import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import React, {useCallback, useEffect, useRef, useState} from "react";
import LoaderService from "../../RetroAssets.System/LoaderService";
import apiClient from "../../services/axios";
import {alert, confirm} from "devextreme/ui/dialog";
import {Template, TextBox} from "devextreme-react";
import cl from "../../RetroAssets.System/Utilities";
import {Column, DataGrid, Button as DataGridButton} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../../RetroAssets.System/MoreOptionsButton";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import ManageApplicationUserDetails from "../Popups/ManageApplicationUserDetails";

function ManageLookups() {
    const spanString = 'AppUser'
    const searchBoxRef = useRef(null)
    const gridInstance = useRef()
    const [gridData, setGridData] = useState([])
    const [gridColumns, setGridColumns] = useState([])
    const [gridCustomization, setGridCustomization] = useState([])
    const [editUserSettings, setEditUserSettings] = useState({
        show: false,
        externalId: ''
    })
    const [height, setHeight] = useState(10)

    useEffect(() => {
        getData(false)
    }, []);

    function getData(showSuccessToast) {
        LoaderService.setData(true)
        apiClient
            .get('/Configuration/GetAllAppUsers')
            .then(response => {
                try {
                    setHeight(cl.setDataGridHeight() - 23)
                    let data = JSON.parse(response.data)
                    setGridData(data[0]["Data"])
                    let settings = data[1]["Settings"]
                    setGridColumns(settings[0][spanString][0]["Columns"])
                    setGridCustomization(settings[0][spanString][3]["GridCustomization"][0])
                    if (showSuccessToast)
                        cl.showSuccessToast()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const searchByText = e => {
        gridInstance.current?.instance().searchByText(e.value ? e.value : null)
    }

    const clearFilter = () => {
        gridInstance.current?.instance().clearFilter()
        searchBoxRef.current?.instance().searchByText('')
        searchBoxRef.current?.instance().option('value', null)
    }

    const cellPrepared = e => {
        cl.setHeaderTooltip(e)
        cl.setColumnColorsFromVM(e)
    }

    const editUser = e => {
        setEditUserSettings({
            ...editUserSettings,
            show: true,
            externalId: e.row.key
        })
    }

    const addUser = e => {
        setEditUserSettings({
            ...editUserSettings,
            show: true,
            externalId: ''
        })
    }

    return (
        <>
            <div
                className="search-actions-header d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 p-lg-3 bg-white border-bottom">
                <div className="d-sm-flex justify-content-between align-items-center flex-fill mb-3 mb-lg-0">
                    <h2 className="fs-5 fw-medium mb-3 mb-sm-0 flex-shrink-0 me-4">Manage Application Users</h2>
                </div>
            </div>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0 dashboard-border">
                    <div
                        className="d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-center mb-0 mb-md-4">

                        <div className="d-flex align-items-center justify-content-sm-end flex-fill me-lg-1">
                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={addUser}>
                                Add
                            </Button>
                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={clearFilter}>
                                Clear Filter
                            </Button>
                            <InputGroup className="me-3 search-input">
                                <TextBox placeholder="Search Lookup Values..."
                                         ref={searchBoxRef}
                                         mode="search"
                                         inputAttr={{class: "form-control"}}
                                         elementAttr={{class: "search-input input-group"}}
                                         width="100%"
                                         onValueChanged={searchByText}
                                ></TextBox>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="tab-content pt-1">
                        <DataGrid
                            ref={gridInstance}
                            dataSource={gridData}
                            keyExpr={"externalId"}
                            height={height}
                            onCellPrepared={cellPrepared}
                            {...gridCustomization}
                        >
                            {gridColumns.map(col => {
                                return cl.generateReactColumnsFromJson(col)
                            })}
                            <Column type={"buttons"} width={"50px"}>
                                <DataGridButton icon={"edit"} onClick={editUser}></DataGridButton>
                            </Column>
                            <Template name="myTemplate" render={MoreOptionsButton}/>
                            <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                        </DataGrid>
                    </div>
                </Card>
            </div>
            <ManageApplicationUserDetails
                show={editUserSettings.show}
                externalId={editUserSettings.externalId}
                handleClose={() => {
                    setEditUserSettings({
                        ...editUserSettings,
                        show: false
                    })
                }}
                isUpdated={() => {
                    getData(true)
                }}
            ></ManageApplicationUserDetails>
        </>
    );
}

export default ManageLookups;