import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef, useCallback} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import apiClient from "../../../services/axios";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {alert} from "devextreme/ui/dialog";
import Form from "react-bootstrap/Form";
import {TextBox, SelectBox, DateBox, ValidationGroup, NumberBox} from "devextreme-react";
import Validator, {RequiredRule, CustomRule} from 'devextreme-react/validator';
import cl from "../../../RetroAssets.System/Utilities";

const TenancyContractCancellation = (props) => {
    const {show, externalId} = props
    const [showModal, setShowModal] = useState(false);

    const [cancellationDetails, setCancellationDetails] = useState({})
    const validationGroup = useRef(null)
    const [allCurrencies, setAllCurrencies] = useState([])

    const modalClose = () => {
        setCancellationDetails({})
        props.handleClose()
        setShowModal(false);
    }

    useEffect(() => {
        if (!show) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Property/GetDataForTenancyContractCancellation?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)

                    let canData = data[0]["Details"]["Data"];
                    canData["DepositRefundCurrency"] = cl.getEcoIdFromExternalId(canData["DepositRefundCurrency"])
                    canData["FineCurrency"] = cl.getEcoIdFromExternalId(canData["FineCurrency"])
                    canData["RentRefundCurrency"] = cl.getEcoIdFromExternalId(canData["RentRefundCurrency"])
                    setCancellationDetails(canData)

                    setAllCurrencies(data[1]["Currencies"])
                    setShowModal(true)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [show])

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        setCancellationDetails({
            ...cancellationDetails,
            [prop]: e.value
        })
    }

    const customValidation = useCallback(e => {
        let property = e.rule.property
        let propertyDisplayName = e.rule.propertyDisplayName
        if (property.startsWith("Deposit")) {
            if (cancellationDetails["DepositRefundAmount"] > 0) {
                if (!e.value) {
                    e.rule.message = `${propertyDisplayName} cannot be empty.`
                    return false
                }
            }
        }
        if (property.startsWith("Fine")) {
            if (cancellationDetails["FineAmount"] > 0) {
                if (!e.value) {
                    e.rule.message = `${propertyDisplayName} cannot be empty.`
                    return false
                }
            }
        }
        if (property.startsWith("Rent")) {
            if (cancellationDetails["RentRefundAmount"] > 0) {
                if (!e.value) {
                    e.rule.message = `${propertyDisplayName} cannot be empty.`
                    return false
                }
            }
        }
        return true;
    }, [cancellationDetails])

    const saveData = () => {
        if (!validationGroup.current.instance().validate().isValid) {
            return;
        }
        LoaderService.setData(true);
        let formData = new FormData();
        formData.append("externalId", externalId);
        formData.append("data", JSON.stringify(cancellationDetails))
        apiClient
            .post('/Property/ManageTenancyContractCancellation', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false);
                        modalClose()
                        let resp = JSON.parse(response.data);
                        if (resp[0].response) {
                            props.isUpdated();
                        } else {
                            return alert(resp[0].message, 'Warning!');
                        }
                        modalClose();
                    } catch (e) {
                        modalClose();
                        LoaderService.setData(false);
                        return alert(response.data, 'Error!');
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false);
                console.log(err);
            });
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-70w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>RECORD TRANSACTION PAYMENT</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
            <ValidationGroup ref={validationGroup}>
                <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                    <div className="row tabsContent">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="dx-fieldset-header"
                                     style={{margin: "10px 0", color: "#048992", fontSize: "15px"}}>
                                    CONTRACT CANCELLATION
                                </div>
                                {/*ContractCancellationDate*/}
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cancellation Date</Form.Label>
                                        <DateBox
                                            displayFormat={"dd/MM/yyyy"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Cancellation Date"
                                            property={"ContractCancellationDate"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["ContractCancellationDate"]}
                                        >
                                            <Validator>
                                                <RequiredRule
                                                    message={"Cancellation date cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </DateBox>
                                    </Form.Group>
                                </div>
                                {/*ContractCancellationRemark*/}
                                <div className="col-sm-9 col-md-10">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cancellation Remark</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Cancellation Remark"
                                            property={"ContractCancellationRemark"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["ContractCancellationRemark"]}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="dx-fieldset-header"
                                     style={{margin: "10px 0", color: "#048992", fontSize: "15px"}}>
                                    DEPOSIT REFUND DETAILS
                                </div>
                                {/*DepositRefundId*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Id</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="ID"
                                            property={"DepositRefundId"}
                                            onValueChanged={valueChangeHandler}
                                            readOnly={true}
                                            value={cancellationDetails["DepositRefundId"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*DepositRefundDate*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Date</Form.Label>
                                        <DateBox
                                            displayFormat={"dd/MM/yyyy"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Date"
                                            property={"DepositRefundDate"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["DepositRefundDate"]}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    propertyDisplayName={"Deposit Refund Date"}
                                                    property="DepositRefundDate"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                            </Validator>
                                        </DateBox>
                                    </Form.Group>
                                </div>
                                {/*DepositRefundAmount*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Amount</Form.Label>
                                        <NumberBox
                                            format={"#,#00.00"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Amount"
                                            property={"DepositRefundAmount"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["DepositRefundAmount"]}
                                        ></NumberBox>
                                    </Form.Group>
                                </div>
                                {/*DepositRefundCurrency*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Currency</Form.Label>
                                        <SelectBox
                                            displayExpr={"Name"}
                                            valueExpr={"externalId"}
                                            dataSource={allCurrencies}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Currency"
                                            property={"DepositRefundCurrency"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["DepositRefundCurrency"]}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    propertyDisplayName={"Deposit Refund Currency"}
                                                    property="DepositRefundCurrency"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                            </Validator>
                                        </SelectBox>
                                    </Form.Group>
                                </div>
                                {/*DepositRefundReference*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Reference</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Reference"
                                            property={"DepositRefundReference"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["DepositRefundReference"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*DepositRefundRemark*/}
                                <div className="col-sm-9 col-md-9">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Remark</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Remark"
                                            property={"DepositRefundRemark"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["DepositRefundRemark"]}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="dx-fieldset-header"
                                     style={{margin: "10px 0", color: "#048992", fontSize: "15px"}}>
                                    RENT REFUND DETAILS <i>[Leave empty if not applicable]</i>
                                </div>
                                {/*RentRefundId*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Id</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="ID"
                                            property={"RentRefundId"}
                                            onValueChanged={valueChangeHandler}
                                            readOnly={true}
                                            value={cancellationDetails["RentRefundId"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*RentRefundDate*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Date</Form.Label>
                                        <DateBox
                                            displayFormat={"dd/MM/yyyy"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Date"
                                            property={"RentRefundDate"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["RentRefundDate"]}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    propertyDisplayName={"Rent Refund Date"}
                                                    property="RentRefundDate"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                            </Validator>
                                        </DateBox>
                                    </Form.Group>
                                </div>
                                {/*RentRefundAmount*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Amount</Form.Label>
                                        <NumberBox
                                            format={"#,#00.00"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Amount"
                                            property={"RentRefundAmount"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["RentRefundAmount"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*RentRefundCurrency*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Currency</Form.Label>
                                        <SelectBox
                                            displayExpr={"Name"}
                                            valueExpr={"externalId"}
                                            dataSource={allCurrencies}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Currency"
                                            property={"RentRefundCurrency"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["RentRefundCurrency"]}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    propertyDisplayName={"Rent Refund Currency"}
                                                    property="RentRefundCurrency"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                            </Validator>
                                        </SelectBox>
                                    </Form.Group>
                                </div>
                                {/*RentRefundReference*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Reference</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Reference"
                                            property={"RentRefundReference"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["RentRefundReference"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*RentRefundRemark*/}
                                <div className="col-sm-9 col-md-9">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Remark</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Remark"
                                            property={"RentRefundRemark"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["RentRefundRemark"]}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="dx-fieldset-header"
                                     style={{margin: "10px 0", color: "#048992", fontSize: "15px"}}>
                                    DAMAGE/ FINE/ PENALTY DETAILS <i>[Leave empty if not applicable]</i>
                                </div>
                                {/*FineId*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Id</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="ID"
                                            property={"FineId"}
                                            onValueChanged={valueChangeHandler}
                                            readOnly={true}
                                            value={cancellationDetails["FineId"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*FineDate*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Date</Form.Label>
                                        <DateBox
                                            displayFormat={"dd/MM/yyyy"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Date"
                                            property={"FineDate"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["FineDate"]}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    propertyDisplayName={"Fine Refund Date"}
                                                    property="FineDate"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                            </Validator>
                                        </DateBox>
                                    </Form.Group>
                                </div>
                                {/*FineAmount*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Amount</Form.Label>
                                        <NumberBox
                                            format={"#,#00.00"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Amount"
                                            property={"FineAmount"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["FineAmount"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*FineCurrency*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Currency</Form.Label>
                                        <SelectBox
                                            displayExpr={"Name"}
                                            valueExpr={"externalId"}
                                            dataSource={allCurrencies}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Currency"
                                            property={"FineCurrency"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["FineCurrency"]}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    propertyDisplayName={"Fine Refund Currency"}
                                                    property="FineCurrency"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                            </Validator>
                                        </SelectBox>
                                    </Form.Group>
                                </div>
                                {/*FineReference*/}
                                <div className="col-sm-3 col-md-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Reference</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Reference"
                                            property={"FineReference"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["FineReference"]}
                                        />
                                    </Form.Group>
                                </div>
                                {/*FineRemark*/}
                                <div className="col-sm-9 col-md-9">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refund Remark</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Refund Remark"
                                            property={"FineRemark"}
                                            onValueChanged={valueChangeHandler}
                                            value={cancellationDetails["FineRemark"]}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationGroup>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            <>
                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                        onClick={saveData}>
                    Save
                </Button>
                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                        onClick={modalClose}>
                    Cancel
                </Button>
            </>
        </Modal.Footer>
    </Modal>)
}

export default TenancyContractCancellation;