import Offcanvas from "react-bootstrap/Offcanvas";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import React, {useEffect, useState} from "react";
import apiClient from "../../services/axios";
import LoaderService from "../../RetroAssets.System/LoaderService";
import {alert} from "devextreme/ui/dialog";


function ViewPropertyDetail(props) {
    const {show, externalId} = props
    const [showModal, setShowModal] = useState(false);
    const [isToggle, setIsToggle] = useState(true); // Accordion
    const options = {
        items: 3,
        dots: false,
        // autoWidth:true,
    };
    const [propertyDetails, setPropertyDetails] = useState({})
    const [images, setImages] = useState([])
    const [fullImage, setFullImage] = useState({
        key: '',
        image: ''
    })

    const handleShow = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);
        setFullImage({
            ...fullImage,
            key: '',
            image: ''
        })
        setImages([])
        props.handleClose()
    }

    const generateLabelForPropertyStatus = () => {
        if (propertyDetails["PurchasePaymentPlan"]) { //Payment Plan
            return (<Badge bg="success" className="text-uppercase prop-status na px-3 fs-7 mb-2">Payment
                Plan</Badge>)
        } else if (!propertyDetails["Available"]) { //Not Available
            return (<Badge bg="success"
                           className="text-uppercase prop-status unoccupied px-3 fs-7 mb-2">{propertyDetails["Status"]}</Badge>)
        } else if (!propertyDetails["Occupied"]) { //Unoccupied
            return (<Badge bg="success"
                           className="text-uppercase prop-status overdue px-3 fs-7 mb-2">{propertyDetails["Status"]}</Badge>)
        } else if (propertyDetails["Occupied"]) {//Occupied
            return (<Badge bg="success"
                           className="text-uppercase prop-status occupied px-3 fs-7 mb-2">{propertyDetails["Status"]}</Badge>)
        }
    }

    useEffect(() => {
        if (!show) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Property/GetDetailsForViewProperty?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setPropertyDetails(data[0]["PropertyDetails"]["Data"])

                    let dt = data[1]["Images"]
                    if (dt.length > 0) {
                        setFullImage({
                            ...fullImage,
                            key: Object.keys(dt[1])[0],
                            image: dt[0]["FULL"]
                        })
                        dt.splice(0, 1)
                        setImages(dt);
                    }
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
        setShowModal(true)
    }, [show])

    const accordionToggle = () => {
        if (isToggle) {
            setIsToggle(false);
        } else {
            setIsToggle(true);
        }
    }

    const imageChanged = e => {
        let params = new URLSearchParams({
            externalId: externalId,
            documentExternalId: e
        })
        apiClient
            .get('/Property/GetFullSizeImage?' + params)
            .then(response => {
                try {
                    let resp = JSON.parse(response.data)
                    if (resp[0].hasOwnProperty('response')) {
                        if (!resp[0].response) {
                            return alert(resp[0].message, 'Warning!')
                        }
                        return
                    }
                    let data = resp
                    if (data.length > 0) {
                        let key = Object.keys(data[0])[0]
                        setFullImage({
                            ...fullImage,
                            key: key,
                            image: data[0][key]
                        })
                    }
                } catch (e) {
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const pageMenuClicked = e => {
        if (e === "TenancyContracts") {
            props.invokeTenancyContract(externalId)
        }
        if (e === "PropertyExpenses") {
            props.invokePropertyExpenses(externalId)
        }
        if (e === "PaymentTransactions") {
            props.invokePaymentTransactions(externalId)
        }
    }

    const editPropertyDetails = e => {
        handleClose()
        props.invokeViewPropertyDetails(externalId)
    }

    return (<Offcanvas show={showModal} onHide={handleClose} placement="end" className="prop-detail-drawer">
        <Offcanvas.Header closeButton className="pb-2">
            <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
            <div className="prop-banner mb-4">
                <div id="prop-thumb-slider" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {fullImage.key ? <div className="carousel-item active" key={fullImage.key}>
                            <img src={'data:image/jpeg;base64,' + fullImage.image} alt="prop-pic"
                                 className="d-block w-100" style={{maxHeight: '250px', objectFit: 'contain'}}/>
                        </div> : <div style={{height: '250px'}}></div>}
                    </div>
                    <div className="carousel-indicators position-relative mt-2 justify-content-start mx-0">
                        {images.map(img => {
                            let key = Object.keys(img)[0]
                            return (
                                <button type="button" data-bs-target="#prop-thumb-slider" data-bs-slide-to={key}
                                        className={fullImage.key === key ? "active ms-0" : "ms-0"}
                                        aria-current="true" aria-label="Slide 1"
                                        onClick={() => imageChanged(key)}>
                                    <img src={'data:image/jpeg;base64,' + img[key]} alt="prop-pic"/>
                                </button>
                                // <div>
                                /*<span><Image src="svg/dots-vertical.svg" alt="dots-vertical"/></span>*/
                                // </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {generateLabelForPropertyStatus()}
            <div className="d-flex align-items-center justify-content-between fs-5 mb-3">
                {propertyDetails.hasOwnProperty("Code") ? <span
                    className="fw-medium">{`[${propertyDetails["Code"]}] [${propertyDetails["Unit"]}] ${propertyDetails["Name"]}`}</span> : <></>}
                <div className="d-flex">
                    <span role="button" className="px-2" title="Edit Property Details" onClick={editPropertyDetails}>
                        <Image src="/svg/edit.svg"
                               alt="edit" width="20"
                               height="20"/></span>
                </div>
            </div>
            <div className="prop-details border rounded px-3 py-2 mb-3">
                <div
                    className="accordion-head cursor-pointer d-flex align-items-center justify-content-between my-1"
                    onClick={() => {
                        accordionToggle()
                    }}>
                    <h6 className="fw-medium mb-0"><b>Property Details</b></h6>
                    <Image src={`svg/${isToggle ? "chevron-up.svg" : "chevron-down.svg"}`} alt="chevron-arrow"
                           width="20" height="20"/>
                </div>
                <div className={`accordion-body pt-1 pb-2 ${isToggle ? "" : "d-none"}`}>
                    <div className="row mb-2">
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/home-line.svg" alt="home-line" width="20"
                                                         height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">Property type</span>
                                <div>{propertyDetails["PropertyTypeName"]}</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/line-height.svg" alt="line-height" width="20"
                                                         height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">Size SQ M</span>
                                <div>{propertyDetails["Size"]} Sq.M</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/bed.svg" alt="bed" width="20" height="20"/>
                            </div>
                            <div className="ps-1">
                                <span className="text-muted">Beds</span>
                                <div>{!propertyDetails["Beds"] ? 'No' : propertyDetails["Beds"]}</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/vehicle-car-parking.svg"
                                                         alt="vehicle-car-parking" width="20" height="20"/>
                            </div>
                            <div className="ps-1">
                                <span className="text-muted">Parking Slots</span>
                                <div>{!propertyDetails["ParkingSlot"] ? 'No' : propertyDetails["ParkingSlot"]}</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/line-chart-up.svg" alt="line-chart-up"
                                                         width="20" height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">ROI</span>
                                <div>{propertyDetails["Ext_ROI"]}%</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/calendar.svg" alt="calendar" width="20"
                                                         height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">Purchase Date</span>
                                <div>{propertyDetails["ViewPurchaseDate"]}</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/coins-stacked.svg" alt="coins-hand" width="20"
                                                         height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">Total Purchase Price</span>
                                <div>{!propertyDetails["ViewTotalPurchasePrice"] ? 'NOT PROVIDED' : propertyDetails["ViewTotalPurchasePrice"]}</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/coins-hand.svg" alt="coins-hand" width="20"
                                                         height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">Base Price</span>
                                <div>{!propertyDetails["Ext_TotalBasePurchasePrice"] ? 'NOT PROVIDED' : propertyDetails["Ext_TotalBasePurchasePrice"]}</div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                            <div className="me-2"><Image src="/svg/plus-black.svg" alt="coins-hand" width="20"
                                                         height="20"/></div>
                            <div className="ps-1">
                                <span className="text-muted">Additional Price</span>
                                <div>{!propertyDetails["Ext_TotalAdditionalPurchasePrice"] ? 'NOT PROVIDED' : propertyDetails["Ext_TotalAdditionalPurchasePrice"]}</div>
                            </div>
                        </div>
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/building.svg" alt="building" width="20"*/}
                        {/*                                 height="20"/></div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Building</span>*/}
                        {/*        <div>79</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/user.svg" alt="user" width="20" height="20"/>*/}
                        {/*    </div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Commission</span>*/}
                        {/*        <div>83,000</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/line-height.svg" alt="home-line" width="20"*/}
                        {/*                                 height="20"/></div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Size SQ M</span>*/}
                        {/*        <div>243.53</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/coins-stacked.svg" alt="coins-stacked"*/}
                        {/*                                 width="20" height="20"/></div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Rent Amt</span>*/}
                        {/*        <div>3,94,900.00</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/users.svg" alt="users" width="20"*/}
                        {/*                                 height="20"/></div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Community</span>*/}
                        {/*        <div>Burj Khalifa</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/shopping-bag.svg" alt="shopping-bag"*/}
                        {/*                                 width="20" height="20"/></div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Market Value</span>*/}
                        {/*        <div>2,44,800.00</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 col-sm-4 d-flex align-items-center py-3">*/}
                        {/*    <div className="me-2"><Image src="svg/settings-h.svg" alt="settings-h" width="20"*/}
                        {/*                                 height="20"/></div>*/}
                        {/*    <div className="ps-1">*/}
                        {/*        <span className="text-muted">Maintenance Fees</span>*/}
                        {/*        <div>83,000</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="prop-details-steps">
                <OwlCarousel className="owl-theme fs-5" loop margin={16} {...options}>
                    <div className="prop-details-card px-3 py-2 rounded d-flex align-items-end"
                         onClick={() => pageMenuClicked("TenancyContracts")}>
                        <div className="fw-medium">Tenancy Contracts</div>
                        <Image src="/svg/arrow-right-short.svg" alt="arrow-right-short" width="16"
                               className="mb-1"/>
                    </div>
                    <div className="prop-details-card px-3 py-2 rounded d-flex align-items-end"
                         onClick={() => pageMenuClicked("PropertyExpenses")}>
                        <div className="fw-medium">Property Expenses</div>
                        <Image src="/svg/arrow-right-short.svg" alt="arrow-right-short" width="16"
                               className="mb-1"/>
                    </div>
                    <div className="prop-details-card px-3 py-2 rounded d-flex align-items-end"
                         onClick={() => pageMenuClicked("PaymentTransactions")}>
                        <div className="fw-medium">Payment Transactions</div>
                        <Image src="/svg/arrow-right-short.svg" alt="arrow-right-short" width="16"
                               className="mb-1"/>
                    </div>
                    {/*<div className="prop-details-card px-3 py-2 rounded d-flex align-items-end"*/}
                    {/*     onClick={() => pageMenuClicked("PropertyDocuments")}>*/}
                    {/*    <div className="fw-medium">Property Documents</div>*/}
                    {/*    <Image src="/svg/arrow-right-short.svg" alt="arrow-right-short" width="16"*/}
                    {/*           className="mb-1"/>*/}
                    {/*</div>*/}
                </OwlCarousel>
            </div>
        </Offcanvas.Body>
    </Offcanvas>)

}

export default ViewPropertyDetail