import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {DateBox, NumberBox, TextBox, ValidationGroup} from "devextreme-react";
import {alert} from "devextreme/ui/dialog";
import apiClient from "../../../services/axios";
import cl from "../../../RetroAssets.System/Utilities";
import Validator, {RequiredRule} from "devextreme-react/validator";
import LoaderService from "../../../RetroAssets.System/LoaderService";

function ManageBankAccountDetails(props) {
    const {show, externalId} = props
    const [showModal, setShowModal] = useState(false);

    const validationGroup = useRef(null);
    const [details, setDetails] = useState({})
    const [allCountries, setAllCountries] = useState([])
    const modalClose = () => {
        setDetails({})
        props.handleClose()
        setDetails({})
        setShowModal(false);
    }

    useEffect(() => {
        if (!show) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Configuration/GetDetailsForManageAccount?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let dt = data[0]["Details"]["Data"]
                    if (externalId) {
                        dt["Country"] = cl.getEcoIdFromExternalId(dt["Country"])
                    } else {
                        dt["Flag_Bank"] = true
                    }
                    setDetails(dt)
                    setAllCountries(data[1]["Countries"])
                    setShowModal(true)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [show])

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        setDetails({
            ...details,
            [prop]: e.value
        })
    }

    const saveData = () => {
        if (!validationGroup.current.instance()?.validate().isValid) {
            return
        }
        let formData = new FormData();
        formData.append("Data", JSON.stringify(details));
        formData.append("SpanString", "_AccountDetails")
        apiClient
            .post('/Configuration/AddUpdateAccount', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        modalClose()
                        if (resp[0].response) {
                            props.isUpdated()
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-50w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>MANAGE BANK ACCOUNT DETAILS</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
            <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                <div className="row tabsContent">
                    <ValidationGroup ref={validationGroup}>
                        <div className="col-sm-12">
                            <div className="row">
                                {/*Name*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Name"
                                            property={"Name"}
                                            value={details["Name"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"Name cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*IBAN*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>IBAN</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="IBAN"
                                            property={"IBAN"}
                                            value={details["IBAN"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"IBAN cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*SwiftCode*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>SWIFT Code</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="SWIFT Code"
                                            property={"SwiftCode"}
                                            value={details["SwiftCode"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"IBAN cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*Description*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Description"
                                            property={"Description"}
                                            value={details["Description"]}
                                            onValueChanged={valueChangeHandler}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={saveData}>
                Save
            </Button>
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={modalClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default ManageBankAccountDetails;