import Card from 'react-bootstrap/Card';
import React, {useEffect, useRef, useState} from "react";
import {PivotGrid, Popover, SelectBox} from "devextreme-react";
import {alert} from 'devextreme/ui/dialog'
import {FieldChooser, FieldPanel} from "devextreme-react/pivot-grid";
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ArrayStore from "devextreme/data/array_store";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import apiClient from "../../../services/axios";
import InputGroup from "react-bootstrap/InputGroup";
import TransactionsComponent from "../../../RetroAssets.System/Components/TransactionsComponent";

const PurchasePaymentProjection = (props) => {
    const {accounts, propListing, loaded} = props
    const [pivotData, setPivotData] = useState({
        data: [],
        filteredData: []
    })
    const arrayStore = new ArrayStore({
        data: pivotData.filteredData,
        key: 'externalId',
    });
    const dataSource = new PivotGridDataSource({
        fields: [
            {
                caption: "Date",
                dataField: "Date",
                allowSorting: true,
                expanded: true,
                allowSortingBySummary: true,
                dataType: "date",
                // format: "MMM",
                format: "yy-MMM",
                sortOrder: "asc",
                area: "row",
            },
            {
                caption: "Company",
                width: 200,
                dataField: "CompanyName",
                expanded: true,
                area: "column"
            },
            {
                caption: "Property",
                width: 150,
                dataField: "Property",
                expanded: true,
                area: "column"
            },
            {
                caption: "Amount",
                width: 60,
                dataField: "Amount",
                // summaryType: "sum",
                dataType: "number",
                area: "data",
                format: '#,#00.00',
                summaryType: "custom",
                calculateCustomSummary: function (e) {
                    if (e.summaryProcess === 'start') {
                        e.totalValue = null;
                    }
                    if (e.summaryProcess === 'calculate') {
                        if (e.value !== null) {
                            e.totalValue += e.value;
                        }
                    }
                }
            }, {
                caption: "Amount [Local]",
                width: 60,
                dataField: "Amount",
                dataType: "number",
                // summaryType: "sum",
                area: "data",
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
                summaryType: "custom",
                calculateCustomSummary: function (e) {
                    if (e.summaryProcess === 'start') {
                        e.totalValue = null;
                    }
                    if (e.summaryProcess === 'calculate') {
                        if (e.value !== null) {
                            e.totalValue += e.value;
                        }
                    }
                }
            }
        ],
        store: arrayStore,
        retrieveFields: false
    });
    const pivotRef = useRef(null)
    const [pivotYearList, setPivotYearList] = useState([])
    const [accountCounts, setAccountCounts] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([])
    let selectedRows = {};
    let currentSelectedProperty = '';
    const popoverRef = useRef(null)

    const purchasePaymentRef = useRef(null)
    const [purchasePaymentSettings, setPurchasePaymentSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: {},
        tabs: []
    })
    const filterOptions = [
        {Name: "All Transactions", externalId: false},
        {
            Name: "All Unpaid Transactions", externalId: true
        }]
    const [pageSettings, setPageSettings] = useState({
        selectedYear: null,
        selectedFilterOption: true,
        currentTab: "ALL"
    })

    useEffect(() => {
        if (accounts.length === 0 || propListing.length === 0 || !loaded) return
        setAccountCounts(accounts)
        setFilteredProperties(propListing[0]['Data'])
        let width = document.getElementById("SomeId").clientWidth
        pivotRef.current.instance().option("width", width - 50)
        loadData(null, true, false)
    }, [accounts, propListing, loaded]);

    const loadData = (year, unpaid, reFetch) => {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            year: year,
            unpaid: unpaid
        })
        apiClient
            .get('/Dashboard/GetPurchasePaymentProjections?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let pvtData = data[0]["PivotData"]
                    let txn = data[1]["Transactions"]
                    let tab = pageSettings.currentTab
                    if (reFetch) {
                        setPivotData({
                            ...pivotData,
                            data: pvtData,
                            filteredData: tab === "ALL" ? pvtData : pvtData.filter(x => x["OwnerExternalId"] === tab),
                        })
                        setPurchasePaymentSettings({
                            ...purchasePaymentSettings,
                            data: txn[0]["Data"]
                        })
                    } else {
                        setPivotData({
                            ...pivotData,
                            data: pvtData,
                            filteredData: tab === "ALL" ? pvtData : pvtData.filter(x => x["OwnerExternalId"] === tab),
                        })
                        setPurchasePaymentSettings({
                            ...purchasePaymentSettings,
                            data: txn[0]["Data"],
                            columns: txn[1]["Settings"][0]["DashboardTransactions"][0]['Columns'],
                            gridCustomization: txn[1]["Settings"][0]["DashboardTransactions"][3]['GridCustomization'][0],
                            tabs: getTabCountsForAccounts(txn[0]["Data"])
                        })
                        let yList = data[2]["YearList"];
                        if (yList.length > 0) {
                            let yr = yList[0]["LastYear"]
                            setPageSettings({
                                ...pageSettings,
                                selectedYear: yr
                            })
                            let coll = []
                            for (let i = yList[0]["LastYear"]; i >= yList[0]["FirstYear"]; i--) {
                                coll.push({Name: i, externalId: i})
                            }
                            setPivotYearList(coll)
                        }
                    }
                    pivotRef.current.instance().repaint()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const getTabCountsForAccounts = (data) => {
        let tempTabs = []
        accounts.forEach(val => {
            if (val["Account"] === "ALL") {
                tempTabs.push({Account: "ALL", Name: "ALL", COUNT: data.length})
            } else {
                tempTabs.push({
                    Account: val.Account,
                    Name: val.Name,
                    COUNT: data.filter(x => x["OwnerExternalId"].toLowerCase() === val["Account"].toLowerCase()).length
                })
            }
        })
        return tempTabs
    }

    function getUpdatedPaymentTransactions() {
        LoaderService.setData(true)
        apiClient
            .get('/Dashboard/GetDashboardPurchasePaymentTransactions')
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setPurchasePaymentSettings({
                        ...purchasePaymentSettings,
                        data: data[0]["Data"],
                        tabs: getTabCountsForAccounts(data[0]["Data"])
                    })
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const filterOptionsChanged = e => {
        setPageSettings({
            ...pageSettings,
            selectedFilterOption: e.value
        })
        loadData(pageSettings.selectedYear, e.value, true)
    }

    const pivotYearChanged = e => {
        if (!e.event) return
        setPageSettings({
            ...pageSettings,
            selectedYear: e.value
        })
        loadData(e.value, pageSettings.selectedFilterOption, true)
    }

    const tabChanged = e => {
        if (e === "ALL") {
            purchasePaymentRef.current?.instance().clearFilter()
        } else {
            purchasePaymentRef.current?.instance().filter(["OwnerExternalId", "=", e])
        }
        setPivotData({
            ...pivotData,
            filteredData: e === "ALL" ? pivotData.data : pivotData.data.filter(x => x["OwnerExternalId"].toLowerCase() === e.toLowerCase()),
        })
    }

    //#region Pivot Component

    const generatePivotComponent = () => {
        return (<div className="col-lg-12 col-xl-6">
            <Card
                className="p-3 border-0 properties-list mb-3 mb-lg-0 dashboard-border dashboard-purchase-payment-component">
                <div className="card-body dash-table-body p-0">
                    <PivotGrid
                        ref={pivotRef}
                        showColumnGrandTotals={false}
                        dataSource={dataSource}
                        showRowTotals={false}
                        showColumnTotals={true}
                        allowSortingBySummary={true}
                        showRowGrandTotals={true}
                        allowSorting={true}
                        allowFiltering={true}
                        allowExpandAll={true}
                        showBorders={true}
                        height={580}
                        onCellClick={cellClick}
                        onCellPrepared={cellPrepared}
                    >
                        <FieldPanel
                            showDataFields={false}
                            showRowFields={true}
                            allowExpandAll={true}
                            showColumnFields={true}
                            showFilterFields={false}
                            allowFieldDragging={false}
                            visible={true}
                        ></FieldPanel>
                        <FieldChooser
                            enabled={false}
                        ></FieldChooser>
                    </PivotGrid>
                </div>
            </Card>
        </div>)
    }

    const cellClick = e => {
        // if (e.area === 'row') {
        //     e.cancel = true;
        //     if (e.cell.hasOwnProperty('rowspan')) return;
        //     currentSelectedProperty = '';
        //     selectedRows = {};
        //     let path = (e.cell.path || []).join('/');
        //     selectedRows[path] = !selectedRows[path];
        //     e.component.repaint();
        // } else if (e.area === "data") {
        //     selectedRows = {};
        //     let rowPath = (e.cell.rowPath || []).join("/");//,
        //     selectedRows [rowPath] = !selectedRows[rowPath];
        //     e.component.repaint();
        // }
    };

    const cellPrepared = e => {
        if (e.area === 'data') {
            if (isRowSelected(e.cell.rowPath || [])) {
                e.cellElement.classList.add('pivotgrid-row-selected');
                if (e.cell.dataIndex === 0) {
                    popoverRef.current.instance().option("target", e.cellElement)
                    popoverRef.current.instance().show();
                }
                if (currentSelectedProperty === '') {
                    let drillDownDataSource = dataSource.createDrillDownDataSource(e.cell);
                    drillDownDataSource
                        .store()
                        .load()
                        .done(items => {
                            currentSelectedProperty = items[0]["externalId"];
                            // e.cellElement.style.color = ;

                        });
                }
            } else {
                e.cellElement.classList.remove('pivotgrid-row-selected');
                let index = filteredProperties.findIndex(x => x["Name"] === e.cell.rowPath[1])
                if (index > -1) {
                    if (filteredProperties[index]["Color"])
                        e.cellElement.style.background = filteredProperties[index]["Color"]
                }
            }
        }
    };

    const RenderPopoverTemplate = () => {
        const changeColor = (colorCode) => {
            let selectedRowId = currentSelectedProperty
            let params = new URLSearchParams({
                objectName: "Property",
                externalId: selectedRowId,
                color: colorCode,
                remove: colorCode === null
            })
            apiClient
                .get('/Shared/UpdateObjectColor?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            let index = filteredProperties.findIndex(x => x.externalId === selectedRowId);
                            if (index > -1) {
                                filteredProperties[index]["Color"] = colorCode
                                setFilteredProperties(filteredProperties)
                            }
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                        LoaderService.setData(false)
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        }
        return (
            <>
                <span className="dot dot-color1" onClick={() => changeColor("#D2E2FF")}></span>
                <span className="dot dot-color2" onClick={() => changeColor("#FEE8B3")}></span>
                <span className="dot dot-color3" onClick={() => changeColor("#B1E3CE")}></span>
                <span className="dot dot-color4" onClick={() => changeColor("#D4D4D4")}></span>
                <span className="dot dot-color5" onClick={() => changeColor("#FFCFAF")}></span>
                <span className="dot dot-color6" onClick={() => changeColor("#EDD7FF")}></span>
                <span className="dot dot-color7" onClick={() => changeColor("#FFA7A7")}></span>
                <span className="dot dot-none" onClick={() => changeColor(null)}> </span>
            </>
        )
    }

    function isRowSelected(rowPath) {
        let path = [],
            selected = false;
        rowPath.some(function (value) {
            path.push(value);
            let pathValue = path.join('/');
            selected = selectedRows[pathValue];
            return selected;
        });
        return selected;
    }

    //#endregion

    //#region Purchase Payment Transactions

    const generatePurchasePaymentComponent = () => {
        return (<div className="col-lg-12 col-xl-6">
            <Card className="p-3 border-0 properties-list mb-3 dashboard-border dashboard-purchase-payment-component">
                <div className="card-body dash-table-body p-0">
                    <TransactionsComponent
                        key="70F462AA-42BD-46D5-A5BF-34BFFB243669"
                        ref={purchasePaymentRef}
                        data={purchasePaymentSettings.data}
                        columns={purchasePaymentSettings.columns}
                        gridCustomization={purchasePaymentSettings.gridCustomization}
                        income={false}
                        expense={true}
                        height={580}
                        isUpdated={() => {
                            getUpdatedPaymentTransactions()
                        }}
                    ></TransactionsComponent>
                </div>
            </Card>
        </div>)
    }

    //#endregion

    return (
        <div className="main-wrapper p-3">
            <Popover
                ref={popoverRef}
                showEvent="dxclick"
                hideOnOutsideClick={true}
                width={180}
                contentRender={RenderPopoverTemplate}
            ></Popover>
            <div className="row mb-3">
                <div className="col-lg-12 col-xl-12">
                    <Card
                        className="p-3 border-0 properties-list mb-3 mb-lg-0 dashboard-border">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center">
                            <h4 className="fs-4 fw-medium mb-3 mb-sm-0 me-4"><b>Projections & Payments</b></h4>
                            <div
                                className="d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-center mb-0 mb-md-1">
                                {/* Tabs title */}
                                <ul className="nav nav-tabs fs-6 nav-tab tab-sm inner-tab mb-3 mb-xl-0">
                                    {accountCounts.map((item, index) => {
                                        return (<li key={item["Account"]} className="nav-item" onClick={() => {
                                            tabChanged(item["Account"])
                                        }}>
                                            <a className={index === 0 ? "nav-link active" : "nav-link"}
                                               data-bs-toggle="tab"
                                               eventKey={item["Account"]}
                                               href={'#' + item["Account"]}>{item["Name"]}</a>
                                        </li>)
                                    })}
                                </ul>
                                <div
                                    className="d-flex justify-md-content-end flex-wrap flex-md-nowrap fs-5 mb-3 mb-xl-0">
                                    <InputGroup className="me-3 search-input">
                                        <SelectBox placeholder="Select Year..."
                                                   dataSource={pivotYearList}
                                                   displayExpr={"Name"}
                                                   valueExpr={"externalId"}
                                                   value={pageSettings.selectedYear}
                                                   inputAttr={{class: "form-control"}}
                                                   elementAttr={{class: "input-group"}}
                                                   onValueChanged={pivotYearChanged}
                                        ></SelectBox>
                                    </InputGroup>
                                    <InputGroup className="me-3 search-input">
                                        <SelectBox placeholder="Select Option..."
                                                   dataSource={filterOptions}
                                                   displayExpr={"Name"}
                                                   valueExpr={"externalId"}
                                                   value={pageSettings.selectedFilterOption}
                                                   inputAttr={{class: "form-control"}}
                                                   elementAttr={{class: "input-group"}}
                                                   onValueChanged={filterOptionsChanged}
                                        ></SelectBox>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="row mb-3">
                {generatePivotComponent()}
                {generatePurchasePaymentComponent()}
            </div>
        </div>
    );
}

export default PurchasePaymentProjection;

