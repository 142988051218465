import {alert} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import {on} from 'devextreme/events';
import LoaderService from './LoaderService';
import {Column, Lookup, Button as DataGridButton} from "devextreme-react/data-grid";
import apiClient from "../services/axios";
import React from "react";
import {forEach} from "react-bootstrap/ElementChildren";

const RetroUtilities = {
    colorColumnPrefix: 'Color_',
    deletedColumn: 'SYS_IsDeleted',
    showSuccessToast: function () {
        notify({
            message: 'Success.',
            type: 'success',
            displayTime: 500,
            position: {my: 'center center', at: 'center center'},
            maxWidth: 300,
        });
    },
    injectLayoutButtons: function (e, spanString) {
        if (e.toolbarOptions.items.length > 0) {
            let index = -1;
            for (let i = 0; i < e.toolbarOptions.items.length; i++) {
                if (e.toolbarOptions.items[i].widget === 'dxButton') {
                    if (
                        e.toolbarOptions.items[i].options.hasOwnProperty('icon') &&
                        e.toolbarOptions.items[i].options.icon === 'column-chooser'
                    ) {
                        index = i;
                        break;
                    }
                }
            }
            if (index > -1) e.toolbarOptions.items.splice(index, 1);
        }

        return e.toolbarOptions.items.push({
            location: 'after',
            widget: 'dxDropDownButton',
            options: {
                icon: 'preferences',
                spanString: spanString,
                hint: 'Save/ Reset layout changes',
                keyExpr: 'id',
                displayExpr: 'text',
                splitButton: true,
                dropDownOptions: {
                    width: 170,
                },
                items: [
                    {id: '1', text: 'Save Layout Changes', icon: 'save'},
                    {id: '2', text: 'Reset Layout Changes', icon: 'clearformat'},
                    {id: '3', text: 'Column Chooser', icon: 'column-chooser'},
                ],
                onItemClick: function (item) {
                    let spanString = item.component.option('spanString');
                    switch (item.itemData.id) {
                        case '1':
                            let coll = e.component.state().columns;
                            let index = coll.findIndex(x => x.width === '50px');
                            if (index > -1) {
                                coll.splice(index, 1);
                            }
                            for (let i = 0; i < coll.length; i++) {
                                if (!coll[i].hasOwnProperty('groupIndex')) coll[i]['groupIndex'] = '-1';
                                if (!coll[i].hasOwnProperty('sortOrder')) coll[i]['sortOrder'] = '';
                                if (!coll[i].hasOwnProperty('fixed')) coll[i]['fixed'] = false;
                                if (!coll[i].hasOwnProperty('fixedPosition')) coll[i]['fixedPosition'] = 'none';
                                if (!coll[i].hasOwnProperty('dataField')) coll[i]['dataField'] = '';
                            }
                            LoaderService.setData(true);
                            let editedData = [
                                {
                                    spanString: spanString,
                                    columns: coll,
                                },
                            ];
                            return apiClient
                                .post('/Shared/UpdateGridCustomizationSettings', editedData)
                                .then(response => {
                                    if (response) {
                                        try {
                                            LoaderService.setData(false);
                                            let resp = JSON.parse(response.data);
                                            if (resp[0].response) {
                                                notify({
                                                    message: 'Success.',
                                                    type: 'success',
                                                    displayTime: 500,
                                                    position: {my: 'center center', at: 'center center'},
                                                    maxWidth: 300,
                                                });
                                            } else {
                                                return alert(resp[0].message, 'Warning!');
                                            }
                                        } catch (e) {
                                            LoaderService.setData(false);
                                            return alert(response.data, 'Error!');
                                        }
                                    }
                                })
                                .catch(err => {
                                    LoaderService.setData(false);
                                    console.log(err);
                                });
                        case '2':
                            LoaderService.setData(true);
                            let params = new URLSearchParams({
                                spanString: spanString,
                            });
                            return apiClient
                                .get('/Shared/DeleteGridCustomizationSettings?' + params)
                                .then(response => {
                                    try {
                                        LoaderService.setData(false);
                                        let resp = JSON.parse(response.data);
                                        if (resp[0].response) {
                                            return alert(
                                                'Please manually refresh the page to reset the columns.',
                                                'Success',
                                            );
                                        } else {
                                            return alert(resp[0].message, 'Warning!');
                                        }
                                    } catch (e) {
                                        LoaderService.setData(false);
                                        return alert(response.data, 'Error!');
                                    }
                                })
                                .catch(err => {
                                    LoaderService.setData(false);
                                    console.log(err);
                                    return alert(err, 'Error!');
                                });
                        case '3':
                            e.component.getView('columnChooserView').showColumnChooser();
                            break;
                        default:
                            break;
                    }
                    return null;
                },
            },
        });
    },
    deleteRow: async function (key, objectType) {
        LoaderService.setData(true);
        let promise = new Promise((resolve, reject) => {
            let params = new URLSearchParams({
                externalId: key.toString(),
                objectType: objectType,
            });
            apiClient
                .get('/Shared/DeleteObject?' + params)
                .then(response => {
                    if (response) {
                        try {
                            let resp = JSON.parse(response.data);
                            LoaderService.setData(false);
                            if (resp[0].response) {
                                this.showSuccessToast();
                                resolve(true);
                            } else {
                                alert(resp[0].message, 'Warning!');
                                reject(false);
                            }
                        } catch (e) {
                            alert(response.data, 'Error!');
                            LoaderService.setData(false);
                            reject(false);
                        }
                    }
                })
                .catch(err => {
                    alert(err, 'Error!');
                    LoaderService.setData(false);
                    reject(false);
                });
        });
        return await promise;
    },
    unsavedChangesText: function () {
        return 'Some unsaved changes have been detected. If you chose to continue the unsaved changes will be lost. Press cancel if you wish to stay and save data or press OK' +
            ' if you wish to discard changes and switch to new page.';
    },
    convertIntegerToRgba(integer) {
        integer >>>= 0;
        let b = integer & 0xff,
            g = (integer & 0xff00) >>> 8,
            r = (integer & 0xff0000) >>> 16,
            a = ((integer & 0xff000000) >>> 24) / 255;
        return 'rgba(' + [r, g, b, a - 0.4].join(',') + ')';
    },
    setDataGridHeight: function () {
        let availableHeight = document.getElementsByClassName('d-flex flex-column wrapper')[0].offsetHeight;
        return availableHeight - 300;
    },
    setYesNoTextBoxColor: function (component, value) {
        if (value === undefined) return;

        if (value.toLowerCase() === 'yes') {
            component.option('inputAttr', {
                style: 'background: #5cb85c; color: white; font-weight: bold;',
            });
        } else {
            component.option('inputAttr', {
                style: 'background: rgba(255, 0, 0, 0.6); color: white; font-weight: bold;',
            });
        }
    },
    rowValidatingEvent: function (e, editedData, withEcoId = false) {
        if (e.brokenRules.length === 0 && e.isValid) {
            e.errorText = '';
            if (editedData.length === 0) {
                editedData = [];
            }
            let keyToSearch = withEcoId ? 'ECO_ID' : 'externalId';
            let dt;
            let keys = Object.keys(e.newData);
            if (keys.findIndex(x => x === '__KEY__') > -1) {
                dt = {};
                dt[keyToSearch] = '';
                keys.forEach(k => {
                    dt[k] = e.newData[k];
                });
                editedData.push(dt);
            } else {
                let dataFound = false;
                let dataIndex;
                if (editedData.length > 0) {
                    for (let i = 0; i < editedData.length; i++) {
                        if (editedData[i].hasOwnProperty('__KEY__')) {
                            if (editedData[i]['__KEY__'] === e.oldData['__KEY__']) {
                                dataFound = true;
                                dataIndex = i;
                                break;
                            }
                        } else {
                            if (editedData[i][keyToSearch] === e.oldData[keyToSearch]) {
                                dataFound = true;
                                dataIndex = i;
                                break;
                            }
                        }
                    }
                }
                if (dataFound) {
                    keys.forEach(k => {
                        editedData[dataIndex][k] = e.newData[k];
                    });
                } else {
                    dt = {};
                    dt[keyToSearch] = e.oldData[keyToSearch];
                    keys.forEach(k => {
                        dt[k] = e.newData[k];
                    });
                    editedData.push(dt);
                }
            }
        } else {
            if (e.errorText === '') e.errorText = 'Please fill required fields to continue.';
        }
        return editedData;
    },
    rowValidatingEvent2: function (e, editedData) {
        if (e.brokenRules.length === 0 && e.isValid) {
            e.errorText = '';
            if (editedData.length === 0) {
                editedData = [];
            }
            let dt;
            let keys = Object.keys(e.newData);
            if (e.key.hasOwnProperty('__KEY__') || e.newData.hasOwnProperty('__KEY__')) {
                dt = {externalId: ''};
                keys.forEach(k => {
                    dt[k] = e.newData[k];
                });
                editedData.push(dt);
            } else {
                let dataFound = false;
                let dataIndex;
                if (editedData.length > 0) {
                    for (let i = 0; i < editedData.length; i++) {
                        if (editedData[i].hasOwnProperty('__KEY__')) {
                            if (editedData[i]['__KEY__'] === e.oldData['__KEY__']) {
                                dataFound = true;
                                dataIndex = i;
                                break;
                            }
                        } else {
                            if (editedData[i].externalId === e.oldData.externalId) {
                                dataFound = true;
                                dataIndex = i;
                                break;
                            }
                        }
                    }
                }
                if (dataFound) {
                    keys.forEach(k => {
                        editedData[dataIndex][k] = e.newData[k];
                    });
                } else {
                    dt = {externalId: e.oldData.externalId};
                    keys.forEach(k => {
                        dt[k] = e.newData[k];
                    });
                    editedData.push(dt);
                }
            }
        } else {
            if (e.errorText === '') e.errorText = 'Please fill required fields to continue.';
        }
        return editedData;
    },
    rowValidatingEventWithColumn: function (columnSet, editedData, e) {
        if (e.brokenRules.length === 0 && e.isValid) {
            e.errorText = '';
            if (editedData.length === 0) {
                editedData = [];
            }
            if (e.key.hasOwnProperty('__KEY__')) {
                //Block executed when a new row is added.
                columnSet.forEach(k => {
                    if (!e.newData.hasOwnProperty(k['dataField'])) {
                        if (k['dataType'] === 'boolean') e.newData[k['dataField']] = false;
                        else if (k['dataType'] === 'string') e.newData[k['dataField']] = '';
                        else if (k['dataType'] === 'double' || k['dataType'] === 'int32')
                            e.newData[k['dataField']] = 0;
                        else e.newData[k['dataField']] = null;
                    }
                });
                editedData.push(e.newData);
            } else {
                let keys = Object.keys(e.newData);
                let dataFound = false;
                let dataIndex;
                if (editedData.length > 0) {
                    for (let i = 0; i < editedData.length; i++) {
                        if (editedData[i].hasOwnProperty('__KEY__')) {
                            let dataKey = !e.oldData ? e.newData['__KEY__'] : e.oldData['__KEY__'];
                            if (editedData[i]['__KEY__'] === dataKey) {
                                dataFound = true;
                                dataIndex = i;
                                break;
                            }
                        } else {
                            if (editedData[i].externalId === e.oldData.externalId) {
                                dataFound = true;
                                dataIndex = i;
                                break;
                            }
                        }
                    }
                }
                if (dataFound) {
                    keys.forEach(k => {
                        editedData[dataIndex][k] = e.newData[k];
                    });
                } else {
                    let oldData = e.oldData;
                    if (!oldData) oldData = {};
                    keys.forEach(k => {
                        oldData[k] = e.newData[k];
                    });
                    editedData.push(oldData);
                }
            }
        } else {
            if (e.errorText === '') e.errorText = 'Please fill required fields to continue.';
        }
        return editedData;
    },
    getDateDifferenceInDays(date1, date2) {
        if (typeof (date1) === 'string')
            date1 = new Date(date1);

        if (typeof (date2) === 'string')
            date2 = new Date(date2);

        return Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
    },
    resetControlValidations(controlArray) {
        for (let i = 0; i < controlArray.length; i++) {
            controlArray[i].instance.option('isValid', true);
        }
    },
    deleteRowFromDataset: function (data, dataSet, component) {
        let keyExpr = 'externalId';
        if (component.option('keyExpr') != null) {
            keyExpr = component.option('keyExpr');
        }
        let index;
        if (data.hasOwnProperty('__KEY__')) index = dataSet.findIndex(x => x.__KEY__ === data.__KEY__);
        else index = dataSet.findIndex(x => x[keyExpr] === data[keyExpr]);
        if (index > -1) dataSet.splice(index, 1);
        return dataSet;
    },
    hasAccess(uac, featureCode) {
        let index = uac.findIndex(x => x === featureCode);
        return index > -1;
    },
    setColumnColorsFromVM(e) {
        if (e.rowType === 'data') {
            if (e.column["HasColorCondition"]) {
                let colorFieldName = this.colorColumnPrefix + e.column.dataField;
                let color = this.convertIntegerToRgba(e.row.data[colorFieldName]);
                if (color !== '') {
                    e.cellElement.style.backgroundColor = color;
                }
            }
            if (e.row.data[this.deletedColumn]) {
                e.cellElement.style.color = 'red';
                e.cellElement.style.textDecorationLine = 'line-through';
                e.cellElement.style.textDecorationStyle = 'solid';
            }
        }
    },
    singleSelectionCheckbox(e) {
        if (e.currentDeselectedRowKeys.length === 0)
            e.component.selectRows(e.currentSelectedRowKeys[0], false);
    },
    setHeaderTooltip(e) {
        if (e.rowType === 'header') {
            on(e.cellElement, 'mouseover', arg => {
                e.cellElement.title = e.column.toolTip;
            });
        }
    },
    enableDisableDataGridEditing(gridCustomization, dataGridInstance) {
        let allowUpdate = false
        if (gridCustomization) {
            allowUpdate = !gridCustomization['editing']?.allowUpdating;
            gridCustomization['editing'].allowUpdating = allowUpdate;
        }
        if (dataGridInstance != null) dataGridInstance.option('editing.allowUpdating', !dataGridInstance.option('editing.allowUpdating'));
        return gridCustomization;
    },
    getEcoIdFromExternalId(e) {
        if (!e) return ''
        let id = e;
        let arr = e.split('!');
        if (arr.length > 0) {
            id = arr[arr.length - 1];
        }
        return id;
    },
    incrementAlphanumeric(ser) {
        let num = parseInt(ser.match(/\d+$/));
        let pos = ser.indexOf(num);
        let str = ser.slice(0, pos);
        num++;
        ser = str + num;
        return ser;
    },
    generateReactColumnsFromJson(column, i) {
        if (column.visible && column.cellTemplate !== "myTemplate") {
            return (<Column caption={column.caption} dataField={column.dataField}
                            dataType={column.dataType} width={column.width}
                            alignment={column.columns ? "center" : column.alignment} ng_model={column.ng_model}
                            visibleIndex={column.visibleIndex} groupIndex={column.groupIndex}
                            fixed={column.fixed} allowEditing={column.allowEditing}
                            allowFiltering={column.allowFiltering} allowGrouping={column.allowGrouping}
                            showInColumnChooser={column.showInColumnChooser}
                            allowHeaderFiltering={column.allowHeaderFiltering} allowSearch={column.allowSearch}
                            allowSorting={column.allowSorting} allowReordering={column.allowReordering}
                            encodeHtml={column.encodeHtml} hidingPriority={column.hidingPriority}
                            sortOrder={column.sortOrder} toolTip={column.toolTip}
                            format={column.format} attribute={column.attribute}
                            attribute1={column.attribute} attribute2={column.attribute}
                            attribute3={column.attribute} attribute4={column.attribute}
                            attribute5={column.attribute5}
                            cellTemplate={column.cellTemplate}
                            visible={column.visible}
                            editCellTemplate={column.editCellTemplate ?? null}
            >
                {column.allowEditing && column.attribute1 ?
                    <Lookup displayExpr={column.lookup.displayExpr} valueExpr={column.lookup.valueExpr}
                            key={column.lookup.key} dataSource={column.lookup.dataSource}></Lookup> : null}
                {column.columns ? column.columns.map(val => {
                    return (<Column caption={val.caption} key={val.key} dataField={val.dataField}
                                    dataType={val.dataType} width={val.width}
                                    alignment={val.alignment} ng_model={val.ng_model}
                                    visibleIndex={val.visibleIndex} groupIndex={val.groupIndex}
                                    fixed={val.fixed} allowEditing={val.allowEditing}
                                    allowFiltering={val.allowFiltering} allowGrouping={val.allowGrouping}
                                    showInColumnChooser={val.showInColumnChooser}
                                    allowHeaderFiltering={val.allowHeaderFiltering} allowSearch={val.allowSearch}
                                    allowSorting={val.allowSorting} allowReordering={val.allowReordering}
                                    encodeHtml={val.encodeHtml} hidingPriority={val.hidingPriority}
                                    sortOrder={val.sortOrder} toolTip={val.toolTip}
                                    format={val.format} attribute={val.attribute}
                                    attribute1={val.attribute} attribute2={val.attribute}
                                    attribute3={val.attribute} attribute4={val.attribute}
                                    attribute5={val.attribute5}
                                    cellTemplate={val.cellTemplate}
                                    visible={val.visible}
                                    editCellTemplate={val.editCellTemplate ?? null}
                    />)
                }) : <></>}
            </Column>)
        }
    },
    generateActionButtonColumn(column) {
        if (column.cellTemplate === "myTemplate") {
            return (<Column key={column.key}
                            cellTemplate={column.cellTemplate}
                            width={column.width}
                            alignment={column.alignment}
                            cssClass={column.cssClass}
                            allowColumnReordering={column.allowColumnReordering}
                            fixed={column.fixed}
                            fixedPosition={column.fixedPosition}
                            showInColumnChooser={column.showInColumnChooser}
                            actionColumn={column.actionColumn}
                            visibleIndex={column.visibleIndex}
            ></Column>)
        }
    },
    setObjectColor(e) {
        if (e.rowType !== 'data') return;
        if (!e.row.data.hasOwnProperty("Color")) return;
        let color = e.row.data['Color'];
        if (color === null) return
        if (e.cellElement.style.background === "")
            e.cellElement.style.background = color + "95"
    },
    getDateObjectAsSqlString(dateObject) {
        //2024-10-15 00:28:27.083
        let year = dateObject.getFullYear()
        let month = dateObject.getMonth() + 1 < 9 ? `$0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
        let day = dateObject.getDate() < 9 ? `$0${dateObject.getDate()}` : dateObject.getDate();
        let hours = dateObject.getHours() < 9 ? `$0${dateObject.getHours()}` : dateObject.getHours();
        let minutes = dateObject.getMinutes() < 9 ? `$0${dateObject.getMinutes()}` : dateObject.getMinutes();
        let seconds = dateObject.getSeconds() < 9 ? `$0${dateObject.getSeconds()}` : dateObject.getSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


    }

};

export default RetroUtilities;
