import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useEffect, useState} from "react";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {TabContainer, TabContent} from "react-bootstrap";
import CompanyAnalyticsComponent from "../Overview/CompanyAnalyticsComponent";
import {
    Chart,
    CommonSeriesSettings,
    ArgumentAxis,
    SeriesTemplate,
    Export,
    ZoomAndPan,
    Legend,
    Title,
    Crosshair,
    ValueAxis,
    ScrollBar,
} from "devextreme-react/chart";
import {DateBox} from 'devextreme-react'
import apiClient from "../../../services/axios";
import Button from "react-bootstrap/Button";
import {alert} from "devextreme/ui/dialog";

function RentOverview(props) {
    const {accounts, propListing, loaded} = props
    const [accountCounts, setAccountCounts] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([])
    const [chartData, setChartData] = useState([])
    const [propSelection, setPropSelection] = useState([])
    const [date, setDate] = useState({
        d1: new Date(new Date().setFullYear(new Date().getFullYear() - 4)),
        d2: new Date(),
    })

    useEffect(() => {
        if (accounts.length === 0 || propListing.length === 0 || !loaded) return
        setAccountCounts(accounts)
        setFilteredProperties(propListing[0]['Data'])
    }, [propListing, accounts, loaded]);

    const accountTabChanged = (e) => {
        setPropSelection([])
        if (e === "ALL") {
            setFilteredProperties(propListing[0]['Data'])
        } else {
            let data = propListing[0]['Data']
            let dt = data.filter(x => x["OwnerExternalId"] === e.toLowerCase());
            setFilteredProperties(dt)
        }
    }

    const propertyChanged = (e) => {
        if (e.currentTarget.checked) {
            propSelection.push(e.currentTarget.id)
        } else {
            let index = propSelection.indexOf(e.currentTarget.id)
            propSelection.splice(index, 1)
        }
        setPropSelection(propSelection)
    }

    const loadData = () => {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalIds: propSelection.toString(),
            date1: date.d1.toJSON(),
            date2: date.d2.toJSON(),
        })
        apiClient
            .get('/Dashboard/GetDataForMonthlyRentOverview?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setChartData(data)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const dateBoxValueChanged = e => {
        let property = e.component.option("property");
        setDate({
            ...date,
            [property]: e.value
        })
    }

    return (
        <>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0 status-card h-100 dashboard-border">
                    {/* Right side filter inputs */}
                    <div className="d-flex align-items-center justify-content-md-end flex-wrap flex-md-nowrap">
                        <div className="date-filter d-flex align-items-center me-sm-3 mb-3 mb-md-0">
                            <div
                                className="datepicker-input d-flex flex-wrap flex-sm-nowrap align-items-center px-sm-2">
                                <Image src="/svg/calendar.svg" alt="calendar" width="15" height="15"/>
                                <span className="flex-shrink-0 ms-1 me-2 text-secondary mb-1 mb-sm-0">From date</span>
                                <DateBox width={120} value={date.d1} className="input-xs"
                                         elementAttr={{class: 'date-box-group'}}
                                         displayFormat="dd/MM/yyyy"
                                         stylingMode="filled"
                                         style={{background: "#fff", borderRadius: "2px", border: '1px solid #eee'}}
                                         property="d1"
                                         onValueChanged={dateBoxValueChanged}
                                ></DateBox>
                            </div>
                            <div
                                className="datepicker-input d-flex flex-wrap flex-sm-nowrap align-items-center px-sm-2 ms-2">
                                <Image src="/svg/calendar.svg" alt="calendar" width="15" height="15"/>
                                <span className="flex-shrink-0 ms-1 me-2 text-secondary mb-1 mb-sm-0">To date</span>
                                <DateBox width={120} value={date.d2} className="input-xs"
                                         elementAttr={{class: 'date-box-group'}}
                                         displayFormat="dd/MM/yyyy"
                                         stylingMode="filled"
                                         style={{background: "#fff", borderRadius: "2px", border: '1px solid #eee'}}
                                         property="d2"
                                         onValueChanged={dateBoxValueChanged}
                                ></DateBox>
                            </div>
                        </div>
                        <div className="dropdown-filter d-flex align-items-center mb-3 mb-md-0">
                            <div className="rent-overview-dropdown me-3">
                                <Dropdown className="select-prop">
                                    <Dropdown.Toggle variant="default" id="prop-selct"
                                                     className="cc-border-dark d-flex text-secondary">
                                        <Image src="/svg/building.svg" alt="building" width="15" height="15"/>
                                        [{propSelection.length}] properties selected
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="shadow-sm border-0 p-3">
                                        {filteredProperties.map((prop) => {
                                            return (<div className="dropdown-items py-1">
                                                <Form.Check type="checkbox"
                                                            id={prop["externalId"]}
                                                            onClick={propertyChanged}
                                                            label={prop["Name"]}/>
                                            </div>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={loadData}>
                                Load
                            </Button>
                            {/*<span role="button" className="px-2"><Image src="svg/print.svg" alt="print" width="20"*/}
                            {/*                                            height="20"/></span>*/}
                        </div>
                    </div>
                    <div className="rent-overview-tabs">
                        <TabContainer>
                            <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                                  onSelect={accountTabChanged}>
                                {accounts.map((item) => (
                                    <Tab id="containerTabProperties" eventKey={item["Account"]}
                                         key={item["Account"]}
                                         title={<>{item["Name"]}
                                             <span className="tab-count">{item["COUNT"]}</span></>}>
                                    </Tab>
                                ))}
                            </Tabs>
                        </TabContainer>
                        <Chart
                            palette="Violet"
                            dataSource={chartData}
                            margin={{
                                bottom: 20
                            }}
                            height={450}
                        >
                            <SeriesTemplate
                                nameField="Property"
                            ></SeriesTemplate>
                            <Crosshair
                                enabled={true}
                                color="#949494"
                                width={3}
                                dashStyle="dot"
                                label={{
                                    visible: true,
                                    backgroundColor: "#949494",
                                    font: {
                                        color: "#fff",
                                        size: 12
                                    }
                                }}
                            ></Crosshair>
                            <Title
                                text="Contract Rental Overview"
                                subtitle={{
                                    text: "(Generated Monthly rents may or may not be accurate)"
                                }}
                            ></Title>
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                itemTextPosition="bottom"
                            ></Legend>
                            {/*<ZoomAndPan*/}
                            {/*    valueAxis="both"*/}
                            {/*    argumentAxis="both"*/}
                            {/*    dragToZoom={true}*/}
                            {/*    allowMouseWheel={false}*/}
                            {/*    panKey="shift"*/}
                            {/*></ZoomAndPan>*/}
                            <CommonSeriesSettings
                                argumentField="StartDate"
                                type="spline"
                                valueField="TotalRent"
                            ></CommonSeriesSettings>
                            <ArgumentAxis
                                argumentType="datetime"
                                valueMarginsEnabled={true}
                                discreteAxisDivisionMode="crossLabels"
                                grid={{
                                    visible: true
                                }}
                            ></ArgumentAxis>
                            <ValueAxis
                                showZero={false}
                                title={{
                                    text: "MONTHLY RENT OVERVIEW"
                                }}
                            ></ValueAxis>
                            {/*<ScrollBar visible={true}></ScrollBar>*/}
                            <Export enabled={true}></Export>
                        </Chart>
                    </div>
                </Card>
            </div>
        </>
    )
        ;
}

export default RentOverview;