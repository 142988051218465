import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef, useCallback} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import {FileUploader, NumberBox, Template, TextArea} from 'devextreme-react';
import apiClient, {baseUrl} from '../../services/axios/index'
import LoaderService from "../../RetroAssets.System/LoaderService";
import store from "store";
import {alert} from "devextreme/ui/dialog";
import cl from "../../RetroAssets.System/Utilities";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {TextBox, SelectBox, DateBox, ValidationGroup} from "devextreme-react";
import {DataGrid} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../../RetroAssets.System/MoreOptionsButton";
import InputGroup from "react-bootstrap/InputGroup";
import Validator, {RequiredRule} from 'devextreme-react/validator';

function ManageTransactionDetails(props) {
    const {show, externalId, readOnly, isIncome, property, defaultCurrency} = props
    const [showModal, setShowModal] = useState(false);
    const [activatedTabs, setActivatedTabs] = useState([]);

    const [transactionDetails, setTransactionDetails] = useState({})
    const [properties, setProperties] = useState([])
    const [accounts, setAccounts] = useState([])
    const [taxes, setTaxes] = useState([])
    const [currencies, setCurrencies] = useState([])
    const statusRef = useRef(null)
    const typeRef = useRef(null)
    const [gridHeight, setGridHeight] = useState(0)
    const validationGroup = useRef(null)
    const searchBoxRef = useRef(null)

    let attachmentSpanString = "Transaction_Documents"
    let fileAttachmentCounter = 0;
    let attachedFileCount = 0;
    let attachmentValueChangedEventExecuted = false;
    const attachmentButtonInstance = useRef(null);

    const faGridInstance = useRef(null);
    const [faData, setFaData] = useState([])
    const [faColumns, setFaColumns] = useState([])
    const [faGridCustomization, setFaGridCustomization] = useState({})
    const [faEditedData, setFaEditedData] = useState([]);
    const [deletedFileAttachments, setDeletedFileAttachments] = useState([]);

    const modalClose = () => {
        setActivatedTabs(["Details"])
        setTransactionDetails({})
        props.handleClose()
        resetAttachmentControl()
        setTransactionDetails({})
        setFaData([])
        setShowModal(false);
        setFaEditedData([])
        setDeletedFileAttachments([])
    }

    useEffect(() => {
        if (!show) return
        setShowModal(true)
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId,
            income: isIncome
        })
        apiClient
            .get('/Transactions/GetTransactionDetails?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    const tData = data[0]["Transaction"]["Data"];
                    if (!externalId) {
                        tData.ID = "NNNN";
                        tData.Property = cl.getEcoIdFromExternalId(property);
                        tData.Currency = cl.getEcoIdFromExternalId(defaultCurrency);
                        tData.Income = isIncome
                    } else {
                        tData.Property = cl.getEcoIdFromExternalId(tData.Property);
                        tData.Currency = cl.getEcoIdFromExternalId(tData.Currency);
                        tData.Account = cl.getEcoIdFromExternalId(tData.Account);
                        tData.Tax = cl.getEcoIdFromExternalId(tData.Tax);
                    }
                    if (tData["Color_Status"]) {
                        let styleString = "color:" + tData["Color_Status"] + "; font-weight: bold;"
                        statusRef.current.instance().option("inputAttr", {style: styleString});
                    }
                    if (tData["Income"])
                        typeRef.current.instance().option("inputAttr", {style: "color: rgba(0, 128, 0, 0.53); font-weight: bold;"});
                    else
                        typeRef.current.instance().option("inputAttr", {style: "color: rgba(255, 0, 0, 1); font-weight: bold;"});
                    setTransactionDetails(tData)
                    setProperties(data[1]["Properties"])
                    setAccounts(data[2]["Accounts"])
                    setTaxes(data[3]["Taxes"])
                    setCurrencies(data[4]["Currency"])
                    let el = document.getElementById("ManageTransaction_DetailsTab").clientHeight
                    setGridHeight(el - 52)
                    validationGroup.current.instance().reset()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [show])

    const tabSelectionChanged = e => {
        const index = activatedTabs.findIndex(x => x === e);
        if (index > -1) {
            return;
        }
        setActivatedTabs(oldArray => [...oldArray, e]);
        let url = ""
        if (e === "Attachments") {
            let params = new URLSearchParams({
                externalId: externalId,
                objectType: "Transaction"
            })
            url = "/Transactions/GetFileAttachments?" + params
        }
        if (!url)
            return;
        LoaderService.setData(true)
        apiClient
            .get(url)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setFaData(data[0]["Data"] ?? [])
                    let settings = data[1]["Settings"]
                    setFaColumns(settings[0][attachmentSpanString][0]["Columns"])
                    let gc = settings[0][attachmentSpanString][3]["GridCustomization"][0]
                    setFaGridCustomization(cl.enableDisableDataGridEditing(gc, null))
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        setTransactionDetails({
            ...transactionDetails,
            [prop]: e.value
        })
    }

    const clearFilter = () => {
        faGridInstance.current?.instance().clearFilter()
        faGridInstance.current?.instance().searchByText('')
        searchBoxRef.current?.instance().option('value', null)
    }

    const searchDocumentByText = e => {
        if (!e.event) return
        faGridInstance.current?.instance().searchByText(e.value ? e.value : null)
    }

    const fileUploaded = useCallback((e => {
        if (e.value.length === 0) return;
        e.value.forEach((val, index) => {
            let ind = `NNNN${index}`;
            faData.push({
                Name: val.name,
                DocumentType: '',
                externalId: ind,
                DocumentLanguage: '',
                ExpiryDate: null,
                Status: '',
                isNew: true,
            });
            e.value[index]['uniqueId'] = ind;
        });
        setFaData(faData);
        faGridInstance.current.instance().refresh();
    }), [faData])

    function resetAttachmentControl() {
        attachmentValueChangedEventExecuted = true;
        attachmentButtonInstance.current.instance().reset();
        attachmentValueChangedEventExecuted = false;
        attachedFileCount = 0;
        fileAttachmentCounter = 0;
    }

    const executeAttachmentCommands = useCallback(() => {
        attachmentButtonInstance.current.instance()._isCustomClickEvent = true;
        attachmentButtonInstance.current.instance()['_$fileInput'][0].click();
    }, []);

    const selectionChangedFileAttachments = e => {
        return
        cl.singleSelectionCheckbox(e);
    };

    const refRowValidating = e => {
        setFaEditedData(cl.rowValidatingEvent(e, faEditedData));
    };

    const cellPreparedFileAttachments = e => {
        cl.setHeaderTooltip(e);
    };

    const addNewPhotos = () => {
        executeAttachmentCommands()
    }

    const saveData = () => {
        if (!validationGroup.current.instance().validate().isValid) {
            return
        }
        LoaderService.setData(true);
        let formData = new FormData();
        formData.append('TransactionData', JSON.stringify(transactionDetails));
        formData.append('TransactionSpanString', '_TransactionDetails');
        formData.append('FileAttachmentData', JSON.stringify(faData));
        formData.append(
            'FileAttachmentEditedData',
            JSON.stringify([{spanString: attachmentSpanString, editedData: faEditedData}]),
        );
        formData.append('DeletedFileAttachments', JSON.stringify(deletedFileAttachments));
        let fa = attachmentButtonInstance.current.instance().option('value');
        fa.forEach(val => {
            formData.append(val['uniqueId'], val, val['uniqueId']);
        });
        apiClient
            .post('/Transactions/AddUpdateTransaction', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        resetAttachmentControl();
                        modalClose();
                        if (resp[0].response) {
                            props.isUpdated();
                        } else {
                            return alert(resp[0].message, 'Warning!');
                        }

                    } catch (e) {
                        resetAttachmentControl();
                        modalClose();
                        LoaderService.setData(false);
                        return alert(response.data, 'Error!');
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false);
                console.log(err);
            });
    }

    const downloadSelected = () => {
        let ids = faGridInstance.current.instance().getSelectedRowKeys();
        if (ids.length === 0) return alert("Please select one or more files to download.", "Warning!")
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalIds: ids.toString(),
            objectType: 'Transaction',
            rootObjectExternalId: externalId
        })

        apiClient
            .get('/Shared/GenerateDocumentsReport?' + params)
            .then(response => {
                try {
                    let resp = JSON.parse(response.data)
                    if (resp[0].response) {
                        let path = baseUrl() + resp[0]['message'];
                        let win = window.open(path, '_blank');
                        win.focus();
                    } else {
                        return alert(resp[0].message + '<br /><br /><i>[TIP: Most errors in file downloads are caused by popup blockers.' +
                            '<br />Kindly configure your browser to allow popups if errors persist.</i>', 'Warning!');
                    }
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-70w prop-img-slider">
            <Modal.Header closeButton className="border-0 pb-0 modal-header">
                <Modal.Title className="fs-5 fw-bold">
                    <span>MANAGE TRANSACTION DETAILS</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-2">
                <FileUploader
                    style={{display: 'none'}}
                    ref={attachmentButtonInstance}
                    multiple={true}
                    accept=".pdf, .xlsx, .xls, .doc, .docx, .txt, .jpg, .png, .bmp"
                    labelText=""
                    allowCanceling={false}
                    showFileList={false}
                    selectButtonText="Add Documents"
                    onValueChanged={fileUploaded}
                    uploadMode="useForm"
                ></FileUploader>
                <Tabs defaultActiveKey="Details" className="fs-6 nav-tab tab-sm inner-tab"
                      onSelect={tabSelectionChanged}>
                    <Tab eventKey="Details" title="Details">
                        <ValidationGroup ref={validationGroup}>
                            <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                                <div className="row tabsContent">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Type</Form.Label>
                                                    <TextBox ref={typeRef}
                                                             inputAttr={{class: "form-control"}}
                                                             elementAttr={{class: "input-group"}}
                                                             placeholder="Type"
                                                             value={transactionDetails["Type"]}
                                                             readOnly={true}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Status</Form.Label>
                                                    <TextBox
                                                        ref={statusRef}
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Status"
                                                        value={transactionDetails["Status"]}
                                                        readOnly={true}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Id</Form.Label>
                                                    <TextBox
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="ID"
                                                        value={transactionDetails["ID"]}
                                                        readOnly={true}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Property</Form.Label>
                                                    <SelectBox
                                                        displayExpr="Name"
                                                        valueExpr="externalId"
                                                        dataSource={properties}
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Property"
                                                        value={transactionDetails["Property"]}
                                                        readOnly={true}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Date</Form.Label>
                                                    <DateBox
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Date"
                                                        value={transactionDetails["Date"]}
                                                        readOnly={readOnly}
                                                        property="Date"
                                                        onValueChanged={valueChangeHandler}
                                                        displayFormat="dd/MM/yyyy"
                                                    >
                                                        <Validator>
                                                            <RequiredRule
                                                                message="Date cannot be empty"></RequiredRule>
                                                        </Validator>
                                                    </DateBox>
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Transaction Reference</Form.Label>
                                                    <TextBox
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Transaction Reference"
                                                        property="Reference"
                                                        onValueChanged={valueChangeHandler}
                                                        value={transactionDetails["Reference"]}
                                                        readOnly={readOnly}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Currency</Form.Label>
                                                    <SelectBox
                                                        displayExpr="Name"
                                                        valueExpr="externalId"
                                                        dataSource={currencies}
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Currency"
                                                        property="Currency"
                                                        onValueChanged={valueChangeHandler}
                                                        value={transactionDetails["Currency"]}
                                                        readOnly={readOnly}
                                                    >
                                                        <Validator>
                                                            <RequiredRule
                                                                message="Currency cannot be empty"></RequiredRule>
                                                        </Validator>
                                                    </SelectBox>
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Account</Form.Label>
                                                    <SelectBox
                                                        displayExpr="Name"
                                                        valueExpr="externalId"
                                                        dataSource={accounts}
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Account"
                                                        searchEnabled={true}
                                                        value={transactionDetails["Account"]}
                                                        property="Account"
                                                        readOnly={readOnly}
                                                        onValueChanged={valueChangeHandler}
                                                    >
                                                        <Validator>
                                                            <RequiredRule
                                                                message="Account cannot be empty"></RequiredRule>
                                                        </Validator>
                                                    </SelectBox>
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Amount</Form.Label>
                                                    <NumberBox
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Amount"
                                                        property="Amount"
                                                        onValueChanged={valueChangeHandler}
                                                        value={transactionDetails["Amount"]}
                                                        readOnly={readOnly}
                                                        format="#,#00.00"
                                                    >
                                                        <Validator>
                                                            <RequiredRule
                                                                message="Amount cannot be empty"></RequiredRule>
                                                        </Validator>
                                                    </NumberBox>
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tax</Form.Label>
                                                    <SelectBox
                                                        displayExpr="Name"
                                                        valueExpr="externalId"
                                                        dataSource={taxes}
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Tax"
                                                        property="Tax"
                                                        searchEnabled={true}
                                                        value={transactionDetails["Tax"]}
                                                        readOnly={readOnly}
                                                        onValueChanged={valueChangeHandler}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-2">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Total</Form.Label>
                                                    <NumberBox
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Total"
                                                        value={transactionDetails["Total"]}
                                                        readOnly={true}
                                                        format="#,#00.00"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-3 col-md-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Remarks</Form.Label>
                                                    <TextBox
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Remarks"
                                                        property="Remarks"
                                                        value={transactionDetails["Remarks"]}
                                                        readOnly={readOnly}
                                                        onValueChanged={valueChangeHandler}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Cancellation Remarks</Form.Label>
                                                    <TextArea
                                                        inputAttr={{class: "form-control"}}
                                                        elementAttr={{class: "input-group"}}
                                                        placeholder="Cancellation Remark  (will be filled if transaction is cancelled.)"
                                                        value={transactionDetails["CancellationRemark"]}
                                                        readOnly={true}
                                                    />
                                                </Form.Group>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ValidationGroup>
                    </Tab>
                    <Tab eventKey="Attachments" title="Attachments">
                        <div className="py-2 tabs-border">
                            <div className="row tabsContent">
                                <div
                                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                    <div className="d-flex">
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={clearFilter}>
                                            Clear Filter
                                        </Button>
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={downloadSelected}>
                                            Download
                                        </Button>
                                        {readOnly ? <></> :
                                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                    onClick={addNewPhotos}>
                                                Add Documents
                                            </Button>}
                                        <InputGroup className="me-3 search-input">
                                            <TextBox
                                                ref={searchBoxRef}
                                                placeholder="Search Documents..."
                                                mode="search"
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "search-input input-group"}}
                                                width="100%"
                                                onValueChanged={searchDocumentByText}
                                            ></TextBox>
                                        </InputGroup>
                                    </div>
                                </div>
                                <DataGrid
                                    ref={faGridInstance}
                                    keyExpr="externalId"
                                    dataSource={faData}
                                    columns={faColumns}
                                    height={gridHeight}
                                    onCellPrepared={cellPreparedFileAttachments}
                                    onRowValidating={refRowValidating}
                                    onSelectionChanged={selectionChangedFileAttachments}
                                    {...faGridCustomization}
                                >
                                    <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                    <Template name="myTemplate" render={MoreOptionsButton}/>
                                </DataGrid>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer className="pt-2">
                {readOnly ? <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={modalClose}>
                    Close
                </Button> : <>
                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                            onClick={saveData}>
                        Save
                    </Button>
                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                            onClick={modalClose}>
                        Cancel
                    </Button>
                </>}
            </Modal.Footer>

        </Modal>
    )
}

export default ManageTransactionDetails;