import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useState} from "react";
import {history} from "../index";
import {useNavigate} from "react-router-dom";

const mapStateToProps = ({dispatch, user}) => ({
    dispatch: dispatch,
    user: user
});

const Login = (props) => {
    const navigate = useNavigate();
    const {dispatch, user} = props;
    const [values, setValues] = useState({
        UserName: 'dimitra',
        Password: "dimitra",
        captchaToken: 'ea4c6148-3414-4105-bcfa-9cdc7337bb3b',
        navigate: navigate
    })

    const changeSetting = (setting, value) => {
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting,
                value,
            },
        });
    };

    const onFinish = event => {
        changeSetting('navigate', navigate);
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return
        }
        dispatch({
            type: 'user/LOGIN',
            payload: values,
        });
    };
    return (
        <>
            <div className="row mx-0 onboard-wrapper" style={{background: "url('./pexels-photo-534174 1.jpeg')"}}>
                <div className="col-md-7 col-lg-8 px-md-0 d-none d-md-flex">
                    {/*<div className='align-self-end'>*/}
                    {/*    /!*<img src="login-banner.png" alt="login-banner" className="banner mw-100"/>*!/*/}
                    {/*</div>*/}
                </div>
                <div className="col-md-5 col-lg-4 px-3 px-xl-0 align-self-center">
                    <div className="card shadow-sm p-4 border-0 login-form m-auto ms-md-0">
                        <h2 className="text-center fs-2 fw-bold my-3">Login</h2>
                        <Form onSubmit={onFinish}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="fw-medium fs-5">Enter Username</Form.Label>
                                <Form.Control required type="text" placeholder="Username" className="form-field"
                                              onChange={e => setValues({
                                                  ...values,
                                                  UserName: e.target.value
                                              })}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className="fw-medium fs-5">Password</Form.Label>
                                <Form.Control required type="password" placeholder="Password" className="form-field"
                                              onChange={e => setValues({
                                                  ...values,
                                                  Password: e.target.value
                                              })}/>
                            </Form.Group>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="text-muted">
                                    <Form.Check label="Keep me Logged in" name="group1" type="checkbox"/>
                                </div>
                                <div><Link to="/" className="text-decoration-none">Forgot Password?</Link></div>
                            </div>
                            <Button type="submit" variant="primary" size="lg" disabled={user.loading}
                                    className="w-100 fs-5 fw-medium"
                            >{user.loading ? "Loading" : "Login"}</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );

}

export default connect(mapStateToProps)(Login);