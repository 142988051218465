import React, {useEffect, useState} from "react";
import {
    Chart, Tooltip, Series, CommonSeriesSettings, Label, Format, Legend, Export,
} from 'devextreme-react/chart';

const CompanyAnalyticsComponent = React.forwardRef((props, chartInstance) => {

    const {data, height} = props
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(data)
    }, [data])

    return (
        <Chart
            ref={chartInstance}
            dataSource={chartData}
            height={height}
        >
            <CommonSeriesSettings
                argumentField="Account"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >
                <Label visible={true}>
                    <Format type="fixedPoint" precision={0}/>
                </Label>
            </CommonSeriesSettings>
            <Series
                argumentField="Account"
                valueField="TotalIncome"
                name="Total Income"
            />
            <Series
                argumentField="Account"
                valueField="TotalExpense"
                name="Total Expense"
            />
            <Series
                argumentField="Account"
                valueField="Profit"
                name="Profit"
            />
            <Tooltip enabled={true}/>
            <Legend verticalAlignment="top" horizontalAlignment="center"></Legend>
            <Export enabled={false}/>
        </Chart>
    )
})

export default CompanyAnalyticsComponent;
