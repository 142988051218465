import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Overview from "./Overview/Overview";
import Summary from "./Summary/Summary";
import ProfitAnalytics from "./Analytics/ProfitAnalytics";
import RentOverview from "./RentOverview/RentOverview";
import PurchasePaymentProjection from "./PurchasePaymentProjection";
import {useEffect, useState} from "react";
import LoaderService from "../../RetroAssets.System/LoaderService";
import apiClient from "../../services/axios";
import {alert} from "devextreme/ui/dialog";

function Dashboard() {
    const [accountCounts, setAccountCounts] = useState([]);
    const [propListing, setPropListing] = useState([]);
    const [summary, setSummary] = useState(false);
    const [profitAnalytics, setProfitAnalytics] = useState(false);
    const [rentOverview, setRentOverview] = useState(false);
    const [purchaseProjection, setPurchaseProjection] = useState(false)
    useEffect(() => {
        apiClient
            .get('/Dashboard/GetAccountsAndPropertiesForDashboard')
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setAccountCounts(data[0]['Accounts'])
                    setPropListing(data[1]['PropertyListing'])
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, []);

    const tabsSelectionChanged = e => {
        if (e.target.textContent === "Profit Analytics") {
            setProfitAnalytics(true)
            return
        } else if (e.target.textContent === "Payment Plan") {
            setPurchaseProjection(true)
            return;
        } else if (e.target.textContent === "summary") {
            setSummary(true)
        } else if (e.target.textContent === 'Monthly Rent Overview') {
            setRentOverview(true)
        }
        setPurchaseProjection(false)
        setProfitAnalytics(false)
    }

    return (
        <>
            <Tabs defaultActiveKey="overview" className="fs-5 nav-tab px-2" onClick={tabsSelectionChanged}>
                <Tab eventKey="overview" title="overview">
                    <Overview accounts={accountCounts} propListing={propListing}/>
                </Tab>
                <Tab eventKey="summary" title="summary">
                    <Summary accounts={accountCounts} propListing={propListing}
                             loaded={summary}/>
                </Tab>
                <Tab eventKey="profitAnalytics" title="Profit Analytics">
                    <ProfitAnalytics accounts={accountCounts} propListing={propListing}
                                     loaded={profitAnalytics}/>
                </Tab>
                <Tab eventKey="rentOverview" title="Monthly Rent Overview">
                    <RentOverview accounts={accountCounts} propListing={propListing}
                                  loaded={rentOverview}/>
                </Tab>
                <Tab eventKey="PurchasePaymentProjection" title="Payment Plan">
                    <PurchasePaymentProjection accounts={accountCounts} propListing={propListing}
                                               loaded={purchaseProjection}/>
                </Tab>
            </Tabs>
        </>
    );
}

export default Dashboard;