import {Subject} from 'rxjs'

const loaderSubject = new Subject()

const LoaderService = {
    setData: d => loaderSubject.next({value: d}),
    clearData: () => loaderSubject.next(),
    getData: () => loaderSubject.asObservable(),
}

export default LoaderService;
