import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import {connect} from "react-redux";
import React, {useCallback, useEffect, useRef, useState} from "react";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {alert, confirm} from 'devextreme/ui/dialog'
import apiClient from "../../../services/axios";
import {useNavigate} from "react-router-dom";
import cl from "../../../RetroAssets.System/Utilities";
import {TextBox} from "devextreme-react";
import TransactionsComponent from "../../../RetroAssets.System/Components/TransactionsComponent";
import AutoGenerateTransactions from "../../Popups/AutoGenerateTransactions";

const mapStateToProps = ({settings}) => ({
    dispatch: settings.dispatch,
    SettingsForPurchasePayment: settings.SettingsForPurchasePayment
})

const PurchasePayment = (props) => {
    const navigate = useNavigate();
    const [newItemIndex, setNewItemIndex] = useState(0)
    const [pageSettings, setPageSettings] = useState({
        externalId: '',
        Name: '',
        Builder: '',
        DisplayPurchaseDate: '',
        PropertyStatus: '',
        PurchasePaymentPlan: false,
        Available: false,
        Occupied: false,
        Currency: ''
    })

    const purchasePaymentSpanString = "Property_PurchasePaymentPlanTransactions"
    const purchasePaymentRef = useRef(null)
    const purchasePaymentSearchRef = useRef(null)
    const [purchasePaymentData, setPurchasePaymentData] = useState([])
    const [purchasePaymentColumns, setPurchasePaymentColumns] = useState([])
    const [purchasePaymentGridCustomization, setPurchasePaymentGridCustomization] = useState({})
    const [purchasePaymentEditedData, setPurchasePaymentEditedData] = useState([]);
    const [deletedPurchasePayments, setDeletedPurchasePayments] = useState([]);
    const [readOnly, setReadOnly] = useState(true)
    const [changesMade, setChangesMade] = useState(false)
    const [autoGenerateTransactionSettings, setAutoGenerateTransactionSettings] = useState({
        show: false
    })

    const [allTaxes, setAllTaxes] = useState([])

    useEffect(() => {
        if (!props.SettingsForPurchasePayment) {
            navigate(-1);
            return
        }
        getData()
    }, [props.SettingsForPurchasePayment])

    function getData(showToast) {
        setReadOnly(true)
        setPageSettings(props.SettingsForPurchasePayment);
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: props.SettingsForPurchasePayment["externalId"],
        })
        apiClient
            .get('/Property/GetPropertyPurchasePaymentTransactions?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setPurchasePaymentData(data[0]['Data'])
                    let settings = data[1]["Settings"]
                    let cols = settings[0][purchasePaymentSpanString][0]['Columns']
                    let index = cols.findIndex(x => x["dataField"] === "Tax")
                    if (index > -1) {
                        setAllTaxes(cols[index]["lookup"]["dataSource"])
                    }
                    setPurchasePaymentColumns(cols)
                    let gc = settings[0][purchasePaymentSpanString][3]['GridCustomization'][0]
                    setPurchasePaymentGridCustomization(cl.enableDisableDataGridEditing(gc, null))
                    if (showToast)
                        cl.showSuccessToast()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    //#region Purchase Payment

    const deletePurchasePaymentTransaction = useCallback((e) => {
        if (e.row.data["Paid"])
            return alert("Cannot delete completed transactions.", "Warning!")

        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = purchasePaymentEditedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    purchasePaymentEditedData.splice(index, 1)
                    setPurchasePaymentEditedData(purchasePaymentEditedData)
                }

                index = purchasePaymentData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    purchasePaymentData.splice(index, 1)
                    purchasePaymentRef.current.instance().option("dataSource", purchasePaymentData)
                    setPurchasePaymentData(purchasePaymentData)
                }
                purchasePaymentRef.current.instance().repaint()
                setChangesMade(true)
                if (e.row.key.includes("NNNN")) return
                deletedPurchasePayments.push(e.row.key)
                setDeletedPurchasePayments(deletedPurchasePayments)
            }
        })
    }, [purchasePaymentEditedData, deletedPurchasePayments, purchasePaymentData])

    const addPurchasePaymentRecord = () => {
        if (!pageSettings.Builder) return alert("No builder assigned against the property.<br /><br />Cannot add purchase payment transactions until the builder is assigned.", "Warning!")

        if (readOnly)
            enableDisableEditing()
        purchasePaymentRef.current.instance().addRow()
    }

    const clearFilterPurchasePayment = () => {
        purchasePaymentRef.current?.instance().clearFilter()
        purchasePaymentRef.current?.instance().searchByText('')
        purchasePaymentSearchRef.current?.instance().option('value', null)
    }

    const searchPurchasePaymentByText = e => {
        if (!e.event) return
        purchasePaymentRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const rowValidatingPurchasePayment = e => {
        if (e.newData.hasOwnProperty("Amount") || e.newData.hasOwnProperty("Tax")) {
            let taxRate = 0;
            if (e.newData.hasOwnProperty("Tax")) {
                if (e.newData.Tax) {
                    let index = allTaxes.findIndex(x => x.externalId === e.newData.Tax);
                    if (index > -1)
                        taxRate = allTaxes[index]["Rate"];
                }
            } else if (e.oldData !== undefined) {
                if (e.oldData.hasOwnProperty("Tax")) {
                    if (e.oldData.Tax) {
                        let index = allTaxes.findIndex(x => x.externalId === e.oldData.Tax);
                        if (index > -1)
                            taxRate = allTaxes[index]["Rate"];
                    }
                }
            }

            let amount = e.newData.hasOwnProperty("Amount") ? e.newData["Amount"] : e.oldData["Amount"];
            let total = 0.0

            if (taxRate === 0) {
                e.newData["Total"] = amount;
                e.newData["TaxAmount"] = 0;
            } else {
                let taxAmount = amount * (taxRate / 100);
                total = amount + taxAmount;
                e.newData["Total"] = total
                e.newData["TaxAmount"] = taxAmount;
            }
        }
        setPurchasePaymentEditedData(cl.rowValidatingEvent(e, purchasePaymentEditedData))
        setChangesMade(true)
    }

    const initRowPurchasePayment = useCallback(e => {
        e.data["externalId"] = "NNNN" + newItemIndex;
        e.data["ID"] = "NNNN"
        e.data["Flag_PurchasePaymentPlan"] = true
        setNewItemIndex(newItemIndex + 1)
    }, [newItemIndex]);

    const enableDisableEditing = useCallback(() => {
        if (readOnly) {
            setPurchasePaymentGridCustomization(cl.enableDisableDataGridEditing(purchasePaymentGridCustomization, purchasePaymentRef.current.instance()))
            setReadOnly(!readOnly)
        } else {
            setPurchasePaymentGridCustomization(cl.enableDisableDataGridEditing(purchasePaymentGridCustomization, purchasePaymentRef.current.instance()))
            setReadOnly(!readOnly)
        }
    }, [purchasePaymentGridCustomization, readOnly])

    const autoGenerateTransactions = () => {
        if (!pageSettings.Builder) return alert("No builder assigned against the property.<br /><br />Cannot add purchase payment transactions until the builder is assigned.", "Warning!")
        setAutoGenerateTransactionSettings({
            ...autoGenerateTransactionSettings,
            show: true
        })
    }

    const generateTransactions = useCallback((data) => {
        if (readOnly)
            enableDisableEditing()
        let count = purchasePaymentData.length + 1
        let firstDate = data.StartDate;
        let rows = data.NumberOfInstallments
        let gap = data.MonthGap
        let amount = data.Amount
        let nwIndex = newItemIndex;
        for (let i = 0; i < rows; i++) {
            let month;
            if (i > 0) {
                month = (firstDate.getMonth() + gap);
                firstDate = new Date(firstDate.setMonth(month));
            }
            let newRecord = {
                ID: "NNNN",
                Date: firstDate,
                Amount: amount,
                Currency: pageSettings.Currency,
                Remarks: "Installment #" + count.toString(),
                externalId: "NNNN" + nwIndex,
                Account: pageSettings.Builder,
                TaxAmount: 0.0,
                Total: amount,
                Flag_PurchasePaymentPlan: true
            }
            purchasePaymentData.push(newRecord)
            purchasePaymentEditedData.push(newRecord)
            nwIndex++
            count++
        }
        setNewItemIndex(nwIndex)
        setPurchasePaymentData(purchasePaymentData)
        setPurchasePaymentEditedData(purchasePaymentEditedData)
        setChangesMade(true)
        purchasePaymentRef.current.instance().repaint()
        purchasePaymentRef.current.instance().option("dataSource", purchasePaymentData)
    }, [purchasePaymentEditedData, purchasePaymentData, purchasePaymentGridCustomization])

    //#endregion

    const generatePropertyStatus = e => {
        if (e["PurchasePaymentPlan"]) { //Payment Plan
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-na"><span
                className="status-color na"></span>{e["PropertyStatus"]}</label>)
        } else if (!e["Available"]) { //Not Available
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-unoccupied"><span
                className="status-color unoccupied"></span>{e["PropertyStatus"]}</label>)
        } else if (!e["Occupied"]) { //Unoccupied
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-overdue"><span
                className="status-color overdue"></span>{e["PropertyStatus"]}</label>)
        } else if (e["Occupied"]) {//Occupied
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-occupied"><span
                className="status-color occupied"></span>{e["PropertyStatus"]}</label>)
        }
    }

    const saveChanges = () => {
        let formData = new FormData()
        formData.append("Property", pageSettings.externalId)
        formData.append("SpanString", purchasePaymentSpanString)
        formData.append("PurchasePaymentTransactions", JSON.stringify(purchasePaymentEditedData))
        formData.append("DeletedTransactions", JSON.stringify(deletedPurchasePayments))
        apiClient
            .post('/Property/AddUpdatePurchasePaymentTransactions', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        setPurchasePaymentEditedData([])
                        setChangesMade(false)
                        setDeletedPurchasePayments([])
                        if (resp[0].response) {
                            getData(true)
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (
        <>
            <div
                className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 bg-white border-bottom">
                <div className="d-flex align-items-center fs-5 mb-3">
                    <span className="text-secondary cursor-pointer">Property Listings</span>
                    <Image src="/svg/chevron-left.svg" alt="chevron-left" width="20" height="20" className="mx-1"/>
                    <h2 className="fs-5 fw-medium mb-0 flex-shrink-0 me-4">Purchase Payment Plan Transactions</h2>
                </div>
                <div className="d-flex flex-wrap flex-xl-nowrap mb-3">
                    <Button variant="outline-primary" className="me-2 px-3">Add
                        New Transaction</Button>
                    <Button variant="outline-primary" className="mx-2 px-3">Export
                        All Data</Button>
                </div>
            </div>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-4 mb-3 mb-lg-0">
                            <Card className="card-light p-3 border-0 text-center h-100">
                                <div className="fs-4 fw-bold mb-1">{pageSettings["Name"]}</div>
                                <div className="text-secondary text-uppercase">Property name</div>
                            </Card>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div
                                            className="fs-4 fw-bold mb-1">{pageSettings["DisplayPurchaseDate"]}</div>
                                        <div className="text-secondary text-uppercase">PURCHASE date</div>
                                    </Card>
                                </div>
                                <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div
                                            className="fs-4 fw-bold mb-1 text-capitalize">{pageSettings["Builder"]}</div>
                                        <div className="text-secondary text-uppercase">property builder</div>
                                    </Card>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <Card className="card-light p-3 border-0 h-100">
                                        <span
                                            className="fs-7 mb-1 rounded-1 py-1 px-2 align-self-center">{generatePropertyStatus(pageSettings)}</span>
                                        <div className="text-secondary text-uppercase align-self-center">Property
                                            Status
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded p-3 border border-secondary">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                            <h4 className="fs-4 fw-medium mb-3 mb-sm-0"><b>Contract Transactions</b></h4>
                            <div className="d-flex">
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={enableDisableEditing}>
                                    {readOnly ? "Enable Edit" : "Disable Edit"}
                                </Button>
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        disabled={!changesMade}
                                        onClick={saveChanges}>
                                    Save Data
                                </Button>
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={autoGenerateTransactions}>
                                    Auto Generate Transactions
                                </Button>
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={addPurchasePaymentRecord}>
                                    Add
                                </Button>
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={clearFilterPurchasePayment}>
                                    Clear Filter
                                </Button>
                                <InputGroup className="me-3 search-input">
                                    <TextBox
                                        ref={purchasePaymentSearchRef}
                                        placeholder="Search Transactions..."
                                        mode="search"
                                        inputAttr={{class: "form-control temp"}}
                                        elementAttr={{class: "search-input input-group"}}
                                        width="100%"
                                        onValueChanged={searchPurchasePaymentByText}
                                    ></TextBox>
                                </InputGroup>
                            </div>
                        </div>
                        <TransactionsComponent
                            ref={purchasePaymentRef}
                            data={purchasePaymentData}
                            columns={purchasePaymentColumns}
                            gridCustomization={purchasePaymentGridCustomization}
                            height={500}
                            income={false}
                            expense={false}
                            purchasePayment={true}
                            deletePurchasePaymentTransaction={deletePurchasePaymentTransaction}
                            rowValidatingEvent={rowValidatingPurchasePayment}
                            summaryObject={{}}
                            newRowAdded={initRowPurchasePayment}
                        ></TransactionsComponent>
                    </div>
                </Card>
            </div>
            <AutoGenerateTransactions
                show={autoGenerateTransactionSettings.show}
                handleClose={() => setAutoGenerateTransactionSettings({
                    ...autoGenerateTransactionSettings,
                    show: false
                })}
                isUpdated={(data) => generateTransactions(data)}
            ></AutoGenerateTransactions>
        </>
    );
}

export default connect(mapStateToProps)(PurchasePayment)