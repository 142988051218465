import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import apiClient from '../../services/axios/index'
import LoaderService from "../../RetroAssets.System/LoaderService";
import {alert} from "devextreme/ui/dialog";
import Form from "react-bootstrap/Form";
import {TextArea} from "devextreme-react";

function CancelTransaction(props) {
    const {show, externalId} = props
    const [showModal, setShowModal] = useState(false);

    const [remark, setRemark] = useState('')

    const modalClose = () => {
        setRemark('')
        props.handleClose()
        setShowModal(false);
    }

    useEffect(() => {
        if (!show) return
        setShowModal(true)
    }, [show])

    const valueChangeHandler = e => {
        setRemark(e.value)
    }

    const saveData = () => {
        if (!remark) return alert("Please enter a remark.", 'Warning!')

        LoaderService.setData(true);
        let params = new URLSearchParams({
            externalId: externalId,
            remark: remark
        })
        apiClient
            .get('/Transactions/CancelTransaction?' + params)
            .then(response => {
                try {
                    LoaderService.setData(false);
                    let resp = JSON.parse(response.data);
                    if (resp[0].response) {
                        props.isUpdated();
                    } else {
                        return alert(resp[0].message, 'Warning!');
                    }
                    modalClose();
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })

    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-50w prop-img-slider">
            <Modal.Header closeButton className="border-0 pb-0 modal-header">
                <Modal.Title className="fs-5 fw-bold">
                    <span>CANCEL TRANSACTION</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3">
                <div className="py-2 tabs-border">
                    <div className="row tabsContent">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-6 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cancellation Remark</Form.Label>
                                        <TextArea
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Remark"
                                            property="Remark"
                                            value={remark}
                                            onValueChanged={valueChangeHandler}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="pt-2">
                <>
                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                            onClick={saveData}>
                        Save
                    </Button>
                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                            onClick={modalClose}>
                        Cancel
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelTransaction;