import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {alert, confirm} from 'devextreme/ui/dialog'
import apiClient from "../../../services/axios";
import {useNavigate} from "react-router-dom";
import cl from "../../../RetroAssets.System/Utilities";
import {TextBox} from "devextreme-react";
import TransactionsComponent from "../../../RetroAssets.System/Components/TransactionsComponent";

const mapStateToProps = ({settings}) => ({
    dispatch: settings.dispatch,
    SettingsForPropertyExpenses: settings.SettingsForPropertyExpenses
})

const PropertyExpenses = (props) => {
    const navigate = useNavigate();
    const [pageSettings, setPageSettings] = useState({
        externalId: '',
        Name: '',
        Builder: '',
        DisplayPurchaseDate: '',
        PropertyStatus: '',
        PurchasePaymentPlan: false,
        Available: false,
        Occupied: false,
        Currency: ''
    })

    const expensesSpanString = "Property_Expenses"
    const expensesRef = useRef(null)
    const expensesSearchRef = useRef(null)
    const [expensesData, setExpensesData] = useState([])
    const [expensesColumns, setExpensesColumns] = useState([])
    const [expensesGridCustomization, setExpensesGridCustomization] = useState({})

    useEffect(() => {
        if (!props.SettingsForPropertyExpenses) {
            navigate(-1);
            return
        }
        getData()
    }, [props.SettingsForPropertyExpenses])

    function getData(showToast) {
        setPageSettings(props.SettingsForPropertyExpenses);
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: props.SettingsForPropertyExpenses["externalId"],
        })
        apiClient
            .get('/Property/GetAllPropertyExpenses?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setExpensesData(data[0]['Data'])
                    let settings = data[1]["Settings"]
                    setExpensesColumns(settings[0][expensesSpanString][0]['Columns'])
                    setExpensesGridCustomization(settings[0][expensesSpanString][3]['GridCustomization'][0])
                    if (showToast)
                        cl.showSuccessToast()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    //#region Purchase Payment

    const addNewExpense = () => {
        expensesRef.current.addNewExpense(pageSettings.externalId, pageSettings.Currency)
    }

    const clearFilterPurchasePayment = () => {
        expensesRef.current?.instance().clearFilter()
        expensesRef.current?.instance().searchByText('')
        expensesSearchRef.current?.instance().option('value', null)
    }

    const searchPurchasePaymentByText = e => {
        if (!e.event) return
        expensesRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    //#endregion

    const generatePropertyStatus = e => {
        if (e["PurchasePaymentPlan"]) { //Payment Plan
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-na"><span
                className="status-color na"></span>{e["PropertyStatus"]}</label>)
        } else if (!e["Available"]) { //Not Available
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-unoccupied"><span
                className="status-color unoccupied"></span>{e["PropertyStatus"]}</label>)
        } else if (!e["Occupied"]) { //Unoccupied
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-overdue"><span
                className="status-color overdue"></span>{e["PropertyStatus"]}</label>)
        } else if (e["Occupied"]) {//Occupied
            return (<label key={props.key} className="form-check-label fs-4 fw-bold " htmlFor="ps-occupied"><span
                className="status-color occupied"></span>{e["PropertyStatus"]}</label>)
        }
    }

    return (
        <>
            <div
                className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 bg-white border-bottom">
                <div className="d-flex align-items-center fs-5 mb-3">
                    <span className="text-secondary cursor-pointer">Property Listings</span>
                    <Image src="/svg/chevron-left.svg" alt="chevron-left" width="20" height="20" className="mx-1"/>
                    <h2 className="fs-5 fw-medium mb-0 flex-shrink-0 me-4">Purchase Payment Plan Transactions</h2>
                </div>
                <div className="d-flex flex-wrap flex-xl-nowrap mb-3">
                    <Button variant="outline-primary" className="me-2 px-3">Add
                        New Transaction</Button>
                    <Button variant="outline-primary" className="mx-2 px-3">Export
                        All Data</Button>
                </div>
            </div>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-4 mb-3 mb-lg-0">
                            <Card className="card-light p-3 border-0 text-center h-100">
                                <div className="fs-4 fw-bold mb-1">{pageSettings["Name"]}</div>
                                <div className="text-secondary text-uppercase">Property name</div>
                            </Card>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div
                                            className="fs-4 fw-bold mb-1">{pageSettings["DisplayPurchaseDate"] ?? "NOT UPDATED"}</div>
                                        <div className="text-secondary text-uppercase">PURCHASE date</div>
                                    </Card>
                                </div>
                                <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div
                                            className="fs-4 fw-bold mb-1 text-capitalize">{pageSettings["Builder"] ?? "NOT UPDATED"}</div>
                                        <div className="text-secondary text-uppercase">property builder</div>
                                    </Card>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <Card className="card-light p-3 border-0 h-100">
                                        <span
                                            className="fs-7 mb-1 rounded-1 py-1 px-2 align-self-center">{generatePropertyStatus(pageSettings)}</span>
                                        <div className="text-secondary text-uppercase align-self-center">Property
                                            Status
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded p-3 border border-secondary">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                            <h4 className="fs-4 fw-medium mb-3 mb-sm-0"><b>Contract Transactions</b></h4>
                            <div className="d-flex">
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={addNewExpense}>
                                    Add
                                </Button>
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={clearFilterPurchasePayment}>
                                    Clear Filter
                                </Button>
                                <InputGroup className="me-3 search-input">
                                    <TextBox
                                        ref={expensesSearchRef}
                                        placeholder="Search Transactions..."
                                        mode="search"
                                        inputAttr={{class: "form-control temp"}}
                                        elementAttr={{class: "search-input input-group"}}
                                        width="100%"
                                        onValueChanged={searchPurchasePaymentByText}
                                    ></TextBox>
                                </InputGroup>
                            </div>
                        </div>
                        <TransactionsComponent
                            ref={expensesRef}
                            data={expensesData}
                            columns={expensesColumns}
                            gridCustomization={expensesGridCustomization}
                            height={500}
                            income={false}
                            expense={true}
                            propertyExpenses={true}
                            isUpdated={() => getData(true)}
                        ></TransactionsComponent>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default connect(mapStateToProps)(PropertyExpenses)