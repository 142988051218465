import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import React, {useEffect, useRef, useState} from "react";
import LoaderService from "../../RetroAssets.System/LoaderService";
import apiClient from "../../services/axios";
import {alert} from "devextreme/ui/dialog";
import {SelectBox, TextBox} from "devextreme-react";
import PropertiesComponent from "../../RetroAssets.System/Components/PropertiesComponent";
import cl from "../../RetroAssets.System/Utilities";

function Property() {
    const [accountCounts, setAccountCounts] = useState([]);
    const [propertyListingSettings, setPropertyListingSettings] = useState({
        data: [],
        soldData: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL"
    })
    const propertiesRef = useRef()
    const propertiesSearchBox = useRef(null)
    const [height, setHeight] = useState(10)
    const [checked, setChecked] = useState({
        Summary: false,
        CurrentContract: false,
        PurchaseDetails: false,
        PurchasePlan: false
    });
    const allGridSettings = [
        {Name: "ALL", externalId: "ALL", spanString: "PropertyListing"},
        {Name: "Summary", externalId: "Summary", spanString: "PropertyListing_Summary"},
        {Name: "Purchase Detail", externalId: "PurchaseDetail", spanString: "PropertyListing_PurchaseDetail"},
        {Name: "Current Contract", externalId: "CurrentContract", spanString: "PropertyListing_CurrentContract"},
        {Name: "Purchase Plan", externalId: "PurchasePlan", spanString: "PropertyListing_PurchasePlan"}]

    const [selectedGridSettings, setSelectedGridSettings] = useState('ALL')

    useEffect(() => {
        getData(false)
    }, []);

    function getData(showSuccessToast) {
        LoaderService.setData(true)
        apiClient
            .get('/Property/GetAllDataForPropertyListing')
            .then(response => {
                try {
                    setHeight(cl.setDataGridHeight() - 23)
                    let data = JSON.parse(response.data)
                    setAccountCounts(data[0]['AccountsCount'])

                    let spanString = ''
                    let settings = data[1]["GridSettings"]
                    setSelectedGridSettings(settings)
                    let index = allGridSettings.findIndex(x => x.externalId === settings);
                    if (index > -1)
                        spanString = allGridSettings[index]["spanString"]

                    let propListing = data[2]['PropertyListing']
                    setPropertyListingSettings({
                        ...propertyListingSettings,
                        data: propListing[0]['Data'].filter(x => !x["Sold"]),
                        soldData: propListing[0]['Data'].filter(x => x["Sold"]),
                        columns: propListing[1]["Settings"][0][spanString][0]['Columns'],
                        gridCustomization: propListing[1]["Settings"][0][spanString][3]['GridCustomization'][0],
                        currentTab: "ALL"
                    })

                    // setChecked(settings[0])
                    if (showSuccessToast)
                        cl.showSuccessToast()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const searchPropertyByText = e => {
        if (!e.event) return
        propertiesRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const clearFilter = () => {
        propertiesRef.current?.instance().clearFilter()
        propertiesRef.current?.instance().searchByText('')
        propertiesSearchBox.current?.instance().option('value', null)
    }

    const propertyTabChanged = (e) => {

        setPropertyListingSettings({
            ...propertyListingSettings,
            currentTab: e
        })
        if (e === "SOLD") return;
        if (e === "ALL") {
            propertiesRef.current?.instance().clearFilter()
            return;
        }
        propertiesRef.current?.instance().filter(["OwnerExternalId", "=", e])
    }

    const showHideBandSettings = (id, bandName) => {
        LoaderService.setData(true)
        let params = null;
        switch (bandName) {
            case "Summary":
                params = new URLSearchParams({
                    summary: !checked.Summary,
                    currentContract: checked.CurrentContract,
                    purchaseDetails: checked.PurchaseDetails,
                    purchasePlan: checked.PurchasePlan,
                })
                break;
            case "CurrentContract":
                params = new URLSearchParams({
                    summary: checked.Summary,
                    currentContract: !checked.CurrentContract,
                    purchaseDetails: checked.PurchaseDetails,
                    purchasePlan: checked.PurchasePlan,
                })
                break;
            case "PurchaseDetails":
                params = new URLSearchParams({
                    summary: checked.Summary,
                    currentContract: checked.CurrentContract,
                    purchaseDetails: !checked.PurchaseDetails,
                    purchasePlan: checked.PurchasePlan,
                })
                break;
            case "PurchasePlan":
                params = new URLSearchParams({
                    summary: checked.Summary,
                    currentContract: checked.CurrentContract,
                    purchaseDetails: checked.PurchaseDetails,
                    purchasePlan: !checked.PurchasePlan,
                })
                break;
            default:
                return alert("Invalid selection.", "Warning!")
        }
        apiClient
            .get('/Property/ShowHideBandSettings?' + params)
            .then(response => {
                try {
                    LoaderService.setData(false)
                    let resp = JSON.parse(response.data)
                    if (resp[0].response) {
                        cl.showSuccessToast()
                        getData(false)
                    } else {
                        return alert(resp[0].message, 'Warning!')
                    }
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    function addProperty() {
        propertiesRef.current.addNewProperty()
    }

    const changeGridLayout = e => {
        if (!e.event) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            selectedOption: e.value
        })
        apiClient
            .get('/Property/SaveAppUserPropertyListingSettings?' + params)
            .then(response => {
                try {
                    LoaderService.setData(false)
                    let resp = JSON.parse(response.data)
                    if (resp[0].response) {
                        getData(false)
                    } else {
                        return alert(resp[0].message, 'Warning!')
                    }
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (
        <>
            <div
                className="search-actions-header d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 p-lg-3 bg-white border-bottom">
                <div className="d-sm-flex justify-content-between align-items-center flex-fill mb-3 mb-lg-0">
                    <h2 className="fs-5 fw-medium mb-3 mb-sm-0 flex-shrink-0 me-4">Property Listings</h2>
                    <div className="d-flex align-items-center justify-content-sm-end flex-fill me-lg-1">
                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                onClick={clearFilter}>
                            Clear Filter
                        </Button>
                        <InputGroup className="me-3 search-input">
                            <TextBox placeholder="Search Properties..."
                                     ref={propertiesSearchBox}
                                     mode="search"
                                     inputAttr={{class: "form-control"}}
                                     elementAttr={{class: "search-input input-group"}}
                                     width="100%"
                                     onValueChanged={searchPropertyByText}
                            ></TextBox>
                        </InputGroup>
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-xl-nowrap mb-md-3 mb-lg-0">
                    <Button variant="outline-primary" className="me-2 mb-3 mb-md-0" onClick={addProperty}><Image
                        src="/svg/plus.svg"
                        alt="plus"
                    />Add New Property</Button>
                    <Button variant="outline-primary" className="me-2 mb-3 mb-md-0">
                        Export All Data</Button>
                    <div className="d-flex actions-btn-group mb-3 mb-md-0">
                        <Button variant="outline-light" className="action-btn px-2">
                            <Image src="/svg/refresh.svg"
                                   alt="refresh" width="20"
                                   height="20"/></Button>
                        <Button variant="outline-light" className="action-btn px-2">
                            <Image src="/svg/save.svg" alt="save"
                                   width="20"
                                   height="20"/></Button>
                        <Button variant="outline-light" className="action-btn px-2">
                            <Image src="/svg/columns.svg"
                                   alt="columns" width="20"
                                   height="20"/></Button>
                    </div>
                </div>
            </div>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0 dashboard-border">
                    <div
                        className="d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-center mb-0 mb-md-4">
                        {/* Tabs title */}
                        <ul className="nav nav-tabs fs-6 nav-tab tab-sm inner-tab mb-3 mb-xl-0">
                            {accountCounts.map((item, index) => {
                                return (<li key={item["Account"]} className="nav-item" onClick={() => {
                                    propertyTabChanged(item["Account"])
                                }}>
                                    <a className={index === 0 ? "nav-link active" : "nav-link"} data-bs-toggle="tab"
                                       eventKey={item["Account"]}
                                       href={'#' + item["Account"]}>{item["Name"]}<span
                                        className="tab-count"
                                    >{item["COUNT"]}</span></a>
                                </li>)
                            })}
                        </ul>
                        {/*<div className="d-flex justify-md-content-end flex-wrap flex-md-nowrap fs-5 mb-3 mb-xl-0">*/}
                        {/*    <Form.Check type="checkbox" id="summary" label="Summary" className="me-4 mb-2"*/}
                        {/*                checked={checked.Summary}*/}
                        {/*                onClick={() => showHideBandSettings("acaf41a3-61cb-4e43-9335-251237d9a0d4", "Summary")}/>*/}
                        {/*    <Form.Check type="checkbox" id="current-contract" label="Current Contract"*/}
                        {/*                checked={checked.CurrentContract}*/}
                        {/*                className="me-4 mb-2"*/}
                        {/*                onClick={() => showHideBandSettings("f51cc8db-37c7-4431-857e-303d9553c625", "CurrentContract")}/>*/}
                        {/*    <Form.Check type="checkbox" id="purchase-details" label="Purchase Details"*/}
                        {/*                checked={checked.PurchaseDetails}*/}
                        {/*                className="me-4 mb-2"*/}
                        {/*                onClick={() => showHideBandSettings("35b38b11-1d79-4819-8a76-69504b1c449b", "PurchaseDetails")}/>*/}
                        {/*    <Form.Check type="checkbox" id="purchase-plan" label="Purchase Plan" className="me-4 mb-2"*/}
                        {/*                checked={checked.PurchasePlan}*/}
                        {/*                onClick={() => showHideBandSettings("a9788b97-a1cf-4085-9b1d-233ddc68012c", "PurchasePlan")}/>*/}
                        {/*</div>*/}
                        <div className="d-flex align-items-center justify-content-sm-end flex-fill me-lg-1">
                            <InputGroup className="me-3 search-input">
                                <SelectBox placeholder="Select Table Layout"
                                           displayExpr={"Name"}
                                           valueExpr={"externalId"}
                                           dataSource={allGridSettings}
                                           inputAttr={{class: "form-control"}}
                                           elementAttr={{class: "search-input input-group"}}
                                           width="100%"
                                           value={selectedGridSettings}
                                           onValueChanged={changeGridLayout}
                                ></SelectBox>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="tab-content pt-1">
                        <PropertiesComponent
                            ref={propertiesRef}
                            data={propertyListingSettings.currentTab === "SOLD" ? propertyListingSettings.soldData : propertyListingSettings.data}
                            columns={propertyListingSettings.columns}
                            gridCustomization={propertyListingSettings.gridCustomization}
                            height={height}
                            isUpdated={() => getData(true)}
                        ></PropertiesComponent>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default Property;