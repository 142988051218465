import React, {useEffect, useRef, useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InnerComponent from "./InnerComponent";
import {useLocation} from "react-router-dom";

function Transactions() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');
    const allRef = useRef(null);
    const pendingRef = useRef(null);
    const completedRef = useRef(null);
    const cancelledRef = useRef(null);

    useEffect(() => {
        // Parse the tab id from the URL hash
        const hash = location.hash;
        const tabId = hash ? hash.substring(1) : 'transactions'; // Remove '#' from hash
        // Set the active tab based on the tab id from the URL hash
        setActiveTab(tabId);
    }, []);

    useEffect(() => {
        // Parse the tab id from the URL hash
        if (!location.hash.includes('#')) return;
        const hash = location.hash;
        const tabId = hash ? hash.substring(1) : ''; // Remove '#' from hash
        if (!tabId) return;
        if (tabId === activeTab) return
        setActiveTab(tabId);
    }, [location.hash]);

    useEffect(() => {
        // Update the URL hash when the active tab changes
        location.hash = activeTab;
        switch (activeTab) {
            case 'all-transactions':
                allRef.current.getData('all-transactions')
                break;
            case 'pending-transactions':
                pendingRef.current.getData('pending-transactions')
                break;
            case 'completed-transactions':
                completedRef.current.getData('completed-transactions')
                break;
            case 'cancelled-transactions':
                cancelledRef.current.getData('cancelled-transactions')
                break;
        }
    }, [activeTab]);

    return (
        <>
            <Tabs activeKey={`${activeTab}`} className="fs-5 nav-tab px-2" onSelect={(tab) => {
                setActiveTab(tab)
            }}>
                <Tab eventKey="all-transactions" title="All Transactions">
                    <InnerComponent
                        ref={allRef}
                        key={"321FE92F-7C11-40A9-910E-A01CD7196959"}
                        mode={activeTab}
                        active={activeTab === 'all-transactions'}
                    ></InnerComponent>
                </Tab>
                <Tab eventKey="pending-transactions" title="Pending Transactions">
                    <InnerComponent
                        ref={pendingRef}
                        key={"A1942A50-DBEE-4162-8347-79E1F9269C47"}
                        mode={activeTab}
                        active={activeTab === 'pending-transactions'}
                    ></InnerComponent>
                </Tab>
                <Tab eventKey="completed-transactions" title="Completed Transactions">
                    <InnerComponent
                        ref={completedRef}
                        key={"8FDC4052-81BA-4223-A9CB-750A014F7EF7"}
                        mode={activeTab}
                        active={activeTab === 'completed-transactions'}
                    ></InnerComponent>
                </Tab>
                <Tab eventKey="cancelled-transactions" title="Cancelled Transactions">
                    <InnerComponent
                        ref={cancelledRef}
                        key={"16AC0C45-2681-4B87-AA2E-18306316B5EC"}
                        mode={activeTab}
                        active={activeTab === 'cancelled-transactions'}
                    ></InnerComponent>
                </Tab>
            </Tabs>
        </>
    );
}

export default Transactions;