import React from "react";
import _Accounts from "./_Accounts";

function ManageOwners() {
    return (
        <>
            <div
                className="search-actions-header d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 p-lg-3 bg-white border-bottom">
                <div className="d-sm-flex justify-content-between align-items-center flex-fill mb-3 mb-lg-0">
                    <h2 className="fs-5 fw-medium mb-3 mb-sm-0 flex-shrink-0 me-4">Manage Owners</h2>
                </div>
            </div>
            <_Accounts
                mode={"owner"}
            ></_Accounts>
        </>
    );
}

export default ManageOwners;