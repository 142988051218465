import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef, useCallback} from "react";
import Button from "react-bootstrap/Button";
import LoaderService from "../../RetroAssets.System/LoaderService";
import Form from "react-bootstrap/Form";
import {TextBox, ValidationGroup} from "devextreme-react";
import {alert} from "devextreme/ui/dialog";
import apiClient from "../../services/axios";
import Validator, {RequiredRule, CustomRule} from "devextreme-react/validator";

function ManageApplicationUserDetails(props) {
    const {show, externalId} = props
    const [showModal, setShowModal] = useState(false);
    const validationGroup = useRef(null)
    const [details, setDetails] = useState({})

    const modalClose = () => {
        setDetails({})
        props.handleClose()
        setDetails({})
        setShowModal(false);
    }

    useEffect(() => {
        if (!show) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Configuration/GetAppUserDetails?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setDetails(data["Data"])
                    setShowModal(true)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [show])

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        if (prop === "Password") {
            setDetails({
                ...details,
                [prop]: e.value,
                PasswordUpdated: true
            })
            return;
        }
        setDetails({
            ...details,
            [prop]: e.value
        })
    }

    const customValidation = useCallback(e => {
        if (!details["PasswordUpdated"]) return true;
        if (!e.value) {
            e.rule.message = `Confirm Password cannot be empty.`
            return false
        }
        if (e.value !== details["Password"]) {
            e.rule.message = `Confirm Password and password do not match.`
            return false
        }
        return true
    }, [details])

    const saveData = () => {
        if (!validationGroup.current.instance()?.validate().isValid) return
        LoaderService.setData(true)
        let formData = new FormData();
        formData.append("Data", JSON.stringify(details))
        formData.append("SpanString", "_AppUserDetails")
        apiClient
            .post('/Configuration/AddUpdateAppUser', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        modalClose()
                        if (resp[0].response) {
                            props.isUpdated()
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-50w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>MANAGE USER PROFILE</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
            <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                <div className="row tabsContent">
                    <div className="col-sm-12">
                        <ValidationGroup ref={validationGroup}>
                            <div className="row">
                                {/*Name*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Name"
                                            property={"Name"}
                                            value={details["Name"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"Name cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*Username*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Username</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Username"
                                            property={"Username"}
                                            value={details["Username"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"Username cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*Email*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email Id</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Email"
                                            property={"Email"}
                                            value={details["Email"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"Email Id cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*Password*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <TextBox
                                            mode={"password"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Password"
                                            property={"Password"}
                                            valueChangeEvent="focusout"
                                            value={details["Password"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule message={"Password cannot be empty."}></RequiredRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                {/*ConfirmPassword*/}
                                <div className="col-sm-12 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <TextBox
                                            mode={"password"}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Password"
                                            valueChangeEvent="focusout"
                                            property={"ConfirmPassword"}
                                            value={details["ConfirmPassword"]}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                ></CustomRule>
                                            </Validator>
                                        </TextBox>
                                    </Form.Group>
                                </div>
                            </div>
                        </ValidationGroup>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={saveData}>
                Save
            </Button>
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={modalClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default ManageApplicationUserDetails;