import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {DataGrid} from "devextreme-react/data-grid";
import {Popover, Template, TextArea} from "devextreme-react";
import {MoreOptionsButton} from "../../../RetroAssets.System/MoreOptionsButton";
import cl from "../../../RetroAssets.System/Utilities";
import apiClient from "../../../services/axios";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {alert} from "devextreme/ui/dialog";

const ContractsComponent = React.forwardRef((props, gridInstance) => {
    const popoverRef = useRef(null)

    const {data, columns, gridCustomization, height} = props
    const [tenancyContractData, setTenancyContractData] = useState([])
    const [tenancyContractColumns, setTenancyContractColumns] = useState([])
    const [tenancyContractGridCustomization, setTenancyContractGridCustomization] = useState({})

    useEffect(() => {
        setTenancyContractData(data)
        setTenancyContractGridCustomization(gridCustomization)
        let cols = columns
        let index = cols.findIndex(x => x.dataField === "Remark")
        if (index > -1) {
            cols[index]["editCellTemplate"] = "remarksTemplate";
        }
        setTenancyContractColumns(cols)
    }, [data, columns, gridCustomization])

    const rowClickEvent = e => {
        popoverRef.current.instance().option("target", e.rowElement)
        popoverRef.current.instance().show();
    }

    const RenderPopoverTemplate = () => {
        const changeColor = (colorCode) => {
            let selectedRowId = gridInstance.current.instance().getSelectedRowKeys()[0];

            let params = new URLSearchParams({
                objectName: "TenancyContract",
                externalId: selectedRowId,
                color: colorCode,
                remove: colorCode === null
            })
            apiClient
                .get('/Shared/UpdateObjectColor?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            let index = tenancyContractData.findIndex(x => x.externalId === selectedRowId)
                            if (index > -1) {
                                tenancyContractData[index]["Color"] = colorCode
                                setTenancyContractData(tenancyContractData)
                                gridInstance.current.instance().refresh()
                            }

                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                        LoaderService.setData(false)
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        }
        return (
            <>
                <span className="dot dot-color1" onClick={() => changeColor("#D2E2FF")}></span>
                <span className="dot dot-color2" onClick={() => changeColor("#FEE8B3")}></span>
                <span className="dot dot-color3" onClick={() => changeColor("#B1E3CE")}></span>
                <span className="dot dot-color4" onClick={() => changeColor("#D4D4D4")}></span>
                <span className="dot dot-color5" onClick={() => changeColor("#FFCFAF")}></span>
                <span className="dot dot-color6" onClick={() => changeColor("#EDD7FF")}></span>
                <span className="dot dot-color7" onClick={() => changeColor("#FFA7A7")}></span>
                <span className="dot dot-none" onClick={() => changeColor(null)}> </span>
            </>
        )
    }

    const cellPrepared = e => {
        cl.setObjectColor(e)
    }

    return (
        <>
            <Popover
                ref={popoverRef}
                showEvent="dxclick"
                hideOnOutsideClick={true}
                width={180}
                contentRender={RenderPopoverTemplate}
            >
            </Popover>
            <DataGrid
                ref={gridInstance}
                dataSource={tenancyContractData}
                onRowClick={rowClickEvent}
                onCellPrepared={cellPrepared}
                {...tenancyContractGridCustomization}
                keyExpr="externalId"
                height={height}
            >
                {tenancyContractColumns.map((column, i) => (
                    cl.generateReactColumnsFromJson(column)
                ))}
                <Template name="myTemplate" render={MoreOptionsButton}/>
                <Template name="propertyNameTemplate" render={PropertyNameColumn}/>
                <Template name="statusTemplate" render={StatusColumn}/>
                <Template name="remarksTemplate" render={RemarksColumn}/>
            </DataGrid>
        </>
    )
})

export default ContractsComponent;

const PropertyNameColumn = (props) => {
    return (<div key={props.key}>
        <div className="col-12"><b>{props.data["Name"]}</b></div>
        <div className="col-12">{props.data["Unit"]}</div>
    </div>)
}

const StatusColumn = (props) => {
    if (!props.data["Available"]) { //N/A
        return (<label key={props.key} className="form-check-label" title={"Property not available for renting"}><span
            className="status-color"></span>{props.data["ContractStatus"]}</label>)
    } else if (!props.data["Active"]) { //TERMINATED
        return (<label key={props.key} className="form-check-label" title={"Terminated"}><span
            className="status-color"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["Terminated"] && props.data["Active"]) { //PENDING TERMINATION DETAILS
        return (<label key={props.key} className="form-check-label"
                       title={"Contract terminated but pending termination details"}><span
            className="status-color na"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["Overdue"]) { //OVERDUE
        return (<label key={props.key} className="form-check-label" title="Overdue"><span
            className="status-color overdue"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["Due"]) { //DUE
        return (<label key={props.key} className="form-check-label" title="Due"><span
            className="status-color na"></span>{props.data["ContractStatus"]}</label>)
    } else {//OCCUPIED
        return (<label key={props.key} className="form-check-label" title="Occuped"><span
            className="status-color occupied"></span>{props.data["ContractStatus"]}</label>)
    }
}

const RemarksColumn = (props) => {
    const onValueChanged = useCallback(
        (e) => {
            if (!e.event) return
            LoaderService.setData(true)
            let params = new URLSearchParams({
                externalId: props.data.externalId,
                remark: e.value
            })
            apiClient
                .get('/Property/UpdateTenancyContractRemark?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            cl.showSuccessToast()
                            props.setValue(e.value);
                            props.component.refresh()
                        } else {
                            props.setValue(e.previousValue);
                            props.component.refresh()
                            return alert(resp[0].message, 'Warning!')
                        }
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        },
        [props],
    );

    return (<TextArea value={props.value} onValueChanged={onValueChanged}/>)
}
