import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import React, {useCallback, useEffect, useRef, useState} from "react";
import LoaderService from "../../RetroAssets.System/LoaderService";
import apiClient from "../../services/axios";
import {alert, confirm} from "devextreme/ui/dialog";
import {Template, TextBox} from "devextreme-react";
import cl from "../../RetroAssets.System/Utilities";
import {Column, DataGrid, Button as DataGridButton} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../../RetroAssets.System/MoreOptionsButton";
import Button from "react-bootstrap/Button";
import ManageBankAccountDetails from "../Popups/Configuration/ManageBankAccountDetails";
import ManageOwnerVendorAgentDetails from "../Popups/Configuration/ManageOwnerVendorAgentDetails";

const _Accounts = (props) => {
    const {mode} = props
    const spanString = () => {
        switch (mode) {
            case "bank":
                return "BankAccounts"
            case "owner":
                return "OwnerAccounts"
            case "vendor":
                return "Vendors"
        }
    }
    const searchBoxRef = useRef(null)
    const gridInstance = useRef()
    const [gridData, setGridData] = useState([])
    const [gridColumns, setGridColumns] = useState([])
    const [gridCustomization, setGridCustomization] = useState([])


    const [editBankAccountSettings, setEditBankAccountSettings] = useState({
        show: false,
        externalId: ''
    })
    const [editOwnerVendorAgentSettings, setEditOwnerVendorAgentSettings] = useState({
        show: false,
        externalId: '',
        vendor: false,
        agent: false,
        owner: false
    })
    const [height, setHeight] = useState(10)

    useEffect(() => {
        getData(false)
    }, []);

    function getData(showSuccessToast) {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            mode: mode
        })
        apiClient
            .get('/Configuration/GetAllAccounts?' + params)
            .then(response => {
                try {
                    setHeight(cl.setDataGridHeight() - 23)
                    let data = JSON.parse(response.data)
                    setGridData(data[0]["Data"])
                    let settings = data[1]["Settings"]
                    setGridColumns(settings[0][spanString()][0]["Columns"])
                    setGridCustomization(settings[0][spanString()][3]["GridCustomization"][0])
                    if (showSuccessToast)
                        cl.showSuccessToast()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const searchByText = e => {
        gridInstance.current?.instance().searchByText(e.value ? e.value : null)
    }

    const clearFilter = () => {
        gridInstance.current?.instance().clearFilter()
        searchBoxRef.current?.instance().searchByText('')
        searchBoxRef.current?.instance().option('value', null)
    }

    const cellPrepared = e => {
        cl.setHeaderTooltip(e)
        cl.setColumnColorsFromVM(e)
    }

    const editUser = e => {
        switch (mode) {
            case "bank":
                setEditBankAccountSettings({
                    ...editBankAccountSettings,
                    show: true,
                    externalId: e.row.key
                })
                break;
            case "owner":
                setEditOwnerVendorAgentSettings({
                    ...editOwnerVendorAgentSettings,
                    show: true,
                    externalId: e.row.key,
                    vendor: false,
                    agent: false,
                    owner: true
                })
                break;
            case "vendor":
                setEditOwnerVendorAgentSettings({
                    ...editOwnerVendorAgentSettings,
                    show: true,
                    externalId: e.row.key,
                    vendor: e.row.data["Flag_Vendor"],
                    agent: e.row.data["Flag_Agent"],
                    owner: false
                })
                break;
        }
    }

    const addUser = (vendor, agent) => {
        switch (mode) {
            case "bank":
                setEditBankAccountSettings({
                    ...editBankAccountSettings,
                    show: true,
                    externalId: ''
                })
                break;
            case "owner":
                setEditOwnerVendorAgentSettings({
                    ...editOwnerVendorAgentSettings,
                    show: true,
                    externalId: '',
                    vendor: false,
                    agent: false,
                    owner: true
                })
                break;
            case "vendor":
                setEditOwnerVendorAgentSettings({
                    ...editOwnerVendorAgentSettings,
                    show: true,
                    externalId: '',
                    vendor: vendor,
                    agent: agent,
                    owner: false
                })
                break;
        }
    }

    return (
        <>
            <ManageBankAccountDetails
                show={editBankAccountSettings.show}
                externalId={editBankAccountSettings.externalId}
                handleClose={() =>
                    setEditBankAccountSettings({
                        ...editBankAccountSettings,
                        show: true
                    })}
                isUpdated={() => getData(true)}
            ></ManageBankAccountDetails>
            <ManageOwnerVendorAgentDetails
                show={editOwnerVendorAgentSettings.show}
                externalId={editOwnerVendorAgentSettings.externalId}
                agent={editOwnerVendorAgentSettings.agent}
                vendor={editOwnerVendorAgentSettings.vendor}
                owner={editOwnerVendorAgentSettings.owner}
                handleClose={() =>
                    setEditOwnerVendorAgentSettings({
                        ...editOwnerVendorAgentSettings,
                        show: true
                    })}
                isUpdated={() => getData(true)}
            ></ManageOwnerVendorAgentDetails>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0 dashboard-border">
                    <div
                        className="d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-center mb-0 mb-md-4">
                        <div className="d-flex align-items-center justify-content-sm-end flex-fill me-lg-1">
                            {mode === "bank" || mode === "owner" ?
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={() => addUser(false, false)}>
                                    Add
                                </Button> : <></>}
                            {mode === "vendor" ?
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={() => addUser(true, false)}>
                                    Add Vendor
                                </Button> : <></>}
                            {mode === "vendor" ?
                                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                        onClick={() => addUser(false, true)}>
                                    Add Agent
                                </Button> : <></>}
                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={clearFilter}>
                                Clear Filter
                            </Button>
                            <InputGroup className="me-3 search-input">
                                <TextBox placeholder="Search Lookup Values..."
                                         ref={searchBoxRef}
                                         mode="search"
                                         inputAttr={{class: "form-control"}}
                                         elementAttr={{class: "search-input input-group"}}
                                         width="100%"
                                         onValueChanged={searchByText}
                                ></TextBox>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="tab-content pt-1">
                        <DataGrid
                            ref={gridInstance}
                            dataSource={gridData}
                            keyExpr={"externalId"}
                            height={height}
                            onCellPrepared={cellPrepared}
                            {...gridCustomization}
                        >
                            {gridColumns.map(col => {
                                return cl.generateReactColumnsFromJson(col)
                            })}
                            <Column type={"buttons"} width={"50px"}>
                                <DataGridButton icon={"edit"} onClick={editUser}></DataGridButton>
                            </Column>
                            <Template name="myTemplate" render={MoreOptionsButton}/>
                            <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                        </DataGrid>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default _Accounts;