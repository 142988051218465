import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import React, {useCallback, useEffect, useRef, useState} from "react";
import LoaderService from "../../RetroAssets.System/LoaderService";
import apiClient from "../../services/axios";
import {alert, confirm} from "devextreme/ui/dialog";
import {SelectBox, Template, TextBox} from "devextreme-react";
import cl from "../../RetroAssets.System/Utilities";
import DataSource from 'devextreme/data/data_source';
import {Column, DataGrid, Button as DataGridButton} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../../RetroAssets.System/MoreOptionsButton";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";

function ManageLookups() {
    const searchBoxRef = useRef(null)
    const gridInstance = useRef()
    const [gridData, setGridData] = useState([])
    const [gridColumns, setGridColumns] = useState([])
    const [gridCustomization, setGridCustomization] = useState([])
    const [editedData, setEditedData] = useState([])
    const [deletedItems, setDeletedItems] = useState([])

    const [height, setHeight] = useState(10)
    const lookupsList = [
        // Documents
        {
            "Name": "Document Language",
            "id": "DOC001",
            "Category": "1. Documents",
            "SpanString": "_DocumentLanguage",
            "AddUpdateFunction": "AddUpdateDocumentLanguage",
            "MergeFunction": "",
            DefaultFunction: "",
            showAdd: true,
            showDelete: true,
            showMerge: false,
            showDefault: false
        },
        {
            "Name": "Document Type",
            "id": "DOC002",
            "Category": "1. Documents",
            "SpanString": "_DocumentType",
            "AddUpdateFunction": "AddUpdateDocumentType",
            "MergeFunction": "",
            DefaultFunction: "",
            showAdd: true,
            showDelete: true,
            showMerge: false,
            showDefault: false
        },
        // Property
        {
            "Name": "Property Type",
            "id": "ASS001",
            "Category": "2. Property",
            "SpanString": "_PropertyType",
            "AddUpdateFunction": "AddUpdatePropertyType",
            "MergeFunction": "",
            DefaultFunction: "",
            showAdd: true,
            showDelete: true,
            showMerge: false,
            showDefault: false
        },
        // Payments
        {
            "Name": "Tax",
            "id": "PAY001",
            "Category": "3. Payments",
            "SpanString": "_Tax",
            "AddUpdateFunction": "AddUpdateTax",
            "MergeFunction": "",
            DefaultFunction: "",
            showAdd: true,
            showDelete: true,
            showMerge: false,
            showDefault: false
        }, {
            "Name": "Currency",
            "id": "PAY002",
            "Category": "3. Payments",
            "SpanString": "_Currency",
            "AddUpdateFunction": "AddUpdateCurrency",
            "MergeFunction": "",
            DefaultFunction: "SetAsDefaultCurrency",
            showAdd: true,
            showDelete: true,
            showMerge: false,
            showDefault: true
        },
        // Location
        {
            "Name": "Country",
            "id": "COU001",
            "Category": "4. Location",
            "SpanString": "_Country",
            "AddUpdateFunction": "AddUpdateCountry",
            "MergeFunction": "",
            showAdd: true,
            showDelete: true,
            showMerge: false,
            showDefault: false
        }
    ];
    const lookupDataSource = new DataSource({
        store: {
            type: 'array',
            data: lookupsList,
            key: 'id',
        },
        group: 'Category',
    });

    const [selectedOption, setSelectedOption] = useState("DOC001")
    const [selectedOptionData, setSelectedOptionData] = useState(lookupsList[0])
    const [newItemIndex, setNewItemIndex] = useState(0)
    const [isUpdated, setIsUpdated] = useState(false)

    useEffect(() => {
        getData("_DocumentLanguage", false)
    }, []);

    function getData(ss, showSuccessToast) {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            spanString: ss
        })
        apiClient
            .get('/Configuration/GetLookupData?' + params)
            .then(response => {
                try {
                    setHeight(cl.setDataGridHeight() - 23)
                    let data = JSON.parse(response.data)
                    let spanString = `Lookup${ss}`
                    setGridColumns(data[0][spanString][0]["Columns"])
                    setGridData(data[0][spanString][1]["Data"])
                    setGridCustomization(data[0][spanString][4]["GridCustomization"][0])
                    if (showSuccessToast)
                        cl.showSuccessToast()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const handleChange = e => {
        if (!e.event) return
        setSelectedOption(e.value)
        let index = lookupsList.findIndex(x => x.id === e.value);
        if (index > -1) {
            setSelectedOptionData(lookupsList[index])
            getData(lookupsList[index]["SpanString"], false)
        }
    }

    const searchByText = e => {
        gridInstance.current?.instance().searchByText(e.value ? e.value : null)
    }

    const clearFilter = () => {
        gridInstance.current?.instance().clearFilter()
        searchBoxRef.current?.instance().searchByText('')
        searchBoxRef.current?.instance().option('value', null)
    }

    const cellPrepared = e => {
        cl.setHeaderTooltip(e)
        cl.setColumnColorsFromVM(e)
    }

    const rowValidating = e => {
        setEditedData(cl.rowValidatingEvent(e, editedData))
        setIsUpdated(true)
    }

    const addNewRecord = () => {
        gridInstance.current?.instance().addRow()
    }

    const newRowInitialized = useCallback(e => {
        e.data["externalId"] = "NNNN" + newItemIndex
        setNewItemIndex(newItemIndex + 1)
    }, [newItemIndex])

    const deleteFromData = useCallback(e => {
        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = editedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    editedData.splice(index, 1)
                    setEditedData(editedData)
                }
                index = gridData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    gridData.splice(index, 1)
                    gridInstance.current?.instance().option("dataSource", gridData)
                    setGridData(gridData)
                }
                gridInstance.current?.instance().repaint()
                setIsUpdated(true)
                if (e.row.key.includes("NNNN")) return
                deletedItems.push(e.row.key)
                setDeletedItems(deletedItems)
            }
        })
    }, [gridData, editedData, deletedItems])

    const setAsDefault = e => {
        if (e.row.key.includes("NNNN")) return alert("Please save data before marking this item as default.", "Warning!")

        if (!selectedOptionData["DefaultFunction"])
            return alert("No function name set for this action.", "Warning!")

        confirm("Are you sure you wish to mark the selected currency as default?", "Warning!").then(resp => {
            if (resp) {
                let params = new URLSearchParams({
                    externalId: e.row.key,
                    functionName: selectedOptionData["DefaultFunction"]
                })
                apiClient
                    .get('/Configuration/InvokeDefaultMethod?' + params)
                    .then(response => {
                        try {
                            LoaderService.setData(false)
                            let resp = JSON.parse(response.data)
                            setEditedData([])
                            setDeletedItems([])
                            setIsUpdated(false)
                            if (resp[0].response) {
                                getData(selectedOptionData["SpanString"], false)
                                return alert(resp[0].message, 'Success!')
                            } else {
                                return alert(resp[0].message, 'Warning!')
                            }
                        } catch (e) {
                            LoaderService.setData(false)
                            return alert(response.data, 'Error!')
                        }
                    })
                    .catch(err => {
                        LoaderService.setData(false)
                        console.log(err)
                        return alert(err, 'Error!')
                    })
            }
        })
    }

    const saveData = () => {
        let formData = new FormData()
        formData.append("EditedData", JSON.stringify(editedData))
        formData.append("DeletedData", JSON.stringify(deletedItems))
        formData.append("SpanString", selectedOptionData["SpanString"])
        formData.append("FunctionName", selectedOptionData["AddUpdateFunction"])
        apiClient
            .post('/Configuration/InvokeAddUpdateMethod', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        setEditedData([])
                        setDeletedItems([])
                        setIsUpdated(false)
                        if (resp[0].response) {
                            getData(selectedOptionData["SpanString"], false)
                            return alert(resp[0].message, 'Success!')
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (
        <>
            <div
                className="search-actions-header d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 p-lg-3 bg-white border-bottom">
                <div className="d-sm-flex justify-content-between align-items-center flex-fill mb-3 mb-lg-0">
                    <h2 className="fs-5 fw-medium mb-3 mb-sm-0 flex-shrink-0 me-4">Manage Lookups</h2>
                    <div className="d-flex align-items-center justify-content-sm-end flex-fill me-lg-1">
                        <InputGroup className="me-3 search-input">
                            <SelectBox placeholder="Select Option..."
                                       displayExpr={"Name"}
                                       valueExpr={"id"}
                                       dataSource={lookupDataSource}
                                       value={selectedOption}
                                       grouped={true}
                                       mode="search"
                                       inputAttr={{class: "form-control"}}
                                       elementAttr={{class: "search-input input-group"}}
                                       width={400}
                                       onValueChanged={handleChange}
                            ></SelectBox>
                        </InputGroup>
                    </div>
                </div>
            </div>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0 dashboard-border">
                    <div
                        className="d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-center mb-0 mb-md-4">

                        <div className="d-flex align-items-center justify-content-sm-end flex-fill me-lg-1">
                            <Button variant="outline-primary" className="me-2 mb-3 mb-md-0"
                                    disabled={!isUpdated}
                                    onClick={saveData}>Save Changes</Button>
                            {selectedOptionData.showAdd ?
                                <Button variant="outline-primary" className="me-2 mb-3 mb-md-0"
                                        onClick={addNewRecord}>Add</Button> : <></>}
                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={clearFilter}>
                                Clear Filter
                            </Button>
                            <InputGroup className="me-3 search-input">
                                <TextBox placeholder="Search Lookup Values..."
                                         ref={searchBoxRef}
                                         mode="search"
                                         inputAttr={{class: "form-control"}}
                                         elementAttr={{class: "search-input input-group"}}
                                         width="100%"
                                         onValueChanged={searchByText}
                                ></TextBox>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="tab-content pt-1">
                        <DataGrid
                            ref={gridInstance}
                            dataSource={gridData}
                            keyExpr={"externalId"}
                            height={height}
                            onCellPrepared={cellPrepared}
                            onRowValidating={rowValidating}
                            {...gridCustomization}
                            onInitNewRow={newRowInitialized}
                        >
                            {gridColumns.map(col => {
                                return cl.generateReactColumnsFromJson(col)
                            })}
                            <Column type={"buttons"} width={selectedOptionData.showDefault ? "130px" : "50px"}>
                                {selectedOptionData.showDelete ?
                                    <DataGridButton icon={"trash"} onClick={deleteFromData}></DataGridButton> : <></>}
                                {selectedOptionData.showDefault ?
                                    <DataGridButton text={"Make Default"}
                                                    onClick={setAsDefault}></DataGridButton> : <></>}
                            </Column>
                            <Template name="myTemplate" render={MoreOptionsButton}/>
                            <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                        </DataGrid>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default ManageLookups;