import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, {useEffect, useRef, useState} from "react";
import {PivotGrid, Chart, DateBox, Popover} from "devextreme-react";
import {alert} from 'devextreme/ui/dialog'
import {FieldChooser, FieldPanel} from "devextreme-react/pivot-grid";
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ArrayStore from "devextreme/data/array_store";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import apiClient from "../../../services/axios";
import Button from "react-bootstrap/Button";

const ProfileAnalytics = (props) => {
    const {accounts, propListing, loaded} = props
    const [pivotData, setPivotData] = useState([]);
    const arrayStore = new ArrayStore({
        data: pivotData,
        key: 'externalId',
    });
    const dataSource = new PivotGridDataSource({
        fields: [
            {
                caption: "Company",
                width: 400,
                dataField: "CompanyName",
                expanded: true,
                area: "row"
            },
            {
                caption: "Property",
                width: 400,
                dataField: "Property",
                expanded: true,
                area: "row"
            },
            {
                caption: "Date",
                dataField: "Date",
                allowSorting: true,
                expanded: false,
                allowSortingBySummary: true,
                dataType: "date",
                area: "column"
            }, {
                caption: "Income",
                width: 60,
                dataField: "IncomeAmount",
                summaryType: "sum",
                dataType: "number",
                area: "data",
                format: '#,#00.00',
            }, {
                caption: "Expense",
                width: 60,
                dataField: "ExpenseAmount",
                dataType: "number",
                summaryType: "sum",
                area: "data",
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
            }, {
                caption: "Profit",
                width: 60,
                dataField: "Profit",
                dataType: "number",
                summaryType: "sum",
                area: "data",
                format: {
                    type: 'fixedPoint',
                    precision: 2
                },
            }
        ],
        store: arrayStore,
    });
    const chartRef = useRef(null)
    const pivotRef = useRef(null)
    const [date, setDate] = useState({
        d1: new Date(new Date().setFullYear(new Date().getFullYear() - 4)),
        d2: new Date(),
    })
    const [accountCounts, setAccountCounts] = useState([]);
    const [propSelection, setPropSelection] = useState([])
    const [filteredProperties, setFilteredProperties] = useState([])
    let selectedRows = {};
    let selectedCells = {}
    let currentSelectedProperty = '';
    const popoverRef = useRef(null)

    useEffect(() => {
        if (accounts.length === 0 || propListing.length === 0 || !loaded) return
        setAccountCounts(accounts)
        setFilteredProperties(propListing[0]['Data'])
        let width = document.getElementById("SomeId").clientWidth
        pivotRef.current.instance().option("width", width - 50)
        loadData()
    }, [accounts, propListing, loaded]);

    const loadData = () => {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalIds: propSelection.toString(),
            date1: date.d1.toJSON(),
            date2: date.d2.toJSON(),
        })
        apiClient
            .get('/Dashboard/GetProfitAnalyticsPivotReport?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setPivotData(data)
                    pivotRef.current.instance().repaint()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const dateBoxValueChanged = e => {
        let property = e.component.option("property");
        setDate({
            ...date,
            [property]: e.value
        })
    }

    const propertyChanged = (e) => {
        if (e.currentTarget.checked) {
            propSelection.push(e.currentTarget.id)
        } else {
            let index = propSelection.indexOf(e.currentTarget.id)
            propSelection.splice(index, 1)
        }
        setPropSelection(propSelection)
    }

    const cellClick = e => {
        if (e.area === 'row') {
            e.cancel = true;
            if (e.cell.hasOwnProperty('rowspan')) return;
            currentSelectedProperty = '';
            selectedRows = {};
            let path = (e.cell.path || []).join('/');
            selectedRows[path] = !selectedRows[path];
            e.component.repaint();
        } else if (e.area === "data") {
            selectedRows = {};
            let rowPath = (e.cell.rowPath || []).join("/");//,
            selectedRows [rowPath] = !selectedRows[rowPath];
            e.component.repaint();
        }
    };

    const cellPrepared = e => {
        if (e.area === 'data') {
            if (isRowSelected(e.cell.rowPath || [])) {
                e.cellElement.classList.add('pivotgrid-row-selected');
                if (e.cell.dataIndex === 0) {
                    popoverRef.current.instance().option("target", e.cellElement)
                    popoverRef.current.instance().show();
                }
                if (currentSelectedProperty === '') {
                    let drillDownDataSource = dataSource.createDrillDownDataSource(e.cell);
                    drillDownDataSource
                        .store()
                        .load()
                        .done(items => {
                            currentSelectedProperty = items[0]["externalId"];
                            // e.cellElement.style.color = ;

                        });
                }
            } else {
                e.cellElement.classList.remove('pivotgrid-row-selected');
                let index = filteredProperties.findIndex(x => x["Name"] === e.cell.rowPath[1])
                if (index > -1) {
                    if (filteredProperties[index]["Color"])
                        e.cellElement.style.background = filteredProperties[index]["Color"]
                }
            }
        }
    };

    const RenderPopoverTemplate = () => {
        const changeColor = (colorCode) => {
            let selectedRowId = currentSelectedProperty
            let params = new URLSearchParams({
                objectName: "Property",
                externalId: selectedRowId,
                color: colorCode,
                remove: colorCode === null
            })
            apiClient
                .get('/Shared/UpdateObjectColor?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            let index = filteredProperties.findIndex(x => x.externalId === selectedRowId);
                            if (index > -1) {
                                filteredProperties[index]["Color"] = colorCode
                                setFilteredProperties(filteredProperties)
                            }
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                        LoaderService.setData(false)
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        }
        return (
            <>
                <span className="dot dot-color1" onClick={() => changeColor("#D2E2FF")}></span>
                <span className="dot dot-color2" onClick={() => changeColor("#FEE8B3")}></span>
                <span className="dot dot-color3" onClick={() => changeColor("#B1E3CE")}></span>
                <span className="dot dot-color4" onClick={() => changeColor("#D4D4D4")}></span>
                <span className="dot dot-color5" onClick={() => changeColor("#FFCFAF")}></span>
                <span className="dot dot-color6" onClick={() => changeColor("#EDD7FF")}></span>
                <span className="dot dot-color7" onClick={() => changeColor("#FFA7A7")}></span>
                <span className="dot dot-none" onClick={() => changeColor(null)}> </span>
            </>
        )
    }

    function isRowSelected(rowPath) {
        let path = [],
            selected = false;
        rowPath.some(function (value) {
            path.push(value);
            let pathValue = path.join('/');
            selected = selectedRows[pathValue];
            return selected;
        });
        return selected;
    }

    return (
        <div className="main-wrapper p-3">
            <Card id={"SomeId"} className="p-3 border-0 status-card h-100 dashboard-border">
                <div className="d-flex align-items-center justify-content-md-end flex-wrap flex-md-nowrap">
                    <div className="date-filter d-flex align-items-center me-sm-3 mb-3 mb-md-0">
                        <div
                            className="datepicker-input d-flex flex-wrap flex-sm-nowrap align-items-center px-sm-2">
                            <Image src="/svg/calendar.svg" alt="calendar" width="15" height="15"/>
                            <span className="flex-shrink-0 ms-1 me-2 text-secondary mb-1 mb-sm-0">From date</span>
                            <DateBox width={120} value={date.d1} className="input-xs"
                                     elementAttr={{class: 'date-box-group'}}
                                     displayFormat="dd/MM/yyyy"
                                     stylingMode="filled"
                                     style={{background: "#fff", borderRadius: "2px", border: '1px solid #eee'}}
                                     property="d1"
                                     onValueChanged={dateBoxValueChanged}
                            ></DateBox>
                        </div>
                        <div
                            className="datepicker-input d-flex flex-wrap flex-sm-nowrap align-items-center px-sm-2 ms-2">
                            <Image src="/svg/calendar.svg" alt="calendar" width="15" height="15"/>
                            <span className="flex-shrink-0 ms-1 me-2 text-secondary mb-1 mb-sm-0">To date</span>
                            <DateBox width={120} value={date.d2} className="input-xs"
                                     elementAttr={{class: 'date-box-group'}}
                                     displayFormat="dd/MM/yyyy"
                                     stylingMode="filled"
                                     style={{background: "#fff", borderRadius: "2px", border: '1px solid #eee'}}
                                     property="d2"
                                     onValueChanged={dateBoxValueChanged}
                            ></DateBox>
                        </div>
                    </div>
                    <div className="dropdown-filter d-flex align-items-center mb-3 mb-md-0">
                        <div className="rent-overview-dropdown me-3">
                            <Dropdown className="select-prop">
                                <Dropdown.Toggle variant="default" id="prop-selct"
                                                 className="cc-border-dark d-flex text-secondary">
                                    <Image src="/svg/building.svg" alt="building" width="15" height="15"/>
                                    {propSelection.length === 0 ? "ALL PROPERTIES" : `[${propSelection.length}] properties selected`}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="shadow-sm border-0 p-3">
                                    {filteredProperties.map((prop) => {
                                        return (<div className="dropdown-items py-1">
                                            <Form.Check type="checkbox"
                                                        id={prop["externalId"]}
                                                        onClick={propertyChanged}
                                                        label={prop["Name"]}/>
                                        </div>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                onClick={loadData}>
                            Load
                        </Button>
                        {/*<span role="button" className="px-2"><Image src="svg/print.svg" alt="print" width="20"*/}
                        {/*                                            height="20"/></span>*/}
                    </div>
                </div>
                <div className="rent-overview-tabs p-3">
                    <Popover
                        ref={popoverRef}
                        showEvent="dxclick"
                        hideOnOutsideClick={true}
                        width={180}
                        contentRender={RenderPopoverTemplate}
                    ></Popover>
                    <PivotGrid
                        ref={pivotRef}
                        showColumnGrandTotals={false}
                        dataSource={dataSource}
                        showRowTotals={false}
                        showColumnTotals={false}
                        allowSortingBySummary={true}
                        showRowGrandTotals={false}
                        allowSorting={true}
                        allowFiltering={true}
                        allowExpandAll={true}
                        showBorders={true}
                        height={600}
                        // width={"100%"}
                        onCellClick={cellClick}
                        onCellPrepared={cellPrepared}
                    >
                        <FieldPanel
                            showDataFields={false}
                            showRowFields={true}
                            allowExpandAll={true}
                            showColumnFields={true}
                            showFilterFields={false}
                            allowFieldDragging={false}
                            visible={true}
                        ></FieldPanel>
                        <FieldChooser
                            enabled={false}
                        ></FieldChooser>
                    </PivotGrid>
                </div>
            </Card>
        </div>
    );
}

export default ProfileAnalytics;

