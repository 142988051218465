import React, {useEffect, useState} from "react";
import {
    Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, SeriesTemplate, Crosshair,
    ArgumentAxis, Title, ValueAxis, Tooltip
} from 'devextreme-react/chart';

const CompanyAnnualKpi = React.forwardRef((props, gridInstance) => {

    const {data, height, viewSettings} = props
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartData(data)
    }, [data])

    const customizePoint = args => {
        if (args.series) {
            return {
                hoverStyle: {
                    border: {
                        visible: true,
                        color: args.series.getColor()
                    }
                }
            };
        }
    }

    return (
        <Chart
            height={260}
            dataSource={chartData}
            stickyHovering={false}
            customizePoint={customizePoint}
            margin={{
                bottom: 20
            }}>
            <SeriesTemplate
                nameField="Company"
            ></SeriesTemplate>
            <Legend
                verticalAlignment="top"
                itemTextPosition="right"
            ></Legend>
            <CommonSeriesSettings
                argumentField="Year"
                type="spline"
                valueField={viewSettings}
                point={{
                    color: "transparent",
                    hoverMode: "onlyPoint",

                }}
            ></CommonSeriesSettings>
            <ArgumentAxis
                argumentType="string"
                valueMarginsEnabled={true}
                discreteAxisDivisionMode="crossLabels"
                grid={{
                    visible: true,
                    opacity: 0.5
                }}
                title={{
                    text: "YEAR",
                    font: {
                        size: "12px",
                        color: "#28484f",
                        weight: "normal"
                    }
                }}
            ></ArgumentAxis>
            <ValueAxis
                showZero={true}
                grid={{
                    visible: true,
                    opacity: 0.5
                }}
                valueMarginsEnabled={true}
                type="continuous"
                tickInterval={25}
                title={{
                    text: viewSettings === "ProfitPercentage" ? "PROFIT (%)" : "PROFIT (BY AMOUNT)",
                    font: {
                        size: "12px",
                        color: "#28484f",
                        weight: "normal"
                    }
                }}
            ></ValueAxis>
            <Tooltip enabled={true} contentRender={TooltipTemplate}/>
            <Export enabled={false}></Export>
        </Chart>
    )
})

function TooltipTemplate(info) {
    return (
        <div>
            <h4>{info.argument}</h4>
            <div>
                <span><strong>Company</strong></span>: {info.point.data["Company"]}
            </div>
            <div>
                <span><strong>Currency</strong></span>: {info.point.data["Currency"]}
            </div>
            <div>
                <span><strong>Profit Percentage</strong></span>: {info.point.data["ProfitPercentage"]} %
            </div>
            <div>
                <span><strong>Total Income</strong></span>: {info.point.data["DisplayTotalIncome"]}
            </div>
            <div>
                <span><strong>Total Expenses</strong></span>: {info.point.data["DisplayTotalExpense"]}
            </div>
            <div>
                <span><strong>Actual Profit</strong></span>: {info.point.data["DisplayProfitAmount"]}
            </div>
            <div className="mb-2"></div>
            <div>
                <span><strong>Actual Profit [Local] </strong></span>: {info.point.data["DisplayProfitAmountLocal"]}
            </div>
        </div>
    );
}


export default CompanyAnnualKpi;
