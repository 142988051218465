import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import LoaderService from "../../RetroAssets.System/LoaderService";
import Form from "react-bootstrap/Form";
import {DateBox, NumberBox} from "devextreme-react";
import {alert} from "devextreme/ui/dialog";

function AutoGenerateTransactions(props) {
    const {show} = props
    const [showModal, setShowModal] = useState(false);

    const [details, setDetails] = useState({
        StartDate: null,
        NumberOfInstallments: null,
        MonthGap: null,
        Amount: null
    })

    const modalClose = () => {
        setDetails({})
        props.handleClose()
        setDetails({})
        setShowModal(false);
    }

    useEffect(() => {
        if (!show) return
        setDetails({
            StartDate: null,
            NumberOfInstallments: null,
            MonthGap: null,
            Amount: null
        })
        setShowModal(true)
    }, [show])

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        setDetails({
            ...details,
            [prop]: e.value
        })
    }

    const saveData = () => {
        if (details.StartDate === null) return alert("Please enter a Start Date.", "Warning!")
        if (details.NumberOfInstallments === null) return alert("Please enter Number Of Installments.", "Warning!")
        if (details.MonthGap === null) return alert("Please enter Gap Between Installments.", "Warning!")
        if (details.Amount === null) return alert("Please enter Amount.", "Warning!")

        props.isUpdated(details)
        modalClose()
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-50w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>AUTO GENERATE TRANSACTIONS</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
            <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                <div className="row tabsContent">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Date</Form.Label>
                                    <DateBox
                                        displayFormat={"dd/MM/yyyy"}
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Start Date"
                                        property={"StartDate"}
                                        value={details["StartDate"]}
                                        onValueChanged={valueChangeHandler}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Number Of Installments</Form.Label>
                                    <NumberBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Number Of Installments"
                                        property={"NumberOfInstallments"}
                                        value={details["NumberOfInstallments"]}
                                        onValueChanged={valueChangeHandler}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Gap Between Installments [in Months]</Form.Label>
                                    <NumberBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Gap Between Installments [in Months]"
                                        property={"MonthGap"}
                                        value={details["MonthGap"]}
                                        onValueChanged={valueChangeHandler}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Amount</Form.Label>
                                    <NumberBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Amount"
                                        property={"Amount"}
                                        value={details["Amount"]}
                                        onValueChanged={valueChangeHandler}
                                        format={"#,#00.00"}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={saveData}>
                Generate Transactions
            </Button>
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={modalClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default AutoGenerateTransactions;