import {Link, useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import store from 'store';
import {useEffect, useState} from "react";

function Menu() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        menuItems.forEach(item => {
            if (item.children)
                if (!show.hasOwnProperty(item.key))
                    show[item.key] = false
        })
        setShow(show)
    }, [])

    const menuItems = [
        {
            title: "Dashboard",
            icon: "dashboard.svg",
            url: "/dashboard",
            id: "dashboard",
            key: "4391B092-9B74-4FC2-85DD-97863067CB85"
        },
        {
            title: "Transactions",
            icon: "transactions.svg",
            url: "/transactions",
            id: "transactions",
            key: "9BCFE341-4279-491B-9827-B46C427BA422",
            children: [
                {
                    title: "Pending Transactions",
                    icon: "pending-transactions.svg",
                    url: "/transactions#pending-transactions",
                    id: "pending-transactions",
                    key: "8AFDFD8E-CDA0-45DA-B0F4-88FC754E4F01"
                },
                {
                    title: "Completed Transactions",
                    icon: "completed-transactions.svg",
                    url: "/transactions#completed-transactions",
                    id: "completed-transactions",
                    key: "2E39692D-5BEC-4B71-AAEE-833C7FBDC06C"
                },
                {
                    title: "Cancelled Transactions",
                    icon: "cancelled-transactions.svg",
                    url: "/transactions#cancelled-transactions",
                    id: "cancelled-transactions",
                    key: "E59EC630-9F37-46F6-A1C5-58BC79EBD153"
                },
                {
                    title: "All Transactions",
                    icon: "all-transactions.svg",
                    url: "/transactions#all-transactions",
                    id: "all-transactions",
                    key: "3D5C2391-CA91-4C90-AF58-55A765051C4E"
                }
            ]
        },
        // {
        //     title: "Property",
        //     icon: "property.svg",
        //     url: "/property",
        //     id: "property",
        //     key: "C3B15551-EC2C-41EA-ACAB-A4B976EFE329",
        //     children: [
        //         {
        //             title: "Property Listings",
        //             icon: "list.svg",
        //             url: "/property",
        //             id: "property-list",
        //             key: "D62B2501-DF0C-472A-B14A-EDAFEE307427"
        //         },
        //         {
        //             title: "Manage Property Documents",
        //             icon: "file.svg",
        //             url: "/",
        //             id: "prop-doc",
        //             key: "61538F18-DE13-42E2-95E6-7DD31735D274"
        //         },
        //     ]
        // },
        {
            title: "Property Listings",
            icon: "property.svg",
            url: "/property",
            id: "property-list",
            key: "D62B2501-DF0C-472A-B14A-EDAFEE307427"
        },
        {
            title: "Bank Loans",
            icon: "bank.svg",
            url: "/loans",
            id: "bank-loans",
            key: "F4AE7D70-9DD5-421A-95F7-BC71BEEF0E1D"
        },
        {
            title: "Configuration",
            icon: "settings.svg",
            url: "/configuration",
            id: "configuration",
            key: "8B4BB7A5-4657-4585-870A-734E314A14BD",
            children: [{
                title: "Manage Lookups",
                icon: "dictionary.svg",
                url: "/configuration/manage-lookups",
                id: "manage-lookups",
                key: "58452083-FEDA-4242-9634-AEF5925D6BC7",
            }, {
                title: "Manage Application Users",
                icon: "key.svg",
                url: "/configuration/manage-app-users",
                id: "manage-application-users",
                key: "36D7D1C8-0BDA-449C-BE02-F367E33735EF",
            }, {
                title: "Manage Bank Accounts",
                icon: "bank-account.svg",
                url: "/configuration/manage-bank-accounts",
                id: "manage-bank-accounts",
                key: "DE5723FC-6426-48B7-9D1C-AF1C42642569",
            }, {
                title: "Manage Owners",
                icon: "user.svg",
                url: "/configuration/manage-owners",
                id: "manage-Owners",
                key: "261A1415-CF9C-49EB-AC9E-5D42E9AE04BD",
            }, {
                title: "Manage Vendors & Agents",
                icon: "sell-company.svg",
                url: "/configuration/manage-vendors-agents",
                id: "manage-vendors-agents",
                key: "B441ECF9-907C-4B75-9E0C-36E44CA4E615",
            }]
        }
    ];
    const version = store.get("Version")
    const [show, setShow] = useState({})
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark" className="bottom-navigation fixed-bottom py-2 px-lg-3">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-3 my-2 p-0 border-0"/>
            {location.pathname === "/" && <div className="copyright text-white">Copyright @ 2024</div>}
            <div className="text-white order-md-2 fs-5 me-3 me-lg-0 ms-auto"><span
                className="fw-bold">RETROTECH SA </span>
                <span className="rounded inline-block px-1 py-1 version ms-1">v {version}</span>
            </div>
            {location.pathname !== "/" && <Navbar.Collapse id="basic-navbar-nav order-md-1">
                <Nav className="me-auto text-white w-100">
                    {menuItems.map((items) => (
                        <div key={items.key}>
                            {!items.children ? (
                                    <Link key={items.key} to={`${items.url}`} id={items.id}
                                          className={location.pathname === items.url ? "active" : ""}>
                                        <Image src={`/svg/header/${items.icon}`} alt={items.title}/>
                                        {items.title}
                                    </Link>
                                ) :
                                (<NavDropdown key={items.key} autoClose={true} show={show[items.key]}
                                              onToggle={() => {
                                                  setShow({
                                                      ...show,
                                                      [items.key]: !show[items.key]
                                                  })
                                              }}
                                              title={<><Image src={`/svg/header/${items.icon}`}
                                                              alt={items.title}/>{items.title}</>}
                                              id={items.id}
                                              drop="up"
                                              className={`border-0 ${location.pathname === items.url ? "dropdown-active" : ""}`}>
                                    {/* Child items */}
                                    {items.children.map((childItem) => (
                                        <Link key={items.key} to={`${childItem.url}`} id={childItem.id}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  setShow({
                                                      ...show,
                                                      [items.key]: !show[items.key]
                                                  })
                                                  navigate(childItem.url)
                                              }}
                                              className={`py-2 px-3 ${location.pathname === childItem.url || location.hash === ("#" + childItem.id) ? "active" : ""}`}>
                                            <Image src={`/svg/header/${childItem.icon}`} alt={childItem.title}/>
                                            {childItem.title}
                                        </Link>
                                    ))}
                                </NavDropdown>)}
                        </div>
                    ))}
                </Nav>
            </Navbar.Collapse>}
        </Navbar>
    )
}

export default Menu;