import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef, useCallback} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import {FileUploader, NumberBox, Template, TextArea} from 'devextreme-react';
import apiClient, {baseUrl} from '../../services/axios/index'
import LoaderService from "../../RetroAssets.System/LoaderService";
import {alert, confirm} from "devextreme/ui/dialog";
import cl from "../../RetroAssets.System/Utilities";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {TextBox, SelectBox, DateBox, ValidationGroup, Map} from "devextreme-react";
import {DataGrid, Column, Button as DataGridButton} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../../RetroAssets.System/MoreOptionsButton";
import InputGroup from "react-bootstrap/InputGroup";
import Validator, {RequiredRule} from 'devextreme-react/validator';
import axios from "axios";
import TransactionsComponent from "../../RetroAssets.System/Components/TransactionsComponent";

function ManagePropertyDetails(props) {
    const {show, externalId, readOnly} = props
    const [showModal, setShowModal] = useState(false);
    const [activatedTabs, setActivatedTabs] = useState([]);

    const [propertyDetails, setPropertyDetails] = useState({})
    const [countries, setCountries] = useState([])
    const [propertyTypes, setPropertyTypes] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [vendors, setVendors] = useState([])
    const [taxes, setTaxes] = useState([])
    const [agents, setAgents] = useState([])

    const [gridHeight, setGridHeight] = useState(0)
    const validationGroup = useRef(null)
    const faSearchBoxRef = useRef(null)
    const mapRef = useRef(null)
    const mapApiKey = {
        google: "AIzaSyCkAEmCfyndiDxLjoF-NkQPYPCPnH7mc0E"
    }

    let attachmentSpanString = "ItemDocumentList"
    let fileAttachmentCounter = 0;
    let attachedFileCount = 0;
    let attachmentValueChangedEventExecuted = false;
    const attachmentButtonInstance = useRef(null);
    const yesNoOptions = [{Name: "YES", externalId: true}, {Name: "NO", externalId: false}]

    const faGridInstance = useRef(null);
    const [faData, setFaData] = useState([])
    const [faColumns, setFaColumns] = useState([])
    const [faGridCustomization, setFaGridCustomization] = useState({})
    const [faEditedData, setFaEditedData] = useState([]);
    const [deletedFileAttachments, setDeletedFileAttachments] = useState([]);

    const [locationSearchCriteria, setLocationSearchCriteria] = useState('')
    const [locationSearched, setLocationSearched] = useState(false)

    const purchaseDetailSpanString = "Property_PurchasePriceDetails"
    const purchaseDetailRef = useRef(null)
    const purchaseDetailSearchRef = useRef(null)
    const [purchaseDetailData, setPurchaseDetailData] = useState([])
    const [purchaseDetailColumns, setPurchaseDetailColumns] = useState([])
    const [purchaseDetailGridCustomization, setPurchaseDetailGridCustomization] = useState({})
    const [purchaseDetailEditedData, setPurchaseDetailEditedData] = useState([]);
    const [deletedPurchaseDetail, setDeletedPurchaseDetail] = useState([]);

    const titleOwnerSpanString = "Property_TitleOwners"
    const titleOwnerRef = useRef(null)
    const titleOwnerSearchRef = useRef(null)
    const [titleOwnerData, setTitleOwnerData] = useState([])
    const [titleOwnerColumns, setTitleOwnerColumns] = useState([])
    const [titleOwnerGridCustomization, setTitleOwnerGridCustomization] = useState({})
    const [titleOwnerEditedData, setTitleOwnerEditedData] = useState([]);
    const [deletedTitleOwner, setDeletedTitleOwner] = useState([]);

    const shareholderSpanString = "Property_Shareholders"
    const shareholderSearchRef = useRef(null)
    const shareholderRef = useRef(null)
    const [shareholderData, setShareholderData] = useState([])
    const [shareholderColumns, setShareholderColumns] = useState([])
    const [shareholderGridCustomization, setShareholderGridCustomization] = useState({})
    const [shareholderEditedData, setShareholderEditedData] = useState([]);
    const [deletedShareholders, setDeletedShareholders] = useState([]);

    const saleDetailSpanString = "Property_PurchasePriceDetails"
    const saleDetailRef = useRef(null)
    const saleDetailSearchRef = useRef(null)
    const [saleDetailData, setSaleDetailData] = useState([])
    const [saleDetailColumns, setSaleDetailColumns] = useState([])
    const [saleDetailGridCustomization, setSaleDetailGridCustomization] = useState({})
    const [saleDetailEditedData, setSaleDetailEditedData] = useState([]);
    const [deletedSaleDetail, setDeletedSaleDetail] = useState([]);

    const [newItemIndex, setNewItemIndex] = useState(0)

    useEffect(() => {
        if (!show) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Property/GetPropertyDetailsForEditing?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let propDetail = data[0]["Details"]["Data"]
                    if (externalId) {
                        propDetail["PropertyType"] = cl.getEcoIdFromExternalId(propDetail["PropertyType"]);
                        propDetail["Country"] = cl.getEcoIdFromExternalId(propDetail["Country"]);
                        propDetail["Tax"] = cl.getEcoIdFromExternalId(propDetail["Tax"]);
                        propDetail["Currency"] = cl.getEcoIdFromExternalId(propDetail["Currency"]);
                        propDetail["Builder"] = cl.getEcoIdFromExternalId(propDetail["Builder"]);
                        propDetail["SellerAgent"] = cl.getEcoIdFromExternalId(propDetail["SellerAgent"])
                    }
                    setPropertyDetails(propDetail)
                    setCountries(data[1]["Country"])
                    setPropertyTypes(data[2]["PropertyType"])
                    setCurrencies(data[3]["Currency"])
                    setTaxes(data[4]["Tax"])
                    setVendors(data[5]["Vendors"])


                    setShowModal(true)
                    setTimeout(() => {
                        validationGroup.current.instance().reset()
                        if (propDetail.Latitude && propDetail.Longitude) {
                            mapRef.current?.instance().addMarker({
                                "location": {
                                    lat: propDetail["Latitude"],
                                    lng: propDetail["Longitude"]
                                },
                                "tooltip": {"text": propDetail["Name"]}
                            }).done(function (marker) {
                                marker.setDraggable(true);
                            });
                            mapRef.current?.instance().option("zoom", 15);
                        }
                        let el = document.getElementById("ManageTransaction_DetailsTab").clientHeight
                        setGridHeight(el - 52)
                    }, 200)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [show])

    //#region Modal Events

    const modalClose = () => {
        setActivatedTabs(["Details"])
        setLocationSearchCriteria('')
        props.handleClose()
        resetAttachmentControl()
        setPropertyDetails({})
        setShowModal(false);

        setFaData([])
        setFaEditedData([])
        setDeletedFileAttachments([])

        setTitleOwnerEditedData([])
        setDeletedTitleOwner([])
        setTitleOwnerData([])

        setShareholderEditedData([])
        setDeletedShareholders([])
        setShareholderData([])

        setSaleDetailData([])
        setSaleDetailGridCustomization([])
        setSaleDetailEditedData([])

        setDeletedPurchaseDetail([])
        setPurchaseDetailEditedData([])
        setPurchaseDetailData([])
    }

    const tabSelectionChanged = (e) => {
        const index = activatedTabs.findIndex(x => x === e);
        if (index > -1) {
            return;
        }
        setActivatedTabs(oldArray => [...oldArray, e]);
        let url = ""
        let params = null;
        switch (e) {
            case "Attachments":
                params = new URLSearchParams({
                    externalId: externalId,
                    objectType: "Transaction"
                })
                url = "/Property/GetFileAttachments?" + params
                break;
            case "PurchaseDetails":
                params = new URLSearchParams({
                    externalId: externalId,
                    sale: false
                })
                url = "/Property/GetPropertyPurchaseDetail?" + params
                break;
            case "SellingDetails":
                params = new URLSearchParams({
                    externalId: externalId,
                    sale: true
                })
                url = "/Property/GetPropertyPurchaseDetail?" + params
                break;
            case "TitleOwners":
                params = new URLSearchParams({
                    externalId: externalId
                })
                url = "/Property/GetPropertyTitleOwners?" + params
                break;
            case "Shareholders":
                params = new URLSearchParams({
                    externalId: externalId
                })
                url = "/Property/GetPropertyShareholders?" + params
                break;
        }
        if (!url)
            return;
        LoaderService.setData(true)
        apiClient
            .get(url)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let settings = data[1]["Settings"]
                    let gc = {}
                    switch (e) {
                        case "Attachments":
                            setFaData(data[0]["Data"])
                            setFaColumns(settings[0][attachmentSpanString][0]["Columns"])
                            setFaGridCustomization(settings[0][attachmentSpanString][3]["GridCustomization"][0])
                            faGridInstance.current.instance().repaint()
                            break
                        case "PurchaseDetails":
                            setPurchaseDetailData(data[0]["Data"])
                            setPurchaseDetailColumns(settings[0][purchaseDetailSpanString][0]["Columns"])
                            setPurchaseDetailGridCustomization(settings[0][purchaseDetailSpanString][3]["GridCustomization"][0])
                            purchaseDetailRef.current.instance().repaint()
                            break
                        case "Shareholders":
                            setShareholderData(data[0]["Data"])
                            setShareholderColumns(settings[0][shareholderSpanString][0]["Columns"])
                            setShareholderGridCustomization(settings[0][shareholderSpanString][3]["GridCustomization"][0])
                            shareholderRef.current.instance().repaint()
                            break
                        case "TitleOwners":
                            setTitleOwnerData(data[0]["Data"])
                            setTitleOwnerColumns(settings[0][titleOwnerSpanString][0]["Columns"])
                            setTitleOwnerGridCustomization(settings[0][titleOwnerSpanString][3]["GridCustomization"][0])
                            titleOwnerRef.current.instance().repaint()
                            break
                        case "SellingDetails":
                            setSaleDetailData(data[0]["Data"])
                            setSaleDetailColumns(settings[0][purchaseDetailSpanString][0]["Columns"])
                            setSaleDetailGridCustomization(settings[0][purchaseDetailSpanString][3]["GridCustomization"][0])
                            saleDetailRef.current.instance().repaint()
                            break
                    }
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    //#endregion

    //#region Map
    const locationEntered = e => {
        mapRef.current.instance().removeMarker(0);
        setLocationSearched(true);
        mapRef.current.instance().addMarker({
            "location": locationSearchCriteria,
            "tooltip": {"text": locationSearchCriteria}
        }).done(function (marker) {
            marker.setDraggable(true);
            let lat = marker.position.lat();
            let lng = marker.position.lng();
            propertyDetails.Latitude = lat;
            propertyDetails.Longitude = lng;
            setPropertyDetails(propertyDetails)
            getLocationDetailsByCoOrdinates(lat, lng);
        });
    }

    const markerAdded = useCallback((e) => {
        if (locationSearched) {
            e.component.option("zoom", 15);
            getLocationDetails(e.options.location);
        }
    }, [])

    function getLocationDetails(location) {
        axios.get("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCkAEmCfyndiDxLjoF-NkQPYPCPnH7mc0E&address="
            + location +
            "&sensor=true").then(resp => {
            return getFormattedAddress(resp.data)
        })

    }

    function getLocationDetailsByCoOrdinates(latitude, longitude) {
        axios.get("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCkAEmCfyndiDxLjoF-NkQPYPCPnH7mc0E&latlng="
            + latitude + "," + longitude).then(resp => {
            return getFormattedAddress(resp.data);
        })
    }

    const getFormattedAddress = useCallback((data) => {
        if (data.results.length === 0) {
            return alert("Location not found.", "Warning!")
        } else {
            propertyDetails.Address = data.results[0]["formatted_address"];
            propertyDetails.Latitude = data.results[0]["geometry"]["location"]["lat"];
            propertyDetails.Longitude = data.results[0]["geometry"]["location"]["lng"];
            let country =
                data["results"][0]["address_components"][data["results"][0]["address_components"].length - 2][
                    "short_name"];
            countries.forEach(function (item) {
                if (item["ShortName"] === country)
                    propertyDetails.Country = item["externalId"];
            });
            setPropertyDetails(propertyDetails)
        }
    }, [propertyDetails])

    const locationValueChanged = e => {
        setLocationSearchCriteria(e.value)
    }
    //#endregion

    //#region Attachment Tab
    const clearFilterFileAttachment = () => {
        faGridInstance.current?.instance().clearFilter()
        faGridInstance.current?.instance().searchByText('')
        faSearchBoxRef.current?.instance().option('value', null)
    }

    const searchDocumentByText = e => {
        if (!e.event) return
        faGridInstance.current?.instance().searchByText(e.value ? e.value : null)
    }

    const fileUploaded = useCallback((e) => {
        if (e.value.length === 0) return;
        let nwIndex = newItemIndex;
        e.value.forEach((val, index) => {
            if (val.hasOwnProperty("uniqueId")) return
            let ind = `NNNN${nwIndex}`;
            faData.push({
                Name: val.name,
                DocumentType: '',
                externalId: ind,
                DocumentLanguage: '',
                ExpiryDate: null,
                Status: '',
                isNew: true,
            });
            e.value[index]['uniqueId'] = ind;
            nwIndex++
        });
        setFaData(faData);
        setNewItemIndex(nwIndex)
        faGridInstance.current.instance().refresh();
    }, [newItemIndex, faData])

    function resetAttachmentControl() {
        attachmentValueChangedEventExecuted = true;
        attachmentButtonInstance.current.instance().reset();
        attachmentValueChangedEventExecuted = false;
        attachedFileCount = 0;
        fileAttachmentCounter = 0;
    }

    const executeAttachmentCommands = useCallback(() => {
        attachmentButtonInstance.current.instance()._isCustomClickEvent = true;
        attachmentButtonInstance.current.instance()['_$fileInput'][0].click();
    }, []);

    const selectionChangedFileAttachments = e => {
        cl.singleSelectionCheckbox(e);
    };

    const refRowValidating = e => {
        setFaEditedData(cl.rowValidatingEvent(e, faEditedData));
    };

    const cellPreparedFileAttachments = e => {
        cl.setHeaderTooltip(e);
    };

    const addNewPhotos = () => {
        executeAttachmentCommands()
    }

    const buttonDownloadFile = (e) => {
        faGridInstance.current?.instance().selectRows([e.row.key], false)
        return downloadSelected()
    }

    const downloadSelected = () => {
        let ids = faGridInstance.current.instance().getSelectedRowKeys();
        if (ids.length === 0) return alert("Please select one or more files to download.", "Warning!")
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalIds: ids.toString(),
            objectType: 'Property',
            rootObjectExternalId: externalId
        })
        apiClient
            .get('/Shared/GenerateDocumentsReport?' + params)
            .then(response => {
                try {
                    let resp = JSON.parse(response.data)
                    if (resp[0].response) {
                        let path = baseUrl() + resp[0]['message'];
                        let win = window.open(path, '_blank');
                        win.focus();
                    } else {
                        return alert(resp[0].message + '<br /><br /><i>[TIP: Most errors in file downloads are caused by popup blockers.' +
                            '<br />Kindly configure your browser to allow popups if errors persist.</i>', 'Warning!');
                    }
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const deleteFileAttachment = useCallback((e) => {
        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = faEditedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    faEditedData.splice(index, 1)
                    setFaEditedData(faEditedData)
                }
                index = faData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    faData.splice(index, 1)
                    faGridInstance.current.instance().option("dataSource", faData)
                    setFaData(faData)
                }

                faGridInstance.current.instance().repaint()
                if (e.row.key.includes("NNNN")) return

                let items = attachmentButtonInstance.current.instance().option('value');
                index = items.findIndex(x => x["uniqueId"] === e.row.key)
                if (index > -1) {
                    items.splice(index, 1)
                    attachmentButtonInstance.current.instance().option('value', items);
                }
                deletedFileAttachments.push(e.row.key)
                setDeletedFileAttachments(deletedFileAttachments)
            }
        })
    }, [faEditedData, deletedFileAttachments, faData])

    //#endregion

    //#region Purchase Price

    const clearFilterPurchaseDetail = e => {
        purchaseDetailRef.current?.instance().clearFilter()
        purchaseDetailRef.current?.instance().searchByText('')
        purchaseDetailSearchRef.current?.instance().option('value', null)
    }

    const cellPreparedPurchasePriceDetail = e => {
        cl.setHeaderTooltip(e);
        cl.setColumnColorsFromVM(e)
    }

    const rowValidatingPurchasePriceDetail = e => {
        setPurchaseDetailEditedData(cl.rowValidatingEvent(e, purchaseDetailEditedData))
    }

    const selectionChangedPurchasePriceDetail = e => {
        cl.singleSelectionCheckbox(e);
    }

    const searchPurchaseDetailByText = e => {
        if (!e.event) return
        purchaseDetailRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const addPurchaseDetailRecord = () => {
        purchaseDetailRef.current.instance().addRow()
    }

    const initRowPurchaseDetail = e => {
        e.data["externalId"] = "NNNN" + newItemIndex;
        e.data["AdditionalCost"] = false
        e.data["Sale"] = false
        setNewItemIndex(newItemIndex + 1);
    }

    const deletePurchaseDetail = useCallback((e) => {
        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = purchaseDetailEditedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    purchaseDetailEditedData.splice(index, 1)
                    setPurchaseDetailEditedData(purchaseDetailEditedData)
                }

                index = purchaseDetailData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    purchaseDetailData.splice(index, 1)
                    purchaseDetailRef.current.instance().option("dataSource", purchaseDetailData)
                    setPurchaseDetailData(purchaseDetailData)
                }
                purchaseDetailRef.current.instance().repaint()
                if (e.row.key.includes("NNNN")) return
                deletedPurchaseDetail.push(e.row.key)
                setDeletedPurchaseDetail(deletedPurchaseDetail)
            }
        })
    }, [purchaseDetailEditedData, deletedPurchaseDetail, purchaseDetailData])

    //#endregion

    //#region Title Owners

    const cellPreparedTitleOwners = e => {
        cl.setHeaderTooltip(e);
        cl.setColumnColorsFromVM(e)
    }

    const rowValidatingTitleOwners = e => {
        setTitleOwnerEditedData(cl.rowValidatingEvent(e, titleOwnerEditedData))
    }

    const selectionChangedTitleOwners = e => {
        cl.singleSelectionCheckbox(e);
    }

    const addTitleOwners = () => {
        titleOwnerRef.current.instance().addRow()
    }

    const clearFilterTitleOwners = () => {
        titleOwnerRef.current?.instance().clearFilter()
        titleOwnerRef.current?.instance().searchByText('')
        titleOwnerSearchRef.current?.instance().option('value', null)
    }

    const searchTitleOwnerByText = e => {
        if (!e.event) return
        titleOwnerRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const deleteTitleOwner = useCallback((e) => {
        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = titleOwnerEditedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    titleOwnerEditedData.splice(index, 1)
                    setTitleOwnerEditedData(titleOwnerEditedData)
                }
                index = titleOwnerData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    titleOwnerData.splice(index, 1)
                    titleOwnerRef.current.instance().option("dataSource", titleOwnerData)
                    setTitleOwnerData(titleOwnerData)
                }
                titleOwnerRef.current.instance().repaint()
                if (e.row.key.includes("NNNN")) return
                deletedTitleOwner.push(e.row.key)
                setDeletedTitleOwner(deletedTitleOwner)
            }
        })
    }, [titleOwnerEditedData, deletedTitleOwner, titleOwnerData])

    const initRowTitleOwner = e => {
        e.data["externalId"] = "NNNN" + newItemIndex;
        e.data["DefaultOwner"] = false
        setNewItemIndex(newItemIndex + 1);
    }

    //#endregion

    //#region Shareholders

    const cellPreparedShareholders = e => {
        cl.setHeaderTooltip(e);
        cl.setColumnColorsFromVM(e)
    }

    const rowValidatingShareholders = e => {
        setShareholderEditedData(cl.rowValidatingEvent(e, shareholderEditedData))
    }

    const selectionChangedShareholders = e => {
        cl.singleSelectionCheckbox(e);
    }

    const addShareholders = () => {
        shareholderRef.current.instance().addRow()
    }

    const clearFilterShareholders = () => {
        shareholderRef.current?.instance().clearFilter()
        shareholderRef.current?.instance().searchByText('')
        shareholderSearchRef.current?.instance().option('value', null)
    }

    const searchShareholdersByText = e => {
        if (!e.event) return
        shareholderRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const deleteShareholder = useCallback((e) => {
        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = shareholderEditedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    shareholderEditedData.splice(index, 1)
                    setShareholderData(shareholderEditedData)
                }
                index = shareholderData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    shareholderData.splice(index, 1)
                    shareholderRef.current.instance().option("dataSource", shareholderData)
                    setShareholderData(shareholderData)
                }
                shareholderRef.current.instance().repaint()
                if (e.row.key.includes("NNNN")) return
                deletedShareholders.push(e.row.key)
                setDeletedShareholders(deletedShareholders)
            }
        })
    }, [shareholderEditedData, deletedShareholders, shareholderData])

    //#endregion

    //#region Selling Details
    const clearFilterSellingDetail = e => {
        saleDetailRef.current?.instance().clearFilter()
        saleDetailRef.current?.instance().searchByText('')
        saleDetailSearchRef.current?.instance().option('value', null)
    }

    const cellPreparedSellingPriceDetail = e => {
        cl.setHeaderTooltip(e);
        cl.setColumnColorsFromVM(e)
    }

    const rowValidatingSellingPriceDetail = e => {
        setSaleDetailEditedData(cl.rowValidatingEvent(e, saleDetailEditedData))
    }

    const selectionChangedSellingPriceDetail = e => {
        cl.singleSelectionCheckbox(e);
    }

    const initRowSellingDetail = useCallback(e => {
        e.data["externalId"] = "NNNN" + newItemIndex;
        e.data["AdditionalCost"] = false
        e.data["Sale"] = true
        setNewItemIndex(newItemIndex + 1);
    }, [newItemIndex])

    const deleteSaleDetail = useCallback((e) => {
        confirm("Are you sure you wish to delete?", "Warning!").then(resp => {
            if (resp) {
                let index = saleDetailEditedData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    saleDetailEditedData.splice(index, 1)
                    setSaleDetailEditedData(saleDetailEditedData)
                }

                index = saleDetailData.findIndex(x => x["externalId"] === e.row.key)
                if (index > -1) {
                    saleDetailData.splice(index, 1)
                    purchaseDetailRef.current.instance().option("dataSource", saleDetailData)
                    setSaleDetailData(saleDetailData)
                }
                saleDetailRef.current.instance().repaint()
                if (e.row.key.includes("NNNN")) return
                deletedSaleDetail.push(e.row.key)
                setDeletedSaleDetail(deletedSaleDetail)
            }
        })
    }, [saleDetailEditedData, deletedSaleDetail, saleDetailData])

    const addSaleDetailRecord = () => {
        saleDetailRef.current.instance().addRow()
    }

    const searchSaleDetailByText = e => {
        if (!e.event) return
        saleDetailRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    //#endregion

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        setPropertyDetails({
            ...propertyDetails,
            [prop]: e.value
        })
    }

    const saveData = () => {
        if (!validationGroup.current.instance().validate().isValid) {
            return
        }
        LoaderService.setData(true);
        let formData = new FormData();
        formData.append('PropertyData', JSON.stringify(propertyDetails));
        formData.append('PropertySpanString', '_PropertyDetails');

        formData.append("PurchaseDetail", JSON.stringify(purchaseDetailEditedData));
        formData.append('PurchaseDetailSpanString', purchaseDetailSpanString);
        formData.append('PurchaseDetailDeleted', JSON.stringify(deletedPurchaseDetail));

        formData.append("TitleOwner", JSON.stringify(titleOwnerEditedData));
        formData.append('TitleOwnerSpanString', titleOwnerSpanString);
        formData.append('TitleOwnerDeleted', JSON.stringify(deletedTitleOwner));

        formData.append("Shareholder", JSON.stringify(shareholderEditedData));
        formData.append('ShareholderSpanString', shareholderSpanString);
        formData.append('ShareholderDeleted', JSON.stringify(deletedShareholders));

        formData.append("SaleDetail", JSON.stringify(saleDetailEditedData));
        formData.append('SaleDetailSpanString', saleDetailSpanString);
        formData.append('SaleDetailDeleted', JSON.stringify(deletedSaleDetail));

        formData.append('FileAttachmentData', JSON.stringify(faData));
        formData.append(
            'FileAttachmentEditedData',
            JSON.stringify([{spanString: attachmentSpanString, editedData: faEditedData}]),
        );
        formData.append('DeletedFileAttachments', JSON.stringify(deletedFileAttachments));
        let fa = attachmentButtonInstance.current.instance().option('value');
        fa.forEach(val => {
            formData.append(val['uniqueId'], val, val['uniqueId']);
        });
        apiClient
            .post('/Property/AddUpdateProperty', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        modalClose();
                        if (resp[0].response) {
                            props.isUpdated();
                        } else {
                            return alert(resp[0].message, 'Warning!');
                        }
                        resetAttachmentControl();
                        modalClose();
                    } catch (e) {
                        resetAttachmentControl();
                        modalClose();
                        LoaderService.setData(false);
                        return alert(response.data, 'Error!');
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false);
                console.log(err);
            });
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-80w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>MANAGE PROPERTY DETAILS</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2">
            <FileUploader
                style={{display: 'none'}}
                ref={attachmentButtonInstance}
                multiple={true}
                accept=".pdf, .xlsx, .xls, .doc, .docx, .txt, .jpg, .png, .bmp"
                labelText=""
                allowCanceling={false}
                showFileList={false}
                selectButtonText="Add Documents"
                onValueChanged={fileUploaded}
                uploadMode="useForm"
            ></FileUploader>
            <Tabs defaultActiveKey="Details" className="fs-6 nav-tab tab-sm inner-tab"
                  onSelect={tabSelectionChanged}>
                <Tab eventKey="Details" title="Details">
                    <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                        <div className="row tabsContent">
                            <div className="col-sm-6">
                                <div className="col-sm-12">
                                    <Form.Group className="mb-2">
                                        <Map
                                            ref={mapRef}
                                            width="100%"
                                            height={282}
                                            autoAdjust={true}
                                            controls={true}
                                            provider={"google"}
                                            apiKey={mapApiKey}
                                            onMarkerAdded={markerAdded}
                                        ></Map>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Enter Location to search on map..."
                                            value={locationSearchCriteria}
                                            readOnly={readOnly}
                                            onValueChanged={locationValueChanged}
                                            onEnterKey={locationEntered}
                                        ></TextBox>
                                    </Form.Group>
                                </div>
                                {/*Description*/}
                                <div className="col-sm-12">
                                    <Form.Group className="mb-2">
                                        <Form.Label>Description</Form.Label>
                                        <TextArea
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Description"
                                            value={propertyDetails["Description"]}
                                            readOnly={readOnly}
                                            property="Description"
                                            onValueChanged={valueChangeHandler}
                                            height={90}
                                        >
                                        </TextArea>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <ValidationGroup ref={validationGroup}>
                                    <div className="row">
                                        {/*Code*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Code</Form.Label>
                                                <TextBox
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Code"
                                                    property="Code"
                                                    value={propertyDetails["Code"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Code cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </TextBox>
                                            </Form.Group>
                                        </div>
                                        {/*Unit*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Unit</Form.Label>
                                                <TextBox
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Unit"
                                                    property="Unit"
                                                    value={propertyDetails["Unit"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Unit cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </TextBox>
                                            </Form.Group>
                                        </div>
                                        {/*Size*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Size (SQ.M)</Form.Label>
                                                <NumberBox
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Size"
                                                    value={propertyDetails["Size"]}
                                                    readOnly={readOnly}
                                                    property="Size"
                                                    format="#,#00.00"
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Unit cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </NumberBox>
                                            </Form.Group>
                                        </div>
                                        {/*Title*/}
                                        <div className="col-sm-12">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Name</Form.Label>
                                                <TextBox
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Name"
                                                    value={propertyDetails["Name"]}
                                                    readOnly={readOnly}
                                                    property="Name"
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Name cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </TextBox>
                                            </Form.Group>
                                        </div>
                                        {/*Beds*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Bed(s)</Form.Label>
                                                <TextBox
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Beds"
                                                    property="Beds"
                                                    value={propertyDetails["Beds"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                </TextBox>
                                            </Form.Group>
                                        </div>
                                        {/*ParkingSlot*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Parking Slot(s)</Form.Label>
                                                <TextBox
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Parking Slot(s)"
                                                    property="ParkingSlot"
                                                    value={propertyDetails["ParkingSlot"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                </TextBox>
                                            </Form.Group>
                                        </div>
                                        {/*PropertyType*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Type</Form.Label>
                                                <SelectBox
                                                    displayExpr={"Name"}
                                                    valueExpr={"externalId"}
                                                    dataSource={propertyTypes}
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Property Type"
                                                    property="PropertyType"
                                                    value={propertyDetails["PropertyType"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Unit cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </SelectBox>
                                            </Form.Group>
                                        </div>
                                        {/*Address*/}
                                        <div className="col-sm-12">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Address</Form.Label>
                                                <TextArea
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Address"
                                                    value={propertyDetails["Address"]}
                                                    readOnly={readOnly}
                                                    property="Address"
                                                    onValueChanged={valueChangeHandler}
                                                    height={90}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Address cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </TextArea>
                                            </Form.Group>
                                        </div>
                                        {/*Country*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Country</Form.Label>
                                                <SelectBox
                                                    displayExpr={"Name"}
                                                    valueExpr={"externalId"}
                                                    dataSource={countries}
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Country"
                                                    property="Country"
                                                    value={propertyDetails["Country"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                    <Validator>
                                                        <RequiredRule
                                                            message="Country cannot be empty"></RequiredRule>
                                                    </Validator>
                                                </SelectBox>
                                            </Form.Group>
                                        </div>
                                        {/*Available*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Available</Form.Label>
                                                <SelectBox
                                                    displayExpr={"Name"}
                                                    valueExpr={"externalId"}
                                                    dataSource={yesNoOptions}
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Available"
                                                    property="Available"
                                                    value={propertyDetails["Available"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                </SelectBox>
                                            </Form.Group>
                                        </div>
                                        {/*Tax*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Tax</Form.Label>
                                                <SelectBox
                                                    displayExpr={"Name"}
                                                    valueExpr={"externalId"}
                                                    dataSource={taxes}
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Tax"
                                                    property="Tax"
                                                    value={propertyDetails["Tax"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                </SelectBox>
                                            </Form.Group>
                                        </div>
                                        {/*Currency*/}
                                        <div className="col-sm-4">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Currency</Form.Label>
                                                <SelectBox
                                                    displayExpr={"Name"}
                                                    valueExpr={"externalId"}
                                                    dataSource={currencies}
                                                    inputAttr={{class: "form-control"}}
                                                    elementAttr={{class: "input-group"}}
                                                    placeholder="Currency"
                                                    property="Currency"
                                                    value={propertyDetails["Currency"]}
                                                    readOnly={readOnly}
                                                    onValueChanged={valueChangeHandler}
                                                >
                                                </SelectBox>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </ValidationGroup>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="PurchaseDetails" title="Purchase Details">
                    <div className="py-2 tabs-border">
                        <div className="row tabsContent">
                            <div className="col-sm-4" id={"PurchaseDetailsForm"}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Purchase Price</Form.Label>
                                            <NumberBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Purchase Price"
                                                value={propertyDetails["TotalPurchasePrice"]}
                                                readOnly={true}
                                                property="PurchaseDate"
                                                onValueChanged={valueChangeHandler}
                                                format={'#,#00.00'}
                                            >
                                            </NumberBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Purchase Price [Local]</Form.Label>
                                            <NumberBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Purchase Price [Local]"
                                                value={propertyDetails["LocalTotalPurchasePrice"]}
                                                readOnly={true}
                                                property="LocalTotalPurchasePrice"
                                                format={'#,#00.00'}
                                            >
                                            </NumberBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Purchase Date</Form.Label>
                                            <DateBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Purchase Date"
                                                value={propertyDetails["PurchaseDate"]}
                                                readOnly={readOnly}
                                                property="PurchaseDate"
                                                onValueChanged={valueChangeHandler}
                                                displayFormat={'dd/MM/yyyy'}
                                            >
                                            </DateBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Evaluation Date [Official]</Form.Label>
                                            <DateBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Evaluation Date [Official]"
                                                value={propertyDetails["OfficialEvaluationDate"]}
                                                readOnly={readOnly}
                                                property="OfficialEvaluationDate"
                                                onValueChanged={valueChangeHandler}
                                                displayFormat={'dd/MM/yyyy'}
                                            >
                                            </DateBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Evaluation Price</Form.Label>
                                            <NumberBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Evaluation Price"
                                                value={propertyDetails["OfficialEvaluationPrice"]}
                                                readOnly={readOnly}
                                                property="OfficialEvaluationPrice"
                                                onValueChanged={valueChangeHandler}
                                                format={"#,#00.00"}
                                            >
                                            </NumberBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Current Market Price Date</Form.Label>
                                            <DateBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Current Market Price Date"
                                                value={propertyDetails["CurrentMarketPriceDate"]}
                                                readOnly={readOnly}
                                                property="CurrentMarketPriceDate"
                                                onValueChanged={valueChangeHandler}
                                                displayFormat={'dd/MM/yyyy'}
                                            >
                                            </DateBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Current Market Price</Form.Label>
                                            <NumberBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Current Market Price"
                                                value={propertyDetails["CurrentMarketPrice"]}
                                                readOnly={readOnly}
                                                property="CurrentMarketPrice"
                                                onValueChanged={valueChangeHandler}
                                                format={"#,#00.00"}
                                            >
                                            </NumberBox>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8" style={{borderLeft: '1px solid #e7e7e7 !important'}}>
                                <div
                                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                    <div className="d-flex">
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={addPurchaseDetailRecord}>
                                            Add
                                        </Button>
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={clearFilterPurchaseDetail}>
                                            Clear Filter
                                        </Button>
                                        <InputGroup className="me-3 search-input">
                                            <TextBox
                                                ref={purchaseDetailSearchRef}
                                                placeholder="Search Purchase Detail..."
                                                mode="search"
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "search-input input-group"}}
                                                width="100%"
                                                onValueChanged={searchPurchaseDetailByText}
                                            ></TextBox>
                                        </InputGroup>
                                    </div>
                                </div>
                                <DataGrid
                                    ref={purchaseDetailRef}
                                    keyExpr="externalId"
                                    dataSource={purchaseDetailData}
                                    height={gridHeight}
                                    onCellPrepared={cellPreparedPurchasePriceDetail}
                                    onRowValidating={rowValidatingPurchasePriceDetail}
                                    onSelectionChanged={selectionChangedPurchasePriceDetail}
                                    onInitNewRow={initRowPurchaseDetail}
                                    {...purchaseDetailGridCustomization}
                                >
                                    {purchaseDetailColumns.map(column => (
                                        cl.generateReactColumnsFromJson(column)
                                    ))}
                                    <Column
                                        type="buttons"
                                        width="5%"
                                    > <DataGridButton
                                        icon={"trash"}
                                        visible={true}
                                        onClick={deletePurchaseDetail}
                                    ></DataGridButton></Column>
                                    <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                    <Template name="myTemplate" render={MoreOptionsButton}/>
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="PaymentPlan" title="Payment Plan">
                    <div className="py-2 tabs-border">
                        <div className="row tabsContent">
                            {/*PurchasePaymentPlan*/}
                            <div className="col-sm-4">
                                <Form.Group className="mb-2">
                                    <Form.Label>Under Payment Plan</Form.Label>
                                    <SelectBox
                                        dataSource={yesNoOptions}
                                        displayExpr="Name"
                                        valueExpr="externalId"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Under Payment Plan"
                                        property="PurchasePaymentPlan"
                                        value={propertyDetails["PurchasePaymentPlan"]}
                                        readOnly={readOnly}
                                        onValueChanged={valueChangeHandler}
                                    >
                                    </SelectBox>
                                </Form.Group>
                            </div>
                            {/*ConstructionCompletionDate*/}
                            <div className="col-sm-4">
                                <Form.Group className="mb-2">
                                    <Form.Label>Completion Date</Form.Label>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Completion Date"
                                        property="ConstructionCompletionDate"
                                        value={propertyDetails["ConstructionCompletionDate"]}
                                        readOnly={readOnly}
                                        onValueChanged={valueChangeHandler}
                                    >
                                    </DateBox>
                                </Form.Group>
                            </div>
                            {/*ConstructionHandoverDate*/}
                            <div className="col-sm-4">
                                <Form.Group className="mb-2">
                                    <Form.Label>Handover Date</Form.Label>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Handover Date"
                                        value={propertyDetails["ConstructionHandoverDate"]}
                                        readOnly={readOnly}
                                        property="ConstructionHandoverDate"
                                        onValueChanged={valueChangeHandler}
                                    >
                                        <Validator>
                                            <RequiredRule
                                                message="Unit cannot be empty"></RequiredRule>
                                        </Validator>
                                    </DateBox>
                                </Form.Group>
                            </div>
                            {/*Builder*/}
                            <div className="col-sm-12">
                                <Form.Group className="mb-2">
                                    <Form.Label>Seller [Mandatory for Purchase Payment Plan]</Form.Label>
                                    <SelectBox
                                        searchEnabled={true}
                                        dataSource={vendors}
                                        displayExpr="Name"
                                        valueExpr="externalId"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Seller"
                                        value={propertyDetails["Builder"]}
                                        readOnly={readOnly}
                                        property="Builder"
                                        onValueChanged={valueChangeHandler}
                                    >
                                    </SelectBox>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="TitleOwners" title="Title Owners">
                    <div className="py-2 tabs-border">
                        <div className="row tabsContent">
                            <div className="col-sm-12">
                                <div
                                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                    <div className="d-flex">
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={addTitleOwners}>
                                            Add
                                        </Button>
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={clearFilterTitleOwners}>
                                            Clear Filter
                                        </Button>
                                        <InputGroup className="me-3 search-input">
                                            <TextBox
                                                ref={titleOwnerSearchRef}
                                                placeholder="Search Title Owners..."
                                                mode="search"
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "search-input input-group"}}
                                                width="100%"
                                                onValueChanged={searchTitleOwnerByText}
                                            ></TextBox>
                                        </InputGroup>
                                    </div>
                                </div>
                                <DataGrid
                                    ref={titleOwnerRef}
                                    key={"6D52FCE5-3F6E-44B8-9817-BF114FB06B52"}
                                    keyExpr="externalId"
                                    dataSource={titleOwnerData}
                                    // columns={titleOwnerColumns}
                                    height={gridHeight}
                                    onCellPrepared={cellPreparedTitleOwners}
                                    onRowValidating={rowValidatingTitleOwners}
                                    onSelectionChanged={selectionChangedTitleOwners}
                                    onInitNewRow={initRowTitleOwner}
                                    {...titleOwnerGridCustomization}
                                >
                                    {titleOwnerColumns.map(col => {
                                        return (cl.generateReactColumnsFromJson(col))
                                    })}
                                    <Column
                                        type="buttons"
                                        width="5%"
                                    > <DataGridButton
                                        icon={"trash"}
                                        visible={true}
                                        onClick={deleteTitleOwner}
                                    ></DataGridButton></Column>
                                    <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                    <Template name="myTemplate" render={MoreOptionsButton}/>
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="Shareholders" title="Shareholders">
                    <div className="py-2 tabs-border">
                        <div className="row tabsContent">
                            <div className="col-sm-12">
                                <div
                                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                    <div className="d-flex">
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={addShareholders}>
                                            Add
                                        </Button>
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={clearFilterShareholders}>
                                            Clear Filter
                                        </Button>
                                        <InputGroup className="me-3 search-input">
                                            <TextBox
                                                ref={shareholderSearchRef}
                                                placeholder="Search Shareholders..."
                                                mode="search"
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "search-input input-group"}}
                                                width="100%"
                                                onValueChanged={searchShareholdersByText}
                                            ></TextBox>
                                        </InputGroup>
                                    </div>
                                </div>
                                <DataGrid
                                    ref={shareholderRef}
                                    keyExpr="externalId"
                                    dataSource={shareholderData}
                                    height={gridHeight}
                                    onCellPrepared={cellPreparedShareholders}
                                    onRowValidating={rowValidatingShareholders}
                                    onSelectionChanged={selectionChangedShareholders}
                                    {...shareholderGridCustomization}
                                >
                                    {shareholderColumns.map(col => {
                                        return (cl.generateReactColumnsFromJson(col))
                                    })}
                                    <Column
                                        type="buttons"
                                        width="5%"
                                    > <DataGridButton
                                        icon={"trash"}
                                        visible={true}
                                        onClick={deleteShareholder}
                                    ></DataGridButton></Column>
                                    <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                    <Template name="myTemplate" render={MoreOptionsButton}/>
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="Attachments" title="Attachments">
                    <div className="py-2 tabs-border">
                        <div className="row tabsContent">
                            <div
                                className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                <div className="d-flex">
                                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                            onClick={clearFilterFileAttachment}>
                                        Clear Filter
                                    </Button>
                                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                            onClick={downloadSelected}>
                                        Download
                                    </Button>
                                    {readOnly ? <></> :
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={addNewPhotos}>
                                            Add Documents
                                        </Button>}
                                    <InputGroup className="me-3 search-input">
                                        <TextBox
                                            ref={faSearchBoxRef}
                                            placeholder="Search Documents..."
                                            mode="search"
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "search-input input-group"}}
                                            width="100%"
                                            onValueChanged={searchDocumentByText}
                                        ></TextBox>
                                    </InputGroup>
                                </div>
                            </div>
                            <DataGrid
                                ref={faGridInstance}
                                keyExpr="externalId"
                                dataSource={faData}
                                height={gridHeight}
                                onCellPrepared={cellPreparedFileAttachments}
                                onRowValidating={refRowValidating}
                                onSelectionChanged={selectionChangedFileAttachments}
                                {...faGridCustomization}
                            >
                                {faColumns.map(col => {
                                    return (cl.generateReactColumnsFromJson(col))
                                })}
                                <Column
                                    type="buttons"
                                    width="6%"
                                > <DataGridButton
                                    icon={"trash"}
                                    visible={true}
                                    onClick={deleteFileAttachment}
                                ></DataGridButton>
                                    <DataGridButton
                                        icon="chevrondown"
                                        visible={true}
                                        onClick={buttonDownloadFile}
                                    ></DataGridButton>
                                </Column>
                                <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                <Template name="myTemplate" render={MoreOptionsButton}/>
                            </DataGrid>
                        </div>
                    </div>
                </Tab>
                {propertyDetails["Sold"] ? <Tab eventKey="SellingDetails" title="Selling Details">
                    <div className="py-2 tabs-border">
                        <div className="row tabsContent">
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Selling Price</Form.Label>
                                            <NumberBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Total Sale Price"
                                                value={propertyDetails["TotalSalePrice"]}
                                                readOnly={true}
                                                property="TotalSalePrice"
                                                onValueChanged={valueChangeHandler}
                                                format={'#,#00.00'}
                                            >
                                            </NumberBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Sale Price [Local]</Form.Label>
                                            <NumberBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Sale Price [Local]"
                                                value={propertyDetails["LocalTotalSalePrice"]}
                                                readOnly={true}
                                                property="LocalTotalSalePrice"
                                                format={'#,#00.00'}
                                            >
                                            </NumberBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Sale Date</Form.Label>
                                            <DateBox
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Sale Date"
                                                value={propertyDetails["SoldDate"]}
                                                readOnly={readOnly}
                                                property="SoldDate"
                                                onValueChanged={valueChangeHandler}
                                                displayFormat={'dd/MM/yyyy'}
                                            >
                                            </DateBox>
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12">
                                        <Form.Group className="mb-2">
                                            <Form.Label>Sale Remarks</Form.Label>
                                            <TextArea
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "input-group"}}
                                                placeholder="Sale Remarks"
                                                value={propertyDetails["SoldRemarks"]}
                                                readOnly={readOnly}
                                                property="SoldRemarks"
                                                onValueChanged={valueChangeHandler}
                                            >
                                            </TextArea>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8" style={{borderLeft: '1px solid #e7e7e7 !important'}}>
                                <div
                                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                    <div className="d-flex">
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={addSaleDetailRecord}>
                                            Add
                                        </Button>
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={clearFilterSellingDetail}>
                                            Clear Filter
                                        </Button>
                                        <InputGroup className="me-3 search-input">
                                            <TextBox
                                                ref={saleDetailSearchRef}
                                                placeholder="Search Sale Detail..."
                                                mode="search"
                                                inputAttr={{class: "form-control"}}
                                                elementAttr={{class: "search-input input-group"}}
                                                width="100%"
                                                onValueChanged={searchSaleDetailByText}
                                            ></TextBox>
                                        </InputGroup>
                                    </div>
                                </div>
                                <DataGrid
                                    ref={saleDetailRef}
                                    keyExpr="externalId"
                                    dataSource={saleDetailData}
                                    height={gridHeight}
                                    onCellPrepared={cellPreparedSellingPriceDetail}
                                    onRowValidating={rowValidatingSellingPriceDetail}
                                    onSelectionChanged={selectionChangedSellingPriceDetail}
                                    onInitNewRow={initRowSellingDetail}
                                    {...saleDetailGridCustomization}
                                >
                                    {saleDetailColumns.map(column => (
                                        cl.generateReactColumnsFromJson(column)
                                    ))}
                                    <Column
                                        type="buttons"
                                        width="5%"
                                    > <DataGridButton
                                        icon={"trash"}
                                        visible={true}
                                        onClick={deleteSaleDetail}
                                    ></DataGridButton></Column>
                                    <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                    <Template name="myTemplate" render={MoreOptionsButton}/>
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </Tab> : <></>}
            </Tabs>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            {readOnly ? <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                onClick={modalClose}>
                Close
            </Button> : <>
                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                        onClick={saveData}>
                    Save
                </Button>
                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                        onClick={modalClose}>
                    Cancel
                </Button>
            </>}
        </Modal.Footer>

    </Modal>)
}

export default ManagePropertyDetails;