import {useCallback, useEffect, useRef, useState} from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {alert, confirm} from 'devextreme/ui/dialog';


import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import LoaderService from "../../../RetroAssets.System/LoaderService";
import apiClient from "../../../services/axios";
import {TabContainer, TabContent} from "react-bootstrap";
import PropertiesComponent from "../../../RetroAssets.System/Components/PropertiesComponent";
import {SelectBox, TextBox} from "devextreme-react";
import TransactionsComponent from "../../../RetroAssets.System/Components/TransactionsComponent";
import ContractsComponent from "./ContractsComponent";
import CompanyAnalyticsComponent from "./CompanyAnalyticsComponent";
import CompanyAnnualKpi from "./CompanyAnnualKpi";

const Overview = (props) => {
    const {accounts, propListing} = props

    const [headerTile, setHeaderTile] = useState({})
    const [accountCounts, setAccountCounts] = useState([]);

    const [propertyTabs, setPropertyTabs] = useState([])

    const propertiesRef = useRef()
    const propertiesSearchBox = useRef(null)
    const [propertyListingSettings, setPropertyListingSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL",
        gridHeight: 640
    })

    const expensesRef = useRef()
    const expensesSearchBox = useRef(null)
    const [expensesSettings, setExpensesSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL",
        tabs: []
    })

    const contractsRef = useRef()
    const contractsSearchBox = useRef(null)
    const [contractSettings, setContractSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL",
        gridHeight: 640,
        tabs: []
    })

    const incomeRef = useRef()
    const incomeSearchBox = useRef(null)
    const [incomeSettings, setIncomeSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL",
        tabs: []
    })

    let companyWiseProfitRef = useRef(null)
    const [companyWiseProfitSettings, setCompanyWiseProfitSettings] = useState({
        currentTab: "ALL",
        data: [],
        filteredData: [],
        viewSettings: "ProfitPercentage",
        options: [{Name: "View By Percentage", externalId: "ProfitPercentage"}, {
            Name: "View By Profit Amount [Local]",
            externalId: "Profit"
        }]
    })

    const profitAnalyticsRef = useRef(null)
    const [profitAnalyticsSettings, setProfitAnalyticsSettings] = useState({
        currentTab: "ALL",
        data: [],
        filteredData: [],
        dates: [],
        selectedDate: "ALL"
    })

    function updateSize(updateProp) {
        let height = document.getElementById("contractsCard").clientHeight
        let h = height - 115
        setContractSettings({...contractSettings, gridHeight: h})
        if (updateProp)
            setPropertyListingSettings({...propertyListingSettings, gridHeight: h})
        else
            return h;
    }

    window.addEventListener('resize', () => {
        // updateSize(true)
    });

    useEffect(() => {
        if (accounts.length === 0 || propListing.length === 0) return
        LoaderService.setData(true)
        setAccountCounts(accounts)
        setPropertyTabs(accounts)
        let h = updateSize(false)
        setPropertyListingSettings({
            ...propertyListingSettings,
            data: propListing[0]['Data'],
            columns: propListing[1]["Settings"][0]["DashboardProperties"][0]['Columns'],
            gridCustomization: propListing[1]["Settings"][0]["DashboardProperties"][3]['GridCustomization'][0],
            currentTab: "ALL",
            gridHeight: h
        })

        apiClient
            .get('/Dashboard/GetAllDataForOverviewDashboard')
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setHeaderTile(data[0]['TilesConfiguration'][0])
                    let expenses = data[1]['Expenses']
                    setExpensesSettings({
                        ...expensesSettings,
                        data: expenses[0]["Data"],
                        columns: expenses[1]["Settings"][0]["DashboardTransactions"][0]['Columns'],
                        gridCustomization: expenses[1]["Settings"][0]["DashboardTransactions"][3]['GridCustomization'][0],
                        currentTab: "ALL",
                        tabs: getTabCountsForAccounts(expenses[0]["Data"])
                    })
                    let contracts = data[2]['Contracts']
                    setContractSettings({
                        ...expensesSettings,
                        data: contracts[0]['Data'],
                        columns: contracts[1]["Settings"][0]["DashboardContracts"][0]['Columns'],
                        gridCustomization: contracts[1]["Settings"][0]["DashboardContracts"][3]['GridCustomization'][0],
                        currentTab: "ALL",
                        tabs: getTabCountsForAccounts(contracts[0]["Data"])
                    })
                    let income = data[3]['Income']
                    setIncomeSettings({
                        ...incomeSettings,
                        data: income[0]['Data'],
                        columns: income[1]["Settings"][0]["DashboardTransactions"][0]["Columns"],//income[1]["Settings"][0]["DashboardContracts"][0]['Columns'],
                        gridCustomization: income[1]["Settings"][0]["DashboardTransactions"][3]['GridCustomization'][0],
                        currentTab: "ALL",
                        tabs: getTabCountsForAccounts(income[0]["Data"])
                    })
                    let pAndL = data[4]["CompanyProfitAnalytics"];
                    let index = pAndL.findIndex(x => x["AccountExternalId"] === "ALL")
                    setProfitAnalyticsSettings({
                        ...profitAnalyticsSettings,
                        currentTab: "ALL",
                        filteredData: index > -1 ? [pAndL[index]] : [],
                        data: pAndL,
                        dates: data[5]["ProfitAnalyticsDates"]
                    })
                    let companyPAndL = data[6]["CompanyWiseKpi"]
                    setCompanyWiseProfitSettings({
                        ...companyWiseProfitSettings,
                        data: companyPAndL,
                        filteredData: companyPAndL
                    })
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })

    }, [accounts, propListing]);

    const getTabCountsForAccounts = (data) => {
        let tempTabs = []
        accounts.forEach(val => {
            if (val["Account"] === "ALL") {
                tempTabs.push({Account: "ALL", Name: "ALL", COUNT: data.length})
            } else {
                tempTabs.push({
                    Account: val.Account,
                    Name: val.Name,
                    COUNT: data.filter(x => x["OwnerExternalId"].toLowerCase() === val["Account"].toLowerCase()).length
                })
            }
        })
        return tempTabs
    }

    const getUpdatedExpenses = () => {
        LoaderService.setData(true)
        apiClient
            .get('/Dashboard/GetDashboardExpenses')
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setExpensesSettings({
                        ...expensesSettings,
                        data: data[0]["Data"],
                        tabs: getTabCountsForAccounts(data[0]["Data"])
                    })
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const getUpdatedIncomes = () => {
        LoaderService.setData(true)
        apiClient
            .get('/Dashboard/GetDashboardIncome')
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setIncomeSettings({
                        ...incomeSettings,
                        data: data[0]["Data"],
                        tabs: getTabCountsForAccounts(data[0]["Data"])
                    })
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const getUpdatedProperties = () => {
        LoaderService.setData(true)
        apiClient
            .get('/Dashboard/GetDashboardProperties')
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setPropertyListingSettings({
                        ...propertyListingSettings,
                        data: data[0]["Data"],
                    })
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    //#region Tiles
    const generateTiles = () => {
        return (<div className="row mb-3 mb-lg-0">
            <div className="col-sm-6 col-md-4 mb-3 col-lg mb-lg-3">
                <Card className="p-3 border-0 status-card h-100 dashboard-border">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="status-info">
                            <div className="status-title mb-1">Total Income (2024)</div>
                            <div className="fs-2 fw-medium">AED {headerTile["IncomeTransactions"]}</div>
                        </div>
                        <div className="flex-shrink-0">
                            <Image src="/svg/dashboard/total-income.svg" alt="total-income"/>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-sm-6 col-md-4 mb-3 col-lg mb-lg-3">
                <Card className="p-3 border-0 status-card h-100 dashboard-border">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="status-info">
                            <div className="status-title mb-1">Total Expense (2024)</div>
                            <div className="fs-2 fw-medium">AED {headerTile["ExpenseTransactions"]}</div>
                        </div>
                        <div className="flex-shrink-0">
                            <Image src="/svg/dashboard/total-expenses.svg" alt="total-expenses"/>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-sm-6 col-md-4 mb-3 col-lg mb-lg-3">
                <Card className="p-3 border-0 status-card h-100 dashboard-border">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="status-info">
                            <div className="status-title mb-1">Pending Income (2024)</div>
                            <div className="fs-2 fw-medium">AED {headerTile["PendingIncomeTransactions"]}</div>
                        </div>
                        <div className="flex-shrink-0">
                            <Image src="/svg/dashboard/total-income.svg" alt="total-income"/>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-sm-6 col-md-4 mb-3 mb-md-0 col-lg mb-lg-3">
                <Card className="p-3 border-0 status-card h-100 align-self-stretch dashboard-border">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="status-info">
                            <div className="status-title mb-1">Pending Expense (2024)</div>
                            <div className="fs-2 fw-medium">AED {headerTile["PendingExpenseTransactions"]}</div>
                        </div>
                        <div className="flex-shrink-0">
                            <Image src="/svg/dashboard/total-expenses.svg" alt="total-expenses"/>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-sm-6 col-md-4 mb-md-0 col-lg mb-lg-3">
                <Card className="p-3 border-0 status-card h-100 dashboard-border">
                    <div className="d-flex align-items-center">
                        <div className="status-info">
                            <div className="status-title mb-1">Properties/Occupied/Unoccupied</div>
                            <div className="fs-2 fw-medium">{headerTile["PropertyOverview"]}</div>
                        </div>
                    </div>
                    <ProgressBar variant="primary" now={60}/>
                </Card>
            </div>
        </div>)
    }
    //#endregion

    //#region Profit Analytics

    const profitAnalyticsTabChanged = e => {
        let index = profitAnalyticsSettings.data.findIndex(x => x["AccountExternalId"] === e)
        if (index > -1) {
            setProfitAnalyticsSettings({
                ...profitAnalyticsSettings,
                currentTab: e,
                filteredData: [profitAnalyticsSettings.data[index]]
            })
        }
    }

    const profitAnalyticsOptionChanged = useCallback(e => {
        if (!e.event) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            year: e.value
        })
        apiClient
            .get('/Dashboard/GetProfitAnalytics?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let index = data.findIndex(x => x["AccountExternalId"] === profitAnalyticsSettings.currentTab)
                    if (index > -1) {
                        setProfitAnalyticsSettings({
                            ...profitAnalyticsSettings,
                            filteredData: [data[index]],
                            data: data,
                            selectedDate: e.value
                        })
                        profitAnalyticsRef.current.instance().option("dataSource", [data[index]])
                    }
                    profitAnalyticsRef.current.instance().render()
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [profitAnalyticsSettings])

    const generateProfitAnalytics = () => {
        return (<div className="col-lg-12 col-xl-6">
            <Card className="p-3 border-0 properties-list h-100 dashboard-border">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="fs-4 fw-medium m-0"><b>Profit Analytics</b></h4>
                    <div className="d-flex mw-100 justify-content-end">
                        <InputGroup className="me-3 search-input">
                            <SelectBox placeholder="Select Year"
                                       mode="search"
                                       displayExpr={"Year"}
                                       valueExpr={"Year"}
                                       dataSource={profitAnalyticsSettings.dates}
                                       value={profitAnalyticsSettings.selectedDate}
                                       onValueChanged={profitAnalyticsOptionChanged}
                                       inputAttr={{class: "form-control"}}
                                       elementAttr={{class: "search-input input-group"}}
                                       width="100%"
                            ></SelectBox>
                        </InputGroup>
                    </div>
                </div>
                <TabContainer>
                    <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                          onSelect={profitAnalyticsTabChanged}>
                        {propertyTabs.filter(x => x["Account"] !== "SOLD").map((item) => (
                            <Tab id="containerTabProperties" eventKey={item["Account"]}
                                 key={item["Account"]}
                                 title={<>{item["Name"]}</>}>
                            </Tab>
                        ))}
                        <TabContent eventKey={profitAnalyticsSettings.currentTab}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <CompanyAnalyticsComponent
                                        ref={profitAnalyticsRef}
                                        data={profitAnalyticsSettings.filteredData}
                                        height={243}
                                    ></CompanyAnalyticsComponent>
                                </div>
                            </div>
                        </TabContent>
                    </Tabs>
                </TabContainer>
            </Card>
        </div>)
    }
    //#endregion

    //#region Company Profit Component
    const companyWiseProfitOptionChanged = e => {
        setCompanyWiseProfitSettings({
            ...companyWiseProfitSettings,
            viewSettings: e.value
        })
    }

    const companyWiseProfitTabChanged = (e) => {
        let filteredData = []
        if (e === "ALL")
            filteredData = companyWiseProfitSettings.data
        else
            filteredData = companyWiseProfitSettings.data.filter(x => x["OwnerExternalId"].toLowerCase() === e.toLowerCase())
        setCompanyWiseProfitSettings({
            ...companyWiseProfitSettings,
            currentTab: e,
            filteredData: filteredData
        })
    }

    const generateCompanyProfitComponent = () => {
        return (<div className="col-lg-12 col-xl-6">
            <Card className="p-3 border-0 properties-list mb-3 mb-lg-0 dashboard-border">
                <div
                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0 me-4"><b>Company-Wise Profit</b></h4>
                    <div className="d-flex mw-100 justify-content-end">
                        <InputGroup className="me-3 search-input">
                            <SelectBox placeholder="Select Year"
                                       mode="search"
                                       displayExpr={"Name"}
                                       valueExpr={"externalId"}
                                       dataSource={companyWiseProfitSettings.options}
                                       value={companyWiseProfitSettings.viewSettings}
                                       onValueChanged={companyWiseProfitOptionChanged}
                                       inputAttr={{class: "form-control"}}
                                       elementAttr={{class: "search-input input-group"}}
                                       width="100%"
                            ></SelectBox>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-body dash-table-body p-0">
                    <TabContainer>
                        <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                              onSelect={companyWiseProfitTabChanged}>
                            {propertyTabs.filter(x => x["Account"] !== "SOLD").map((item) => (
                                <Tab id="containerTabProperties" eventKey={item["Account"]}
                                     key={item["Account"]}
                                     title={item["Name"]}>
                                </Tab>
                            ))}
                            <TabContent eventKey={companyWiseProfitSettings.currentTab}>
                                <CompanyAnnualKpi
                                    ref={companyWiseProfitRef}
                                    data={companyWiseProfitSettings.filteredData}
                                    viewSettings={companyWiseProfitSettings.viewSettings}
                                ></CompanyAnnualKpi>
                            </TabContent>
                        </Tabs>
                    </TabContainer>
                </div>
            </Card>
        </div>)
    }
    //#endregion

    //#region Property Component
    const generatePropertyComponent = () => {
        return (<div className="col-lg-12 col-xl-6">
            <Card
                className="p-3 border-0 properties-list mb-3 mb-lg-0 dashboard-border dashboard-property-contract-component">
                <div
                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0 me-4"><b>Properties</b></h4>
                    <div className="d-flex mw-100 justify-content-end">
                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                onClick={clearPropertiesFilter}>
                            Clear Filter
                        </Button>
                        <InputGroup className="me-3 search-input">
                            <TextBox placeholder="Search Properties..."
                                     ref={propertiesSearchBox}
                                     mode="search"
                                     inputAttr={{class: "form-control"}}
                                     elementAttr={{class: "search-input input-group"}}
                                     width="100%"
                                     onValueChanged={searchPropertyByText}
                            ></TextBox>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-body dash-table-body p-0">
                    <TabContainer>
                        <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                              onSelect={propertyTabChanged}>
                            {propertyTabs.filter(x => x["Account"] !== "SOLD").map((item) => (
                                <Tab id="containerTabProperties" eventKey={item["Account"]}
                                     key={item["Account"]}
                                     title={<>{item["Name"]}
                                         <span className="tab-count">{item["COUNT"]}</span></>}>
                                </Tab>
                            ))}
                            <TabContent eventKey={propertyListingSettings.currentTab}>
                                <PropertiesComponent
                                    key="CDFD0591-DD6A-4568-A946-85898D3A7CF3"
                                    ref={propertiesRef}
                                    data={propertyListingSettings.data}
                                    columns={propertyListingSettings.columns}
                                    gridCustomization={propertyListingSettings.gridCustomization}
                                    height={propertyListingSettings.gridHeight}
                                    isUpdated={() => {
                                        getUpdatedProperties()
                                    }}
                                ></PropertiesComponent>
                            </TabContent>
                        </Tabs>
                    </TabContainer>
                </div>
            </Card>
        </div>)
    }

    const propertyTabChanged = (e) => {
        setPropertyListingSettings({
            ...propertyListingSettings,
            currentTab: e
        })
        if (e === "ALL") {
            propertiesRef.current?.instance().clearFilter()
            return;
        }
        propertiesRef.current?.instance().filter(["OwnerExternalId", "=", e])
    }

    const searchPropertyByText = e => {
        if (!e.event) return
        propertiesRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const clearPropertiesFilter = () => {
        propertiesRef.current?.instance().clearFilter()
        propertiesRef.current?.instance().searchByText('')
        propertiesSearchBox.current?.instance().option('value', null)
    }
    //#endregion

    //#region Expenses and Income Component

    const generateExpensesAndIncomeComponents = () => {
        return (<>
            <div className="col-lg-12 col-xl-6">
                {generateExpensesComponent()}
            </div>
            <div className="col-lg-12 col-xl-6">
                {generateIncomeComponent()}
            </div>
        </>)
    }

    const generateExpensesComponent = () => {
        return (<div className="col-lg-6 col-xl-12">
            <Card className="p-3 border-0 properties-list mb-3 dashboard-border dashboard-property-contract-component">
                <div
                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0 me-4"><b>Payments</b></h4>
                    <div className="d-flex mw-100 justify-content-end">
                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                onClick={clearExpensesFilter}>
                            Clear Filter
                        </Button>
                        <InputGroup className="me-3 search-input">
                            <TextBox
                                ref={expensesSearchBox}
                                placeholder="Search Expenses..."
                                mode="search"
                                inputAttr={{class: "form-control temp"}}
                                elementAttr={{class: "search-input input-group"}}
                                width="100%"
                                onValueChanged={searchExpenseByText}
                            ></TextBox>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-body dash-table-body p-0">
                    <TabContainer>
                        <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                              onSelect={expensesTabChanged}>
                            {expensesSettings.tabs.filter(x => x["Account"] !== "SOLD").map(item => (
                                <Tab id="containerTabProperties" eventKey={item["Account"]}
                                     key={item["Account"]}
                                     title={<>{item["Name"]}
                                         <span className="tab-count">{item["COUNT"]}</span></>}>
                                </Tab>
                            ))}
                            <TabContent eventKey={expensesSettings.currentTab}>
                                <TransactionsComponent
                                    key="70F462AA-42BD-46D5-A5BF-34BFFB243669"
                                    ref={expensesRef}
                                    data={expensesSettings.data}
                                    columns={expensesSettings.columns}
                                    gridCustomization={expensesSettings.gridCustomization}
                                    income={false}
                                    expense={true}
                                    height={580}
                                    isUpdated={() => {
                                        getUpdatedExpenses()
                                    }}
                                ></TransactionsComponent>
                            </TabContent>
                        </Tabs>
                    </TabContainer>
                </div>
            </Card>
        </div>)
    }

    const searchExpenseByText = e => {
        if (!e.event) return
        expensesRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const expensesTabChanged = e => {
        setExpensesSettings({
            ...expensesSettings,
            currentTab: e
        })
        if (e === "ALL") {
            expensesRef.current?.instance().clearFilter()
            return;
        }
        expensesRef.current?.instance().filter(["OwnerExternalId", "=", e])
    }

    const clearExpensesFilter = () => {
        expensesRef.current?.instance().clearFilter()
        expensesRef.current?.instance().searchByText('')
        expensesSearchBox.current?.instance().option('value', null)
    }

    const generateIncomeComponent = () => {
        return (<div className="col-lg-6 col-xl-12">
                <Card
                    className="p-3 border-0 properties-list mb-3 mb-lg-0 dashboard-border dashboard-property-contract-component">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                        <h4 className="fs-4 fw-medium mb-3 mb-sm-0"><b>Income</b></h4>
                        <div className="d-flex">
                            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                    onClick={clearIncomeFilter}>
                                Clear Filter
                            </Button>
                            <InputGroup className="me-3 search-input">
                                <TextBox
                                    ref={incomeSearchBox}
                                    placeholder="Search Income..."
                                    mode="search"
                                    inputAttr={{class: "form-control temp"}}
                                    elementAttr={{class: "search-input input-group"}}
                                    width="100%"
                                    onValueChanged={searchIncomeByText}
                                ></TextBox>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="card-body dash-table-body p-0">
                        <TabContainer>
                            <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                                  onSelect={incomeTabChanged}>
                                {incomeSettings.tabs.filter(x => x["Account"] !== "SOLD").map(item => (
                                    <Tab id="containerTabProperties" eventKey={item["Account"]}
                                         key={item["Account"]}
                                         title={<>{item["Name"]}
                                             <span className="tab-count">{item["COUNT"]}</span></>}>
                                    </Tab>
                                ))}
                                <TabContent eventKey={incomeSettings.currentTab}>
                                    <TransactionsComponent
                                        key="128A6A70-7227-4565-BD2E-7171DC194A93"
                                        ref={incomeRef}
                                        data={incomeSettings.data}
                                        columns={incomeSettings.columns}
                                        income={true}
                                        expense={false}
                                        gridCustomization={incomeSettings.gridCustomization}
                                        height={580}
                                        isUpdated={() => {
                                            getUpdatedIncomes()
                                        }}
                                    ></TransactionsComponent>
                                </TabContent>
                            </Tabs>
                        </TabContainer>
                    </div>
                </Card>
            </div>
        )
    }

    const searchIncomeByText = e => {
        if (!e.event) return
        incomeRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const incomeTabChanged = e => {
        setIncomeSettings({
            ...incomeSettings,
            currentTab: e
        })
        if (e === "ALL") {
            incomeRef.current?.instance().clearFilter()
            return;
        }
        incomeRef.current?.instance().filter(["OwnerExternalId", "=", e])
    }

    const clearIncomeFilter = () => {
        expensesRef.current?.instance().clearFilter()
        expensesRef.current?.instance().searchByText('')
        expensesSearchBox.current?.instance().option('value', null)
    }

    //#endregion

    //#region Contracts Component
    const generateContractsComponent = () => {
        return (<div className="col-lg-12 col-xl-6">
            <Card className="p-3 border-0 properties-list h-100 dashboard-border dashboard-property-contract-component "
                  id="contractsCard">
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0"><b>Contracts</b></h4>
                    <div className="d-flex">
                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                onClick={clearContractsFilter}>
                            Clear Filter
                        </Button>
                        <InputGroup className="me-3 search-input">
                            <TextBox
                                ref={contractsSearchBox}
                                placeholder="Search Contracts..."
                                mode="search"
                                inputAttr={{class: "form-control"}}
                                elementAttr={{class: "search-input input-group"}}
                                width="100%"
                                onValueChanged={searchContractsByText}
                            ></TextBox>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-body dash-table-body p-0">
                    <TabContainer>
                        <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                              onSelect={contractsTabChanged}>
                            {contractSettings.tabs.filter(x => x["Account"] !== "SOLD").map(item => (
                                <Tab id="containerTabProperties" eventKey={item["Account"]}
                                     key={item["Account"]}
                                     title={<>{item["Name"]}
                                         <span className="tab-count">{item["COUNT"]}</span></>}>
                                </Tab>
                            ))}
                            <TabContent eventKey={contractSettings.currentTab}>
                                <ContractsComponent
                                    ref={contractsRef}
                                    data={contractSettings.data}
                                    columns={contractSettings.columns}
                                    gridCustomization={contractSettings.gridCustomization}
                                    height={contractSettings.gridHeight}
                                ></ContractsComponent>
                            </TabContent>
                        </Tabs>
                    </TabContainer>
                </div>
            </Card>
        </div>)
    }

    const clearContractsFilter = () => {
        contractsRef.current?.instance().clearFilter()
        contractsRef.current?.instance().searchByText('')
        contractsSearchBox.current?.instance().option('value', null)
    }

    const searchContractsByText = e => {
        if (!e.event) return
        contractsRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const contractsTabChanged = e => {
        setContractSettings({
            ...contractSettings,
            currentTab: e
        })
        if (e === "ALL") {
            contractsRef.current?.instance().clearFilter()
            return;
        }
        contractsRef.current?.instance().filter(["OwnerExternalId", "=", e])
    }
    //#endregion

    return (
        <div className="main-wrapper p-3">
            {/* Status Card */}
            {generateTiles()}
            {/*Properties, Profit Analytics*/}
            <div className="row mb-3">
                {generateCompanyProfitComponent()}
                {/* Profit Analytics */}
                {generateProfitAnalytics()}
            </div>
            <div className="row mb-3">
                {generatePropertyComponent()}
                {generateContractsComponent()}
            </div>
            {/*Expenses, Contracts*/}
            <div className="row">
                {generateExpensesAndIncomeComponents()}
            </div>
        </div>
    );
}

export default Overview;