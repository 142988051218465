import React from 'react'

export function MoreOptionsButton() {
    return (
        <div>
            <div
                className="dx-link customCellTemplate"
                title="More Options"
                onClick={onMoreOptionsClicked}
                key={Math.random()}
            />
        </div>
    )
}

function onMoreOptionsClicked(e) {
    const dxContextMenuEvent = new MouseEvent('dxcontextmenu', e.nativeEvent)
    e.currentTarget.dispatchEvent(dxContextMenuEvent)
}

export function ContextMenuTemplate(props) {
    let srcText = `/svg/${props["icon"]}.svg`;
    return (<div className="action-item" style={{minWidth: "150px"}}>
        <img src={srcText} alt="eye" style={{marginRight: "10px"}}/>
        {props["text"]}
    </div>)
}