import Modal from "react-bootstrap/Modal";
import {useEffect, useState, useRef, useCallback} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import {FileUploader} from 'devextreme-react';
import apiClient, {baseUrl} from '../../services/axios/index'
import LoaderService from "../../RetroAssets.System/LoaderService";
import store from "store";
import {alert} from "devextreme/ui/dialog";
import cl from "../../RetroAssets.System/Utilities";

function ViewPropertyPhotos(props) {
    const {show, externalId, name} = props
    const [showModal, setShowModal] = useState(false);
    let fileAttachmentCounter = 0;
    let attachedFileCount = 0;
    let attachmentValueChangedEventExecuted = false;
    const attachmentButtonInstance = useRef(null);
    let uploadUrl = baseUrl() + 'api/Property/SavePropertyImage';
    const [images, setImages] = useState([])
    const [fullImage, setFullImage] = useState({
        key: '',
        image: ''
    })

    const modalClose = () => {
        setShowModal(false);
        props.handleClose()
        setFullImage({
            key: '',
            image: ''
        })
        setImages([])
    }

    useEffect(() => {
        if (!show) return
        getImagesForProperty(false)
    }, [show])

    function getImagesForProperty(showToast) {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Property/GetAllPropertyImageThumbnails?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    if (data.length > 0) {
                        setFullImage({
                            ...fullImage,
                            key: Object.keys(data[1])[0],
                            image: data[0]["FULL"]
                        })
                        data.splice(0, 1)
                        setImages(data);
                    }
                    if (showToast)
                        cl.showSuccessToast()
                    else
                        setShowModal(true)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const getUploadHeader = () => {
        const accessToken = store.get('accessToken');

        return {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
            Accept: 'application/json, text/plain, */*',
        };
    };

    const valueChanged = e => {
        if (e.value.length === 0) return;
        if (!attachmentValueChangedEventExecuted) {
            LoaderService.setData(true);
            attachedFileCount = e.value.length;
            let formData = new FormData()
            e.value.forEach((val, index) => {
                let ind = `NNNN${index}`;
                formData.append(ind, val, ind);
            });
            let params = new URLSearchParams({
                externalId: externalId
            });
            apiClient
                .post('/Property/SavePropertyImage?' + params, formData)
                .then(response => {
                    if (response) {
                        try {
                            LoaderService.setData(false);
                            let resp = JSON.parse(response.data);
                            if (resp[0].response) {
                                getImagesForProperty(true);
                            } else {
                                return alert(resp[0].message, 'Warning!');
                            }
                            resetAttachmentControl();
                        } catch (e) {
                            resetAttachmentControl();
                            LoaderService.setData(false);
                            return alert(response.data, 'Error!');
                        }
                    }
                })
                .catch(err => {
                    LoaderService.setData(false);
                    console.log(err);
                });
        }
    };

    function resetAttachmentControl() {
        attachmentButtonInstance.current.instance().option('uploadUrl', uploadUrl);
        attachmentValueChangedEventExecuted = true;
        attachmentButtonInstance.current.instance().reset();
        attachmentValueChangedEventExecuted = false;
        attachedFileCount = 0;
        fileAttachmentCounter = 0;
    }

    const executeAttachmentCommands = useCallback(() => {
        attachmentButtonInstance.current.instance().option('uploadUrl', uploadUrl);
        attachmentButtonInstance.current.instance()._isCustomClickEvent = true;
        attachmentButtonInstance.current.instance()['_$fileInput'][0].click();
    }, []);

    const addNewPhotos = () => {
        executeAttachmentCommands()
    }

    const imageChanged = e => {
        let params = new URLSearchParams({
            externalId: externalId,
            documentExternalId: e
        })
        apiClient
            .get('/Property/GetFullSizeImage?' + params)
            .then(response => {
                try {
                    let resp = JSON.parse(response.data)
                    if (resp[0].hasOwnProperty('response')) {
                        if (!resp[0].response) {
                            return alert(resp[0].message, 'Warning!')
                        }
                        return
                    }
                    let data = resp
                    if (data.length > 0) {
                        let key = Object.keys(data[0])[0]
                        setFullImage({
                            ...fullImage,
                            key: key,
                            image: data[0][key]
                        })
                    }
                } catch (e) {
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-90w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>{name}</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2">
            <FileUploader
                style={{display: 'none'}}
                ref={attachmentButtonInstance}
                multiple={true}
                accept=".jpg, .png, .bmp, .jpeg"
                labelText=""
                allowCanceling={false}
                uploadMode="useForm"
                showFileList={false}
                uploadHeaders={getUploadHeader()}
                selectButtonText="Add Documents"
                onValueChanged={valueChanged}
            ></FileUploader>
            <div className="prop-banner">
                <div id="prop-thumb-slider" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner mb-3">
                        {fullImage.key ? <div className="carousel-item active" key={fullImage.key}>
                            <img src={'data:image/jpeg;base64,' + fullImage.image} alt="prop-pic"
                                 className="d-block w-100" style={{maxHeight: '650px', objectFit: 'contain'}}/>
                        </div> : <div style={{height: '650px'}}></div>}
                    </div>
                    <div className="carousel-indicators position-relative mt-2 justify-content-start mx-0 mb-0">
                        {images.map(img => {
                            let key = Object.keys(img)[0]
                            return (
                                <button type="button" data-bs-target="#prop-thumb-slider" data-bs-slide-to={key}
                                        className={fullImage.key === key ? "active ms-0" : "ms-0"}
                                        aria-current="true" aria-label="Slide 1"
                                        onClick={() => imageChanged(key)}>
                                    <img src={'data:image/jpeg;base64,' + img[key]} alt="prop-pic"/>
                                </button>
                                // <div>
                                /*<span><Image src="svg/dots-vertical.svg" alt="dots-vertical"/></span>*/
                                // </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            <Button variant="outline-primary" className="flex-shrink-0 me-3"
                    onClick={addNewPhotos}>
                Upload Photos
            </Button>
        </Modal.Footer>

    </Modal>)
}

export default ViewPropertyPhotos;