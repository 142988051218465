import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, {useEffect, useRef, useState} from "react";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import apiClient from "../../../services/axios";
import {alert} from "devextreme/ui/dialog";
import {TextBox} from "devextreme-react";
import {TabContainer, TabContent} from "react-bootstrap";
import TransactionsComponent from "../../../RetroAssets.System/Components/TransactionsComponent";
import {Chart, CommonSeriesSettings, Export, Format, Label, Legend, Series} from "devextreme-react/chart";
import ViewPropertyPhotos from "../../Popups/ViewPropertyPhotos";

function Summary(props) {
    const {accounts, propListing, loaded} = props
    const [accountCounts, setAccountCounts] = useState([]);
    const [propertyListingSettings, setPropertyListingSettings] = useState([])
    const [tabWiseProperties, setTabWiseProperties] = useState([])
    const [selectedPropertyData, setSelectedPropertyData] = useState({})
    const [incomeTabs, setIncomeTabs] = useState([])
    const [expensesTab, setExpensesTab] = useState([])

    const incomeRef = useRef()
    const incomeSearchBox = useRef(null)
    const [incomeSettings, setIncomeSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL"
    })

    const expensesRef = useRef()
    const expensesSearchBox = useRef(null)
    const [expenseSettings, setExpenseSettings] = useState({
        data: [],
        columns: [],
        gridCustomization: [],
        currentTab: "ALL"
    })

    const [chartData, setChartData] = useState([])
    const [gridHeight, setGridHeight] = useState(20)

    const [showDropdown, setShowDropdown] = useState(false);
    const [viewPropertyPhotos, setViewPropertyPhotos] = useState({
        show: false,
        externalId: '',
        Name: ''
    })

    useEffect(() => {
        if (accounts.length === 0 || propListing.length === 0) return
        updateSize()
        LoaderService.setData(true)
        setAccountCounts(accounts)
        setPropertyListingSettings(propListing[0]['Data'])
        let tabData = []
        let first = true;
        let firstProperty = '';
        accounts.forEach(account => {
            let obj = {
                Name: account.Name,
                Account: account.Account,
                COUNT: account.COUNT,
                Properties: []
            }
            if (account.Account === "ALL") {
                if (first && propListing[0]['Data'].length > 0) {
                    firstProperty = propListing[0]['Data'][0]["externalId"]
                    setSelectedPropertyData(propListing[0]['Data'][0])
                }
                obj.Properties = propListing[0]['Data']
                first = false
            } else {
                obj.Properties = propListing[0]['Data'].filter(x => x["OwnerExternalId"] === account.Account.toLowerCase())
            }
            tabData.push(obj)
        })
        setTabWiseProperties(tabData)
        loadDataForProperty(firstProperty)
    }, [propListing, accounts, loaded]);

    useEffect(() => {
        if (!loaded) return
        // incomeRef.current?.instance()?.repaint()
        // incomeRef.current?.instance()?.refresh()
        //
        // expensesRef.current?.instance()?.repaint()
        // expensesRef.current?.instance()?.refresh()

    }, [loaded])

    const getUpdatedExpenses = () => {
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: selectedPropertyData["externalId"]
        })
        apiClient
            .get('/Dashboard/GetSummaryExpense?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setExpenseSettings({
                        ...expenseSettings,
                        data: data[0]['Data'],
                    })
                    setExpensesTab(generateTabCounts(data[0]['Data']))
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const getUpdatedIncomes = () => {
        let params = new URLSearchParams({
            externalId: selectedPropertyData["externalId"]
        })
        LoaderService.setData(true)
        apiClient
            .get('/Dashboard/GetSummaryIncome?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    setIncomeSettings({
                        ...incomeSettings,
                        data: data[0]['Data'],
                    })
                    setIncomeTabs(generateTabCounts(data[0]['Data']))
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const loadDataForProperty = externalId => {

        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Dashboard/GetDashboardPropertyIncomeAndExpenses?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let income = data[0]['Income']
                    setIncomeSettings({
                        ...incomeSettings,
                        data: income[0]['Data'],
                        columns: income[1]['Settings'][0]['DashboardSummaryIncomeTransactions'][0]['Columns'],
                        gridCustomization: income[1]['Settings'][0]['DashboardSummaryIncomeTransactions'][3]['GridCustomization'][0],
                        currentTab: "ALL"
                    })
                    setIncomeTabs(generateTabCounts(income[0]['Data']))
                    let expenses = data[1]["Expenses"];
                    setExpenseSettings({
                        ...expenseSettings,
                        data: expenses[0]['Data'],
                        columns: expenses[1]['Settings'][0]['DashboardSummaryExpenseTransactions'][0]['Columns'],
                        gridCustomization: expenses[1]['Settings'][0]['DashboardSummaryExpenseTransactions'][3]['GridCustomization'][0],
                        currentTab: "ALL"
                    })
                    setExpensesTab(generateTabCounts(expenses[0]['Data']))
                    setChartData(data[2]["GraphData"])
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const generateTabCounts = data => {
        let tempTabs = []
        tempTabs.push({Name: "ALL", externalId: "ALL", Count: data.length})
        tempTabs.push({
            Name: "Pending",
            externalId: 'Pending',
            Count: data.filter(x => x['FilterName'] === "Pending").length
        })
        tempTabs.push({
            Name: "Temp Paid",
            externalId: 'TempPaid',
            Count: data.filter(x => x['FilterName'] === "TempPaid").length
        })
        tempTabs.push({
            Name: "Paid",
            externalId: 'Paid',
            Count: data.filter(x => x['FilterName'] === "Paid").length
        })
        return tempTabs
    }

    const propertyChanged = e => {
        let selectedProperty = e.target.id.split("!")[1];
        let index = propertyListingSettings.findIndex(x => x.externalId === selectedProperty);
        if (index > -1) {
            setShowDropdown(!showDropdown)
            setSelectedPropertyData(propertyListingSettings[index])
            loadDataForProperty(selectedProperty)
        }
    }

    const generatePropertyStatus = (e) => {
        if (e["PurchasePaymentPlan"]) { //Payment Plan
            return (<label key={e.key} className="form-check-label" htmlFor="ps-na"><span
                className="status-color na"></span>{e["Status"]}</label>)
        } else if (!e["Available"]) { //Not Available
            return (<label key={e.key} className="form-check-label" htmlFor="ps-unoccupied"><span
                className="status-color unoccupied"></span>{e["Status"]}</label>)
        } else if (!e["Occupied"]) { //Unoccupied
            return (<label key={e.key} className="form-check-label" htmlFor="ps-overdue"><span
                className="status-color overdue"></span>{e["Status"]}</label>)
        } else if (e["Occupied"]) {//Occupied
            return (<label key={e.key} className="form-check-label" htmlFor="ps-occupied"><span
                className="status-color occupied"></span>{e["Status"]}</label>)
        }
    }

    const searchIncomeByText = (e) => {
        if (!e.event) return
        incomeRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const incomeTabChanged = (e) => {
        setIncomeSettings({
            ...incomeSettings,
            currentTab: e
        })
        if (e === "ALL") {
            incomeRef.current?.instance().clearFilter()
        } else {
            incomeRef.current?.instance().filter(["FilterName", "=", e])
        }
    }

    const searchExpenseByText = e => {
        if (!e.event) return
        expensesRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const expensesTabChanged = e => {
        setExpenseSettings({
            ...expenseSettings,
            currentTab: e
        })
        if (e === "ALL") {
            expensesRef.current?.instance().clearFilter()
        } else {
            expensesRef.current?.instance().filter(["FilterName", "=", e])
        }
    }

    function updateSize() {
        let height = document.getElementById("PropertyDetailsComponent").clientHeight
        setGridHeight(height - 140)
    }

    const viewPhotos = () => {
        setViewPropertyPhotos({
            ...viewPropertyPhotos,
            show: true,
            externalId: selectedPropertyData["externalId"],
            Name: selectedPropertyData["Name"]
        })
    }

    const printButtonClicked = e => {
        return alert("Feature under construction.", "Warning!")
    }

    return (
        <div className="main-wrapper p-3">
            <div className="d-flex justify-md-content-end mb-3 mb-lg-0 position-relative">
                <Dropdown className="summary-filter-dropdown" autoClose={true} show={showDropdown}>
                    <Dropdown.Toggle variant="default" id="summary-filter-btn"
                                     className="cc-border-dark d-flex justify-content-between text-secondary summary-filter-btn"
                                     onClick={() => {
                                         setShowDropdown(!showDropdown)
                                     }}>
                        {selectedPropertyData.Name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="summary-filter-body shadow-sm border-0 p-3"
                        style={{maxHeight: '400px', overflowY: 'hidden'}}>
                        <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab">
                            {tabWiseProperties.filter(x => x["Account"] !== "SOLD").map((tab) => {
                                return (<Tab eventKey={tab["Account"]} title={<>{tab["Name"]}<span
                                    className="tab-count">{tab["COUNT"]}</span></>}>
                                    <div className="pt-3" style={{maxHeight: '250px', overflowY: 'scroll'}}>
                                        {tab.Properties.map((prop) => {
                                            return (<div
                                                className="d-flex justify-content-between dropdown-items py-2 border-bottom">
                                                <Form.Check type="radio"
                                                            id={tab["Account"] + '!' + prop["externalId"]}
                                                            label={prop["Name"]}
                                                            defaultChecked={tab["Account"] + '!' + prop["externalId"] === "ALL" + '!' + selectedPropertyData["externalId"]}
                                                            className="ps-0 me-2 sum-filter-checkbox"
                                                            name="SummaryPropSelection"
                                                            onClick={propertyChanged}
                                                />
                                                {generatePropertyStatus(prop)}
                                            </div>)
                                        })}
                                    </div>
                                </Tab>)
                            })}
                        </Tabs>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="row">
                <div className="col-lg-7 col-xl-6 col-xxl-4 mb-3 mb-xxl-0" id="PropertyDetailsComponent">
                    {/* Summary chart and short info */}
                    <Card className="p-3 border-0 dashboard-border">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-4">
                            <h4 className="fs-4 fw-bold mb-3 mb-sm-0 me-4"><b>{selectedPropertyData["Name"]}</b></h4>
                            <span role="button"><Image src="/svg/print.svg" alt="print" width="20" height="20"
                                                       onClick={printButtonClicked}/></span>
                        </div>
                        <div className="row">
                            <Chart
                                dataSource={chartData}
                                height={280}
                            >
                                <CommonSeriesSettings
                                    argumentField="Year"
                                    type="bar"
                                    hoverMode="allArgumentPoints"
                                    selectionMode="allArgumentPoints"
                                >
                                    {/*<Label visible={true}>*/}
                                    {/*    <Format type="fixedPoint" precision={0}/>*/}
                                    {/*</Label>*/}
                                </CommonSeriesSettings>
                                <Series
                                    argumentField="Year"
                                    valueField="TotalIncome"
                                    name="Total Income"
                                />
                                <Series
                                    argumentField="Year"
                                    valueField="TotalExpense"
                                    name="Total Expense"
                                />
                                <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                                <Export enabled={false}/>
                            </Chart>
                        </div>
                        <div className="row income-exp-count text-center mb-3">
                            <div className="col-6">
                                <div className="bg-light p-2 rounded">
                                    <div className="fw-bold mb-1">{selectedPropertyData["DisplayTotalIncome"]} AED</div>
                                    <div className="text-muted text-uppercase fs-7">Total Income</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="bg-light p-2 rounded">
                                    <div className="fw-bold mb-1">{selectedPropertyData["DisplayTotalExpense"]} AED
                                    </div>
                                    <div className="text-muted text-uppercase fs-7">Total Expense</div>
                                </div>
                            </div>
                        </div>
                        <div className="prop-details border rounded p-3 mb-3">
                            <h6 className="fw-medium mb-1">Property Details</h6>
                            <div className="row mb-2">
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/line-height.svg" alt="line-height" width="20"
                                                                 height="20"/></div>
                                    <div className="ps-1">
                                        <span className="text-muted">Size SQ M</span>
                                        <div>{selectedPropertyData["DisplaySize"]} Sq.M</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/bed.svg" alt="bed" width="20" height="20"/>
                                    </div>
                                    <div className="ps-1">
                                        <span className="text-muted">Beds</span>
                                        <div>{!selectedPropertyData["Beds"] ? 'No' : selectedPropertyData["Beds"]}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/home-line.svg" alt="home-line" width="20"
                                                                 height="20"/></div>
                                    <div className="ps-1">
                                        <span className="text-muted">Property type</span>
                                        <div>{selectedPropertyData["PropertyType"]}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/vehicle-car-parking.svg"
                                                                 alt="vehicle-car-parking" width="20" height="20"/>
                                    </div>
                                    <div className="ps-1">
                                        <span className="text-muted">Parking Slots</span>
                                        <div>{!selectedPropertyData["ParkingSlot"] ? 'No' : selectedPropertyData["ParkingSlot"]}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/line-chart-up.svg" alt="line-chart-up"
                                                                 width="20" height="20"/></div>
                                    <div className="ps-1">
                                        <span className="text-muted">ROI</span>
                                        <div>{selectedPropertyData["ROI"]}%</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/coins-stacked.svg" alt="coins-stacked"
                                                                 width="20" height="20"/></div>
                                    <div className="ps-1">
                                        <span className="text-muted">Rent Amt</span>
                                        <div>{selectedPropertyData["DisplayCurrentContractRent"] + ' ' + selectedPropertyData["Currency"]}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/calendar.svg" alt="calendar" width="20"
                                                                 height="20"/></div>
                                    <div className="ps-1">
                                        <span className="text-muted">Start Date</span>
                                        <div>{selectedPropertyData["DisplayCurrentContractStartDate"]}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/calendar.svg" alt="calendar" width="20"
                                                                 height="20"/></div>
                                    <div className="ps-1">
                                        <span className="text-muted">End Date</span>
                                        <div>{selectedPropertyData["DisplayCurrentContractEndDate"]}</div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 d-flex align-items-center py-3">
                                    <div className="me-2"><Image src="/svg/address.svg" alt="address" width="20"
                                                                 height="20"/></div>
                                    <div className="ps-1 w-75">
                                        <div className="text-muted">Address</div>
                                        <div className="text-primary d-inline-block text-truncate w-100">
                                            {selectedPropertyData["Address"]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center" onClick={viewPhotos}><span role="button"
                                                                                    className="fw-bold text-primary">VIEW PROPERTY PHOTOS</span>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-lg-5 col-xl-6 col-xxl-3 mb-3 mb-xxl-0">
                    {/* Cheques Data Table */}
                    <Card className="p-3 border-0 h-100 dashboard-border">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                            <h4 className="fs-4 fw-medium mb-3 mb-sm-0 me-4"><b>Cheques</b></h4>
                            <div className="d-flex mw-100 justify-content-end">
                                <InputGroup className="me-3 search-input">
                                    <TextBox
                                        ref={incomeRef}
                                        placeholder="Search Cheques..."
                                        mode="search"
                                        inputAttr={{class: "form-control temp"}}
                                        elementAttr={{class: "search-input input-group"}}
                                        width="100%"
                                        onValueChanged={searchIncomeByText}
                                    ></TextBox>
                                </InputGroup>
                            </div>
                        </div>
                        <div>
                            <TabContainer>
                                <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                                      onSelect={incomeTabChanged}>
                                    {incomeTabs.map(item => (
                                        <Tab id="containerTabProperties" eventKey={item["externalId"]}
                                             key={item["externalId"]}
                                             title={<>{item["Name"]}
                                                 <span className="tab-count">{item["Count"]}</span></>}>
                                        </Tab>
                                    ))}
                                    <TabContent eventKey={incomeSettings.currentTab}>
                                        <TransactionsComponent
                                            key="2AB9CC7C-4A10-4DFD-BE08-B921D8D94482"
                                            ref={incomeRef}
                                            data={incomeSettings.data}
                                            columns={incomeSettings.columns}
                                            income={true}
                                            expense={false}
                                            gridCustomization={incomeSettings.gridCustomization}
                                            height={gridHeight}
                                            isUpdated={() => {
                                                getUpdatedIncomes()
                                            }}
                                        ></TransactionsComponent>
                                    </TabContent>
                                </Tabs>
                            </TabContainer>
                        </div>
                    </Card>
                </div>
                <div className="col-xxl-5">
                    {/* Expenses */}
                    <Card className="p-3 border-0 h-100 dashboard-border">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-3">
                            <h4 className="fs-4 fw-medium mb-3 mb-sm-0 me-4"><b>Expenses</b></h4>
                            <div className="d-flex mw-100 justify-content-end">
                                <InputGroup className="me-3 search-input">
                                    <TextBox
                                        ref={expensesRef}
                                        placeholder="Search Expenses..."
                                        mode="search"
                                        inputAttr={{class: "form-control temp"}}
                                        elementAttr={{class: "search-input input-group"}}
                                        width="100%"
                                        onValueChanged={searchExpenseByText}
                                    ></TextBox>
                                </InputGroup>
                            </div>
                        </div>
                        <div>
                            <TabContainer>
                                <Tabs defaultActiveKey="ALL" className="fs-6 nav-tab tab-sm inner-tab"
                                      onSelect={expensesTabChanged}>
                                    {expensesTab.map(item => (
                                        <Tab id="containerTabProperties" eventKey={item["externalId"]}
                                             key={item["externalId"]}
                                             title={<>{item["Name"]}
                                                 <span className="tab-count">{item["Count"]}</span></>}>
                                        </Tab>
                                    ))}
                                    <TabContent eventKey={expenseSettings.currentTab}>
                                        <TransactionsComponent
                                            key="325E2597-ECE9-42D5-A562-0EE668712256"
                                            ref={expensesRef}
                                            data={expenseSettings.data}
                                            columns={expenseSettings.columns}
                                            income={false}
                                            expense={true}
                                            gridCustomization={expenseSettings.gridCustomization}
                                            height={gridHeight}
                                            isUpdated={() => {
                                                getUpdatedExpenses()
                                            }}
                                        ></TransactionsComponent>
                                    </TabContent>
                                </Tabs>
                            </TabContainer>
                        </div>
                    </Card>
                </div>
                <ViewPropertyPhotos
                    show={viewPropertyPhotos.show}
                    externalId={viewPropertyPhotos.externalId}
                    name={viewPropertyPhotos.Name}
                    handleClose={() => setViewPropertyPhotos({...viewPropertyPhotos, show: false})}
                ></ViewPropertyPhotos>
            </div>
        </div>
    );
}

export default Summary;