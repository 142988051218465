import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import {useLocation} from 'react-router-dom';
import {alert} from 'devextreme/ui/dialog'
import {connect} from "react-redux";
import store from "store";

const mapStateToProps = ({user}) => ({user})


const Header = ({dispatch, user}) => {
    const location = useLocation();

    const downloadReport = e => {
        return alert("Feature currently under construction", "Warning!")
    }

    const logoutFromApplication = () => {
        dispatch({
            type: 'user/LOGOUT',
        })
        store.set('accessToken', '');
    }

    return (
        <header
            className="pt-2 pb-sm-2 px-3 border-bottom d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-between bg-white top-header">
            {/* Header logo */}
            <div className="mb-3 mb-sm-0 me-3"><Image src="/logo.svg" alt="logo"/></div>
            {location.pathname !== "/" && <div className="d-flex align-items-center mb-3 mb-sm-0">
                {/* Download dropdown */}
                <Dropdown className="head-download-btn me-3">
                    <Dropdown.Toggle variant="outline-light" id="head-download-dropdown" className="fs-5 p-0">
                        <Image src="/svg/download.svg" alt="download" className="me-2"/>
                        Download Reports
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="fs-6 shadow-sm border-0">
                        <Dropdown.Item href="/" className="py-2" onClick={downloadReport}>Overview
                            Report</Dropdown.Item>
                        <Dropdown.Item href="/" className="py-2" onClick={downloadReport}>Property Overview
                            Report</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <button type="button" className="btn text-danger fs-5" onClick={logoutFromApplication}><Image
                    src="/svg/header/log-out.svg" alt="logout"
                    className="me-1"
                />Logout
                </button>
            </div>}
        </header>
    )
}

export default connect(mapStateToProps)(Header)
